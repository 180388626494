"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _excel = require("@/utils/excel");

var _dictionary = require("@/config/dictionary");

var _inquiry = require("@/api/inquiry.js");

var _report = require("@/api/report.js");

var _index = require("@/utils/index.js");

var _index2 = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "caseTable",
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      tableData: [],
      total: 0,
      //总页数
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      conditionData: "",
      //来自子组件的数据
      date: "",
      caseState: _dictionary.dictionary.caseState,
      caseDealing: _dictionary.dictionary.caseDealing
    };
  },
  created: function created() {
    this.currentPage = 1;
    this.init();
  },
  components: {
    Caseconditons: _index2.default
  },
  methods: {
    init: function init() {
      var _this = this;

      (0, _inquiry.getList)({
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    btnExport: function btnExport() {
      var data = this.$refs.Caseconditons.getValue();
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1
      };
      (0, _report.exportCaseListByPage)(postData).then(function (res) {
        // console.log(res);
        var counst = res.rows;
        (0, _excel.onExport)({
          col: [{
            index: 'caseNo',
            name: '案号'
          }, {
            index: 'arbitrationReason',
            name: '案由'
          }, {
            index: 'registerTime',
            name: '立案时间'
          }, {
            index: 'appName',
            name: '申请人'
          }, {
            index: 'resName',
            name: '被申请人'
          }, {
            index: 'controversyAmount',
            name: '标的额(元)'
          }, {
            index: 'arbitrationAmount',
            name: '仲裁费(元)'
          }, {
            index: 'payVerify',
            name: '费用核实'
          }, {
            index: 'caseId',
            name: '网络案号'
          }, {
            index: 'registerSendTime',
            name: '受理文书送达时间'
          }, {
            index: 'composeSendTime',
            name: '组庭通知送达时间'
          }, {
            index: 'arbitratorName',
            name: '仲裁员'
          }, {
            index: 'courtSendTime',
            name: '开庭通知送达时间'
          }, {
            index: 'courtTime',
            name: '开庭时间'
          }, {
            index: 'results',
            name: '处理结果'
          }],
          data: counst
        });
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this2 = this;

      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _inquiry.getList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = "";
      this.currentPage = 1;
      this.init();
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this3 = this;

      this.pageLimit = val;

      if ((0, _index.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this3.tableData = res.rows;
            _this3.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this3.tableData = res.rows;
            _this3.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this4 = this;

      if ((0, _index.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this4.tableData = res.rows;
            _this4.total = res.total;
            _this4.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this4.tableData = res.rows;
            _this4.total = res.total;
            _this4.currentPage = val;
          }
        });
      }
    },
    handleSelectionChange: function handleSelectionChange() {}
  }
};
exports.default = _default;