"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.sort");

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

var _inquiry = require("@/api/inquiry");

var _dictionary = require("@/config/dictionary");

var _index2 = require("@/utils/index.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'service',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      total: 0,
      loading: true,
      tableData: [],
      conditionData: '',
      //来自子组件的数据
      sendMsgType: _dictionary.dictionary.sendMsgType,
      sendMsgState: _dictionary.dictionary.sendMsgState,
      partyIdType: _dictionary.dictionary.partyIdType,
      receiptState: _dictionary.dictionary.receiptState,
      listSendType: {
        '1': '短信',
        '2': '邮件',
        '3': '服务平台'
      },
      serviceType: {
        '1': '立案',
        '2': '组庭',
        '3': '开庭',
        '4': '裁决',
        '5': '撤案',
        '6': '仲裁庭调查取证',
        '7': "仲裁行使权利通知",
        '8': "不予立案通知",
        '9': "缴费通知书送达"
      },
      multipleSelection: [],
      multipleSelectionId: [],
      serviceLogVisible: false,
      //送达日志弹框显示
      addressEditVal: '',
      //送达地址修改
      popoverVisible: false,
      //气泡弹窗
      title: '',
      //弹窗标题
      confirmServiceVisble: false,
      //  确认送达弹窗显示
      confirmServiceform: {
        remark: ''
      },
      confirmRules: {
        remark: [{
          required: true,
          message: '请填写',
          trigger: 'blur'
        }]
      },
      caseServiceVisble: false,
      //案件送达弹窗显示
      caseServiceForm: {
        name: '张时',
        type: [],
        sendType: [],
        message: '',
        email: '',
        mailNum: '',
        //物流单号
        value: '' //物流值

      },
      caseServiceRules: {
        name: [{
          required: true,
          message: '请输入送达人名称',
          trigger: 'blur'
        }, {
          min: 3,
          max: 5,
          message: '长度在 3 到 5 个字符',
          trigger: 'blur'
        }],
        type: [{
          type: 'array',
          required: true,
          message: '请至少选择一个需送达内容',
          trigger: 'change'
        }],
        sendType: [{
          type: 'array',
          required: true,
          message: '请至少选择一个需送达方式',
          trigger: 'change'
        }]
      },
      arr: [],
      messageHight: true,
      emailHight: true,
      mailHight: true,
      options: [{
        value: '中通',
        label: '中通'
      }, {
        value: '圆通',
        label: '圆通'
      }],
      //物流选择
      caseServiceInnerVisble: false,
      //内部弹窗
      id: '',
      sendType: '',
      span: 18,
      sendDetailData: '',
      order: '',
      columnSort: ''
    };
  },
  created: function created() {
    this.init();
  },
  components: {
    Caseconditons: _index.default
  },
  methods: {
    init: function init() {
      var _this = this;

      (0, _inquiry.getCaseMessageList)({
        state: '',
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    //排序
    handleSort: function handleSort(column, prop, order) {
      var _this2 = this;

      if (column.order == 'ascending') {
        this.order = 'asc';
        this.columnSort = column.prop;
      } else if (column.order == 'descending') {
        this.order = 'desc';
        this.columnSort = column.prop;
      } else {
        this.order = '';
        this.columnSort = '';
      }

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        this.conditionData.page = this.currentPage;
        this.conditionData.limit = this.pageLimit;

        if (this.order) {
          this.conditionData.order = this.order;
          this.conditionData.sort = this.columnSort;
          (0, _inquiry.getCaseMessageList)(this.conditionData).then(function (res) {
            if (res.code == 1) {
              _this2.tableData = res.rows;
              _this2.total = res.total;
              _this2.loading = false;
            }
          });
        } else {
          delete this.conditionData.order;
          delete this.conditionData.sort;
          (0, _inquiry.getCaseMessageList)(this.conditionData).then(function (res) {
            if (res.code == 1) {
              _this2.tableData = res.rows;
              _this2.total = res.total;
              _this2.loading = false;
            }
          });
        }
      } else {
        if (this.order) {
          (0, _inquiry.getCaseMessageList)({
            state: '',
            limit: this.pageLimit,
            page: this.currentPage,
            sort: this.columnSort,
            order: this.order
          }).then(function (res) {
            if (res.code == 1) {
              _this2.tableData = res.rows;
              _this2.total = res.total;
              _this2.loading = false;
            }
          });
        } else {
          (0, _inquiry.getCaseMessageList)({
            state: '',
            limit: this.pageLimit,
            page: this.currentPage
          }).then(function (res) {
            if (res.code == 1) {
              _this2.tableData = res.rows;
              _this2.total = res.total;
              _this2.loading = false;
            }
          });
        }
      }
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this3 = this;

      // 需要发送给后端的数据
      this.currentPage = 1;
      this.$refs.multipleTable.clearSort();
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        state: data.serviceStatusvalue,
        serviceType: data.serviceTypevalue,
        type: data.serviceWayvalue,
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _inquiry.getCaseMessageList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this3.tableData = res.rows;
          _this3.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.$refs.multipleTable.clearSort();
      this.columnSort = '';
      this.order = "";
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 表格 查看日志按钮
    handleCheck: function handleCheck(data) {
      var _this4 = this;

      this.title = '查看';
      this.serviceLogVisible = true;
      this.sendType = data.type;
      (0, _inquiry.getCaseMessage)(data.id).then(function (res) {
        if (res.code == 1) {
          _this4.sendDetailData = res.data;
        }
      });
    },
    // 表格  重新送达按钮
    handleResend: function handleResend(data) {
      var _this5 = this;

      this.id = data.id;
      this.serviceLogVisible = true;
      this.sendType = data.type;
      this.title = '重新送达';
      (0, _inquiry.getCaseMessage)(data.id).then(function (res) {
        if (res.code == 1) {
          _this5.sendDetailData = res.data;
        }
      });
    },
    cancelAddressEdit: function cancelAddressEdit() {
      this.popoverVisible = false;
      this.addressEditVal = '';
    },
    confiremAddressEdit: function confiremAddressEdit() {
      this.popoverVisible = false;
    },
    //重新送达-提交
    handleResendSubmit: function handleResendSubmit() {
      var _this6 = this;

      (0, _inquiry.resendCaseEmail)({
        id: this.id,
        newEmail: this.addressEditVal ? this.addressEditVal : this.sendDetailData.deliveryAddress
      }).then(function (res) {
        if (res.code == 1) {
          _this6.$message({
            message: '保存成功',
            type: 'success'
          });

          _this6.serviceLogVisible = false;
          _this6.addressEditVal = "";

          _this6.init();
        } else {
          _this6.$message.error(res.msg);
        }
      });
    },
    // 表格 确认送达按钮
    handleConfirm: function handleConfirm(data) {
      this.id = data.id;
      this.confirmServiceVisble = true;
    },
    // 确认送达-提交
    handleConfirmSubmit: function handleConfirmSubmit(confirmServiceform) {
      var _this7 = this;

      this.$refs['confirmServiceform'].validate(function (valid) {
        if (valid) {
          (0, _inquiry.confirmCaseMessage)({
            id: _this7.id,
            remark: _this7.confirmServiceform.remark
          }).then(function (res) {
            if (res.code == 1) {
              _this7.$message({
                message: '保存成功',
                type: 'success'
              });

              _this7.confirmServiceVisble = false;

              _this7.init();
            } else {
              _this7.$message.error(res.msg);
            }
          });
        }
      });
    },
    //送达方式的值改变事件
    aa: function aa(data) {
      this.arr = [];
      this.arr = data;

      if (this.arr.indexOf('短信') != -1) {
        this.messageHight = false;
      }

      if (this.arr.indexOf('邮件') != -1) {
        this.emailHight = false;
      }

      if (this.arr.indexOf('邮寄') != -1) {
        this.mailHight = false;
      }

      if (this.arr.indexOf('短信') == -1) {
        this.messageHight = true;
      }

      if (this.arr.indexOf('邮件') == -1) {
        this.emailHight = true;
      }

      if (this.arr.indexOf('邮寄') == -1) {
        this.mailHight = true;
      }
    },
    //提交表单
    submitForm: function submitForm(data) {
      this.caseServiceVisble = false;
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this8 = this;

      this.pageLimit = val; // 有筛选条件的情况下

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;

        if (this.order || this.columnSort) {
          this.conditionData.order = this.order;
          this.conditionData.sort = this.columnSort;
          (0, _inquiry.getCaseMessageList)(this.conditionData).then(function (res) {
            if (res.code == 1) {
              _this8.tableData = res.rows;
              _this8.total = res.total;
            }
          });
        } else {
          (0, _inquiry.getCaseMessageList)(this.conditionData).then(function (res) {
            if (res.code == 1) {
              _this8.tableData = res.rows;
              _this8.total = res.total;
            }
          });
        }
      } else {
        //没有筛选条件的情况下
        if (this.order || this.columnSort) {
          (0, _inquiry.getCaseMessageList)({
            limit: val,
            page: this.currentPage,
            sort: this.columnSort,
            order: this.order
          }).then(function (res) {
            if (res.code == 1) {
              _this8.tableData = res.rows;
              _this8.total = res.total;
            }
          });
        } else {
          (0, _inquiry.getCaseMessageList)({
            limit: val,
            page: this.currentPage
          }).then(function (res) {
            if (res.code == 1) {
              _this8.tableData = res.rows;
              _this8.total = res.total;
            }
          });
        }
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this9 = this;

      this.currentPage = val;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;

        if (this.columnSort || this.order) {
          this.conditionData.order = this.order;
          this.conditionData.sort = this.columnSort;
          (0, _inquiry.getCaseMessageList)(this.conditionData).then(function (res) {
            if (res.code == 1) {
              _this9.tableData = res.rows;
              _this9.total = res.total;
            }
          });
        } else {
          (0, _inquiry.getCaseMessageList)(this.conditionData).then(function (res) {
            if (res.code == 1) {
              _this9.tableData = res.rows;
              _this9.total = res.total;
            }
          });
        }
      } else {
        //没有筛选条件的情况下
        if (this.order || this.columnSort) {
          (0, _inquiry.getCaseMessageList)({
            limit: this.pageLimit,
            page: val,
            sort: this.columnSort,
            order: this.order
          }).then(function (res) {
            if (res.code == 1) {
              _this9.tableData = res.rows;
              _this9.total = res.total;
            }
          });
        } else {
          (0, _inquiry.getCaseMessageList)({
            limit: this.pageLimit,
            page: val
          }).then(function (res) {
            if (res.code == 1) {
              _this9.tableData = res.rows;
              _this9.total = res.total;
            }
          });
        }
      }
    }
  }
};
exports.default = _default;