"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'receiveSettlement',
  data: function data() {
    return {
      docuNum: '',
      //单据号
      associateNum: '',
      //关联单号
      makeAccountDate: '',
      //制单时间
      activeName: '全部',
      tabList: [{
        label: '全部',
        'name': '全部'
      }, {
        label: '待对账',
        'name': '待对账'
      }, {
        label: '待对方审',
        'name': '待对方审'
      }, {
        label: '待付款',
        'name': '待付款'
      }, {
        label: '已结算',
        'name': '已结算'
      }, {
        label: '已作废',
        'name': '已作废'
      }],
      loading: false,
      tableData: [{
        reference: '（2019）赣国仲网字第1号',
        acceptNum: '4500004',
        application: '万海融资租赁（东莞）有限公司',
        respondent: '张时',
        operate: '民间金融纠纷',
        subjectMatter: '10000',
        arbitration: '200',
        status: '待一审',
        origin: '互仲链'
      }],
      currentPage: 4
    };
  },
  methods: {
    //筛选
    screen: function screen() {},
    //tab栏切换
    handleClick: function handleClick(tab, event) {},
    //表格  详情
    gotoDetail: function gotoDetail() {
      this.$router.push({
        name: 'receiveDetail'
      });
    },
    //跳转新建结算单
    gotoCreate: function gotoCreate() {
      this.$router.push({
        name: 'createSettlement'
      });
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {},
    handleCurrentChange: function handleCurrentChange(val) {}
  }
};
exports.default = _default;