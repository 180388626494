"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _inquiry = require("@/api/inquiry");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      show: false,
      loading: false,
      reason: "",
      caseId: ""
    };
  },
  methods: {
    onShow: function onShow(id) {
      this.show = true;
      this.caseId = id;
    },
    onHide: function onHide() {
      this.show = false;
      this.reason = '';
      this.caseId = '';
    },
    onSubmit: function onSubmit() {
      var _this = this;

      if (!this.reason) {
        this.$message.error('请输入理由!');
        return;
      }

      this.loading = true;
      (0, _inquiry.filingRefuse)({
        caseId: this.caseId,
        opinion: this.reason
      }).then(function (res) {
        _this.loading = false;

        if (res.code == 1) {
          _this.loading = false;

          _this.$message({
            message: "保存成功",
            type: "success"
          });

          _this.$emit("onrefresh");
        } else {
          _this.loading = false;

          _this.$message.error(res.msg);
        }
      }).catch(function (error) {
        _this.loading = false;
      });
    }
  }
};
exports.default = _default;