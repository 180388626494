"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _system = require("@/api/system.js");

var _dictionary = require("@/config/dictionary");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'process',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      tableData: [],
      total: 0,
      //总页数
      committeeValue: '',
      //仲裁机构名称
      committeeArr: [],
      statevalue: '',
      //状态值
      stateArr: [{
        //状态数组
        value: '1',
        label: '已启用'
      }, {
        value: '0',
        label: '已禁用'
      }],
      workflowHistoryVisible: false,
      //工作流变更历史弹窗显示
      gridData: [],
      gridPage: 1,
      gridTotal: 0,
      currentProcessId: ''
    };
  },
  created: function created() {
    var _this = this;

    this.currentPage = 1;
    this.init(); //仲裁委员会列表

    (0, _system.getAllCommission)().then(function (res) {
      if (res.code == 1) {
        _this.committeeArr = res.data;
      }
    });
  },
  mounted: function mounted() {},
  methods: {
    // 初始化数据列表
    init: function init() {
      var _this2 = this;

      (0, _system.getProcessList)({
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
          _this2.loading = false;
        }
      });
    },
    //筛选
    screen: function screen() {
      var _this3 = this;

      this.total = 1;
      this.currentPage = 1;

      if (this.statevalue || this.committeeValue) {
        (0, _system.getProcessList)({
          status: this.statevalue,
          commId: this.committeeValue,
          limit: this.pageLimit,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this3.tableData = res.rows;
            _this3.total = res.total;
            _this3.loading = false;
          }
        });
      }
    },
    //点击 清空所有筛选条件
    clearAllConditon: function clearAllConditon() {
      this.statevalue = '';
      this.committeeValue = "";
      this.currentPage = 1;
      this.init();
    },
    //添加流程
    addProcess: function addProcess() {
      this.$router.push({
        name: 'addprocess'
      });
    },
    //表格  查看
    check: function check(val) {
      var _this4 = this;

      this.currentProcessId = val.id;
      (0, _system.checkProcessLog)({
        processId: val.id
      }).then(function (res) {
        if (res.code == 1) {
          _this4.gridData = res.rows;
          _this4.gridTotal = res.total;
        }
      });
      this.workflowHistoryVisible = true;
    },
    //表格  修改
    edit: function edit(val) {
      this.$router.push({
        name: 'addprocess',
        params: {
          id: val.id
        }
      });
    },
    //表格  删除
    deleteRow: function deleteRow(val) {
      var _this5 = this;

      this.$confirm('是否删除该工作流程？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _system.deleteProcess)({
          id: val.id
        }).then(function (res) {
          if (res.code == 1) {
            _this5.$message({
              message: '删除成功',
              type: 'success'
            });

            _this5.init();
          }
        });
      }).catch(function () {
        _this5.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this6 = this;

      this.pageLimit = val;

      if (this.statevalue || this.committeeValue) {
        // 有筛选条件的情况下
        (0, _system.getProcessList)({
          status: this.statevalue,
          commId: this.committeeValue,
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this6.tableData = res.rows;
            _this6.total = res.total;
            _this6.loading = false;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _system.getProcessList)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this6.tableData = res.rows;
            _this6.total = res.total;
            _this6.loading = false;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this7 = this;

      if (this.statevalue || this.committeeValue) {
        // 有筛选条件的情况下
        (0, _system.getProcessList)({
          status: this.statevalue,
          commId: this.committeeValue,
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
            _this7.loading = false;
            _this7.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _system.getProcessList)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
            _this7.loading = false;
            _this7.currentPage = val;
          }
        });
      }
    },
    handleGridSizeChange: function handleGridSizeChange(val) {},
    //查看  分页
    handleGridCurrentChange: function handleGridCurrentChange(val) {
      var _this8 = this;

      this.gridPage = val;
      (0, _system.checkProcessLog)({
        processId: this.currentProcessId,
        limit: this.pageLimit,
        page: val
      }).then(function (res) {
        if (res.code == 1) {
          _this8.gridData = res.rows;
          _this8.gridTotal = res.total;
        }
      });
    }
  }
};
exports.default = _default;