"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addMenu = addMenu;
exports.defaultOrderLine = defaultOrderLine;
exports.deleteMenu = deleteMenu;
exports.getMenuList = getMenuList;
exports.updateMenu = updateMenu;

var _request = _interopRequireDefault(require("@/utils/request"));

function getMenuList() {
  return (0, _request.default)({
    url: '/back/menu/menuList',
    method: 'get'
  });
}

function addMenu(data) {
  return (0, _request.default)({
    url: '/back/menu',
    method: 'post',
    data: data
  });
}

function deleteMenu(data) {
  return (0, _request.default)({
    url: '/back/menu/' + data,
    method: 'delete'
  });
}

function updateMenu(data) {
  return (0, _request.default)({
    url: '/back/menu',
    method: 'put',
    data: data
  });
} // 获取新排序值


function defaultOrderLine(data) {
  return (0, _request.default)({
    url: '/back/menu/defaultOrderLine/' + data,
    method: 'get'
  });
}