"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _inquiry = require("@/api/inquiry.js");

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

var _dictionary = require("@/config/dictionary");

var _auth = require("@/utils/auth");

var _security = require("@/utils/security");

var _encryptKey = require("@/config/encryptKey");

var _arbitrator = require("@/api/arbitrator");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _index2 = require("@/utils/index.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "videoCourt",
  data: function data() {
    return {
      list: [],
      tableHeaderColor: this.$tableHeaderColor,
      pageSizes: this.$tablePageSizes,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      total: 0,
      loading: false,
      tableData: [],
      conditionData: "",
      //来自子组件的数据
      date: "",
      caseState: _dictionary.dictionary.caseState,
      caseId: "",
      id: "",
      rule: "",
      detailData: "",
      //总数据
      identiyState: _dictionary.dictionary.identiyState,
      //案件识别类型
      caseReply: "",
      //答辩状数据   ==>因为返回detailData数据里面，它自成一个对象，必须要声明
      fileType: _dictionary.dictionary.fileType,
      materialState: _dictionary.dictionary.materialState,
      sendMsgType: _dictionary.dictionary.sendMsgType,
      token: (0, _auth.getToken)(),
      uploadVisible: false,
      uploadRule: {},
      uploadApi: ""
    };
  },
  created: function created() {
    this.currentPage = 1;
    this.init();
  },
  components: {
    Caseconditons: _index.default,
    // Editor,
    Tinymce: _Tinymce.default
  },
  methods: {
    init: function init() {
      var _this = this;

      (0, _inquiry.findCaseCourtListByPage)({
        states: [3, 4],
        trialWay: 3,
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleInVideo: function handleInVideo(row) {
      console.log(row);
      this.$router.push({
        path: "/trial/videoTrial",
        query: {
          caseId: row.caseId,
          caseNo: row.caseNo
        }
      }); //videoTrial
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this2 = this;

      this.pageLimit = val;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        this.conditionData.states = [3, 4];
        this.conditionData.trialWay = 3;
        (0, _inquiry.findCaseCourtListByPage)(this.conditionData).then(function (res) {
          if (res.code === 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.findCaseCourtListByPage)({
          states: [3, 4],
          trialWay: 3,
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code === 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this3 = this;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        this.conditionData.states = [3, 4];
        this.conditionData.trialWay = 3;
        (0, _inquiry.findCaseCourtListByPage)(this.conditionData).then(function (res) {
          if (res.code === 1) {
            _this3.tableData = res.rows;
            _this3.total = res.total;
            _this3.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.findCaseCourtListByPage)({
          states: [3, 4],
          trialWay: 3,
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code === 1) {
            _this3.tableData = res.rows;
            _this3.total = res.total;
            _this3.currentPage = val;
          }
        });
      }
    },
    cleanConditionData: function cleanConditionData() {},
    handleAvatarSuccess: function handleAvatarSuccess() {},
    beforeAvatarUpload: function beforeAvatarUpload() {},
    handleUploadSubmit: function handleUploadSubmit() {},
    //点击筛选，发送请求列表
    checkList: function checkList(data) {// 需要发送给后端的数据
      //  this.currentPage = 1;
      // const postData = {
      //   caseId: data.caseId,
      //   caseNo: data.caseNo,
      //   partyName: data.partyName,
      //   beginCtime: data.rowSecondDate[0],
      //   endCtime: data.rowSecondDate[1],
      //   caseType: data.caseTypevalue,
      //   beginRegisterTime: data.rowThirdDate[0],
      //   endRegisterTime: data.rowThirdDate[1],
      //   limit: this.pageLimit,
      //   page: 1
      // };
      // this.conditionData = postData;
      // findCaseCourtListByPage(this.conditionData).then(res => {
      //   if (res.code == 1) {
      //     this.tableData = res.rows;
      //     this.total = res.total;
      //   }
      // });
    }
  }
};
exports.default = _default;