"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _inquiry = require("@/api/inquiry");

var _dictionary = require("@/config/dictionary");

var _partyInfo = _interopRequireDefault(require("@/views/case/handing/partyInfo"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "inquiryDetail",
  components: {
    PartyInfo: _partyInfo.default
  },
  data: function data() {
    return {
      caseState: _dictionary.dictionary.caseState,
      //案件状态
      caseExamineState: _dictionary.dictionary.caseExamineState,
      //案件审核状态
      caseExamineType: _dictionary.dictionary.caseExamineType,
      //案件审核类型
      clicked: 'caseStatus',
      casedetailid: '',
      detailData: '',
      //总数据
      courtInfo: "",
      //仲裁庭信息
      MaterialData1: [],
      //申请人材料列表
      MaterialData2: [],
      //被申请人材料列表
      caseMoveData: [],
      //案件流转记录
      caseServiceData: [],
      //案件送达记录
      rightTableData: [],
      //权利行使记录
      auditRecordData: [],
      //审核记录
      cancelFormShow: false,
      //作废弹窗显示
      cancelform: {
        //作废拒绝弹框的内容
        name: ''
      },
      //申请人案件材料分页
      MaterialTotal1: 0,
      MaterialCurrentPage1: 2,
      // 被申请人案件材料分页
      MaterialTotal2: 0,
      MaterialCurrentPage2: 2,
      //行使权利分页
      RightTotal: 0,
      RightCurrentPage: 2,
      //案件流转分页
      MoveTotal: 0,
      MoveCurrentPage: 2,
      //案件审核
      AudtingTotal: 0,
      AudtingCurrentPage: 2,
      //案件送达
      ServiceTotal: 0,
      ServiceCurrentPage: 2,
      partyInfoVisible: false,
      //当事人信息
      fileType: _dictionary.dictionary.fileType,
      materialState: _dictionary.dictionary.materialState,
      sendMsgType: _dictionary.dictionary.sendMsgType,
      arbitrationRequestFlag: false,
      arbitrationAruthFlag: false
    };
  },
  created: function created() {
    var _this = this;

    this.casedetailid = this.$route.params.caseId; //请求详情数据

    (0, _inquiry.getDetail)(this.casedetailid).then(function (res) {
      if (res.code == 1) {
        _this.detailData = res.data;
        _this.courtInfo = res.data.courtInfo;

        if (_this.detailData.arbitrationRequest.length > 50) {
          _this.arbitrationRequestFlag = true;
        }

        if (_this.detailData.arbitrationAruth.length > 50) {
          _this.arbitrationAruthFlag = true;
        }
      }
    }); //请求申请人案件材料

    (0, _inquiry.getMaterial)({
      type: 1,
      caseId: this.casedetailid,
      limit: 5,
      offset: 0
    }).then(function (res) {
      if (res.code == 1) {
        _this.MaterialData1 = res.rows;
        _this.MaterialTotal1 = res.total;
      }
    }); //请求被申请人案件材料

    (0, _inquiry.getMaterial)({
      type: 2,
      caseId: this.casedetailid,
      limit: 5,
      offset: 0
    }).then(function (res) {
      if (res.code == 1) {
        _this.MaterialData2 = res.rows;
        _this.MaterialTotal2 = res.total;
      }
    }); // 请求案件流转列表

    (0, _inquiry.getCasemoveRecord)({
      caseId: this.casedetailid,
      limit: 5,
      offset: 0
    }).then(function (res) {
      if (res.code == 1) {
        _this.caseMoveData = res.rows;
        _this.MoveTotal = res.total;
      }
    }); //请求案件送达记录列表

    (0, _inquiry.getCaseService)({
      caseId: this.casedetailid,
      limit: 5,
      offset: 0
    }).then(function (res) {
      if (res.code == 1) {
        _this.caseServiceData = res.rows;
        _this.ServiceTotal = res.total;
      }
    }); // 请求行使权利列表

    (0, _inquiry.getCasePower)({
      caseId: this.casedetailid,
      limit: 5,
      offset: 0
    }).then(function (res) {
      _this.rightTableData = res.rows;
      _this.RightTotal = res.total;
    });
  },
  mounted: function mounted() {},
  methods: {
    // 右侧菜单栏点击
    anchorTo: function anchorTo(data) {
      document.getElementById(data).scrollIntoView({
        block: "center"
      });
      this.clicked = data;
    },
    //查看当事人信息
    getPartyInfo: function getPartyInfo(data) {
      var _this2 = this;

      this.partyInfoVisible = true;
      this.$nextTick(function () {
        _this2.$refs.partyInfo.init(data);
      });
    },
    // 点击表格作废按钮
    handleClicktoCancel: function handleClicktoCancel(data) {
      this.cancelFormShow = true;
    },
    //申请人案件材料分页
    handleMaterialSizeChange1: function handleMaterialSizeChange1(val) {},
    handleMaterialCurrentChange1: function handleMaterialCurrentChange1(val) {
      var _this3 = this;

      (0, _inquiry.getMaterial)({
        type: 1,
        caseId: this.casedetailid,
        limit: 5,
        offset: val - 1
      }).then(function (res) {
        if (res.code == 1) {
          _this3.MaterialData1 = res.rows;
          _this3.MaterialTotal1 = res.total;
        }
      });
    },
    //被申请人案件材料分页
    handleMaterialSizeChange2: function handleMaterialSizeChange2(val) {},
    handleMaterialCurrentChange2: function handleMaterialCurrentChange2(val) {
      var _this4 = this;

      (0, _inquiry.getMaterial)({
        type: 2,
        caseId: this.casedetailid,
        limit: 5,
        offset: val - 1
      }).then(function (res) {
        if (res.code == 1) {
          _this4.MaterialData1 = res.rows;
          _this4.MaterialTotal1 = res.total;
        }
      });
    },
    //行使权利分页
    handleRightSizeChange: function handleRightSizeChange(val) {},
    handleRightCurrentChange: function handleRightCurrentChange(val) {
      var _this5 = this;

      (0, _inquiry.getCasePower)({
        caseId: this.casedetailid,
        limit: 5,
        offset: val - 1
      }).then(function (res) {
        _this5.rightTableData = res.rows;
        _this5.RightTotal = res.total;
      });
    },
    //案件流转
    handleMoveSizeChange: function handleMoveSizeChange(val) {},
    handleMoveCurrentChange: function handleMoveCurrentChange(val) {
      var _this6 = this;

      (0, _inquiry.getCasemoveRecord)({
        caseId: this.casedetailid,
        limit: 5,
        offset: val - 1
      }).then(function (res) {
        if (res.code == 1) {
          _this6.caseMoveData = res.rows;
          _this6.MoveTotal = res.total;
        }
      });
    },
    //案件审核
    handleAudtingSizeChange: function handleAudtingSizeChange(val) {},
    handleAudtingCurrentChange: function handleAudtingCurrentChange(val) {},
    //案件送达
    handleServiceSizeChange: function handleServiceSizeChange(val) {},
    handleServiceCurrentChange: function handleServiceCurrentChange(val) {
      var _this7 = this;

      (0, _inquiry.getCaseService)({
        caseId: this.casedetailid,
        limit: 5,
        offset: val - 1
      }).then(function (res) {
        if (res.code == 1) {
          _this7.caseServiceData = res.rows;
          _this7.ServiceTotal = res.total;
        }
      });
    }
  }
};
exports.default = _default;