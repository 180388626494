"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _inquiry = require("@/api/inquiry.js");

var _validate = require("@/utils/validate.js");

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

var _dictionary = require("@/config/dictionary");

var _index2 = require("@/utils/index.js");

var _security = require("@/utils/security");

var _encryptKey = require("@/config/encryptKey");

var _send = _interopRequireDefault(require("../service/send"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//定义一个全局的变量
var validPhone = function validPhone(rule, value, callback) {
  if (!value) {
    callback(new Error('请输入电话号码'));
  } else if (!(0, _validate.isvalidPhone)(value)) {
    callback(new Error('请输入正确的11位手机号码'));
  } else {
    callback();
  }
};

var _default = {
  name: 'manage',
  data: function data() {
    return {
      loading: true,
      tableHeaderColor: this.$tableHeaderColor,
      supplyBtnLoading: false,
      //上传材料弹窗的按钮loading
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      suppleMaterialVisible: false,
      //补充材料弹窗
      tableData: [],
      multipleSelection: [],
      multipleSelectionId: [],
      total: 0,
      //总页数
      caseState: _dictionary.dictionary.caseState,
      identityType: _dictionary.dictionary.partyType,
      partyIdType: _dictionary.dictionary.partyIdType,
      evidenceStatus: _dictionary.dictionary.evidenceStatus,
      card: _dictionary.dictionary.card,
      sex: _dictionary.dictionary.sex,
      uploadSuccess: false,
      materUploadTip: false,
      ruleForm: {
        content: ''
      },
      rules: {
        content: [{
          required: true,
          message: '请输入取证内容'
        }]
      },
      materFileList: [],
      //补充材料的上传材料列表
      currentCaseId: '',
      //当前详情caseid
      uploadTip: false,
      //补充资料=>上传文件提示
      conditionData: '',
      //筛选数据
      headers: {
        'Authorization': localStorage.getItem('token')
      },
      uploadData: {
        file: ''
      },
      action: process.env.VUE_APP_BASE_API + (0, _inquiry.partyUploadFiles)(),
      sendCaseVisible: false,
      checkSuppleMaterialVisible: false,
      materContent: '',
      fileList: ''
    };
  },
  created: function created() {
    this.init();
  },
  mounted: function mounted() {},
  components: {
    Caseconditons: _index.default,
    SendCase: _send.default
  },
  methods: {
    // 初始化数据列表
    init: function init() {
      var _this = this;

      this.loading = true;
      (0, _inquiry.getObtainEvidentList)({
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this2 = this;

      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _inquiry.getObtainEvidentList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    refreshPage: function refreshPage() {
      this.sendCaseVisible = false;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //表格禁止勾选事件
    selectable: function selectable(row, index) {
      if (row.sendState == 4) {
        return false;
      } else {
        return true;
      }
    },
    batchUpload: function batchUpload() {
      this.suppleMaterialVisible = true;
    },
    //表格  案号点击
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    // 案件送达
    updateShow: function updateShow() {
      this.supplyBtnLoading = false;
      this.sendCaseVisible = true;
      this.suppleMaterialVisible = false;
    },
    updateErrorShow: function updateErrorShow() {
      this.supplyBtnLoading = false;
      this.sendCaseVisible = false;
    },
    // 表格  查看按钮
    check: function check(val) {
      var _this3 = this;

      this.checkSuppleMaterialVisible = true;
      (0, _inquiry.checkEvidentMaterial)({
        caseId: val
      }).then(function (res) {
        if (res.code == 1) {
          _this3.materContent = res.data.content;
          _this3.fileList = res.data.sendFileList;
        }
      });
    },
    // 表格   补充材料按钮
    supple: function supple(val) {
      this.currentCaseId = val;
      this.suppleMaterialVisible = true;
      this.$refs.multipleTable.clearSelection(); // 初始化补充材料弹框

      this.materFileList = [];
      this.materUploadTip = false;

      if (this.$refs['ruleForm']) {
        this.$refs['ruleForm'].resetFields();
      }
    },
    //表格 重新上传材料
    suppleAgain: function suppleAgain(val) {
      var _this4 = this;

      this.currentCaseId = val;
      this.suppleMaterialVisible = true;
      this.$refs.multipleTable.clearSelection();
      this.materFileList = [];
      this.materUploadTip = false;

      if (this.$refs['ruleForm']) {
        this.$refs['ruleForm'].resetFields();
      } // 初始化补充材料弹框


      (0, _inquiry.checkEvidentMaterial)({
        caseId: val
      }).then(function (res) {
        if (res.code == 1) {
          _this4.ruleForm.content = res.data.content;
          res.data.sendFileList.forEach(function (ele) {
            _this4.materFileList.push({
              name: ele.fileName,
              url: ele.href,
              filePath: ele.filePath
            });
          });
        }
      });
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this5 = this;

      this.pageLimit = val;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getObtainEvidentList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this5.tableData = res.rows;
            _this5.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getObtainEvidentList)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this5.tableData = res.rows;
            _this5.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this6 = this;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _inquiry.getObtainEvidentList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this6.tableData = res.rows;
            _this6.total = res.total;
            _this6.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getObtainEvidentList)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this6.tableData = res.rows;
            _this6.total = res.total;
            _this6.currentPage = val;
          }
        });
      }
    },
    //补充材料  表单提交
    submitForm: function submitForm(formName) {
      var _this7 = this;

      // 处理新上传的文件的数据   ==>转为字符串
      var fileStr = [];
      this.materFileList.forEach(function (item, index) {
        fileStr.push(item.filePath);
      });

      if (this.multipleSelection.length > 0) {
        this.multipleSelection.forEach(function (ele) {
          _this7.multipleSelectionId.push(ele.caseId);
        });
      }

      this.$refs[formName].validate(function (valid) {
        if (valid && _this7.materFileList.length > 0) {
          _this7.supplyBtnLoading = true;

          if (_this7.multipleSelection.length > 0) {
            (0, _inquiry.obtainEvidentBatchUpload)({
              filePath: fileStr.join(','),
              content: _this7.ruleForm.content,
              caseIds: _this7.multipleSelectionId
            }).then(function (res) {
              if (res.code == 1) {
                _this7.$message({
                  message: '上传材料成功',
                  type: 'success'
                });

                _this7.$refs[formName].resetFields();

                _this7.suppleMaterialVisible = false;
                _this7.materUploadTip = false;
                _this7.materFileList = [];
                _this7.multipleSelectionId = [];
                _this7.supplyBtnLoading = false;

                _this7.init();
              } else {
                _this7.supplyBtnLoading = false;

                _this7.$message.error(res.msg);
              }
            });
          } else {
            (0, _inquiry.obtainEvidentUpload)({
              filePath: fileStr.join(','),
              content: _this7.ruleForm.content,
              caseId: _this7.currentCaseId
            }).then(function (res) {
              if (res.code == 1) {
                _this7.sendCaseVisible = true;

                _this7.$message({
                  message: '上传材料成功',
                  type: 'success'
                });

                _this7.$refs[formName].resetFields();

                _this7.suppleMaterialVisible = false;
                _this7.materUploadTip = false;
                _this7.materFileList = [];
                _this7.sendCaseVisible = true;

                _this7.$nextTick(function () {
                  _this7.$refs.sendCase.otherInit(res.data, _this7.currentCaseId, '6');
                });

                _this7.supplyBtnLoading = false;
              } else {
                _this7.supplyBtnLoading = false;

                _this7.$message.error(res.msg);
              }
            });
          }
        } else {
          _this7.materUploadTip = true;
          return false;
        }
      });
    },
    // 上传前判断文件的类型和大小
    beforeUpload: function beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      testmsg = testmsg.toLowerCase();
      var extension = testmsg === 'png';
      var extension2 = testmsg === 'jpg';
      var extension3 = testmsg === 'pdf';
      var isLt2M = file.size / 1024 / 1024 < 5;

      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: '上传文件只能是png、jpg、pdf格式!',
          type: 'warning'
        });
      }

      if (!isLt2M) {
        this.$message({
          message: '上传文件大小不能超过 5MB!',
          type: 'warning'
        });
      }

      return (extension || extension2 || extension3) && isLt2M;
    },
    // 删除文件前
    beforeRemove: function beforeRemove(file, fileList) {
      return this.$confirm("\u786E\u5B9A\u79FB\u9664 ".concat(file.name, "\uFF1F"));
    },
    //上传   移除文件的事件
    handleRemove: function handleRemove(file, fileList) {
      var index = (0, _index2.findWithAttr)(this.materFileList, 'uid', file.uid);
      this.materFileList.splice(index, 1);
    },
    //上传   点击文件列表中的文件
    handlePreview: function handlePreview(file) {
      if (file.url) {
        window.open(file.url);
      } else {
        var res = JSON.parse((0, _security.decryptByAES)(file.response.response, _encryptKey.encryptKey.key));

        if (res.code == 1) {
          window.open(res.data.href);
        } else {
          location.reload();
        }
      }
    },
    //上传   超过限制时的事件
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E910\u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u9009\u62E9\u4E86 ".concat(files.length, " \u4E2A\u6587\u4EF6\uFF0C\u5171\u9009\u62E9\u4E86 ").concat(files.length + fileList.length, " \u4E2A\u6587\u4EF6"));
    },
    //上传成功时的钩子
    handleSuccess: function handleSuccess(res1, file, fileList) {
      var res = JSON.parse((0, _security.decryptByAES)(res1.response, _encryptKey.encryptKey.key));

      if (res.code == 1) {
        var testmsg = file.name.substring(0, file.name.indexOf('.'));

        if (!this.ruleForm.sumbitName) {
          this.ruleForm.sumbitName = testmsg;
        }

        this.materFileList.push({
          name: res.data.fileName,
          url: res.data.aliyunOssHref,
          filePath: res.data.filePath,
          uid: file.uid
        });
        this.materUploadTip = false;
      } else {
        location.reload();
      }
    }
  }
};
exports.default = _default;