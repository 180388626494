"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    content: {
      default: ""
    },
    delay: {
      type: Number,
      default: 0.5
    },
    speed: {
      type: Number,
      default: 100
    }
  },
  mounted: function mounted() {},
  data: function data() {
    return {
      wrapWidth: 0,
      //父盒子宽度
      firstRound: true,
      //判断是否
      duration: 0,
      //css3一次动画需要的时间
      offsetWidth: 0,
      //子盒子的宽度
      animationClass: "" //添加animate动画

    };
  },
  computed: {
    contentStyle: function contentStyle() {
      return {
        //第一次从头开始,第二次动画的时候需要从最右边出来所以宽度需要多出父盒子的宽度
        paddingLeft: (this.firstRound ? 0 : this.wrapWidth) + "px",
        //只有第一次的时候需要延迟
        animationDelay: (this.firstRound ? this.delay : 0) + "s",
        animationDuration: this.duration + "s"
      };
    }
  },
  watch: {
    content: {
      //监听到有内容,从后台获取到数据了,开始计算宽度,并计算时间,添加动画
      handler: function handler() {
        var _this = this;

        this.$nextTick(function () {
          var _this$$refs = _this.$refs,
              wrap = _this$$refs.wrap,
              content = _this$$refs.content;
          var wrapWidth = wrap.getBoundingClientRect().width;
          var offsetWidth = content.getBoundingClientRect().width;
          _this.wrapWidth = wrapWidth;
          _this.offsetWidth = offsetWidth;
          _this.duration = offsetWidth / _this.speed;
          _this.animationClass = "animate";
        });
      }
    }
  },
  methods: {
    //这个函数是第一次动画结束的时候,第一次没有使用infinite,第一次动画执行完成后开始使用添加animate-infinite动画
    onAnimationEnd: function onAnimationEnd() {
      this.firstRound = false; //这是时候样式多出了padding-left:this.wrapWidth;所以要想速度一样需要重新计算时间

      this.duration = (this.offsetWidth + this.wrapWidth) / this.speed;
      this.animationClass = "animate-infinite";
    }
  }
};
exports.default = _default;