"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _commission = require("@/api/commission.js");

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

var _dictionary = require("@/config/dictionary");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'handing',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      pageSizes: this.$tablePageSizes,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      total: 0,
      loading: true,
      tableData: [],
      conditionData: '',
      //来自子组件的数据
      span: 18,
      multipleSelection: [],
      multipleSelectionId: [],
      id: '',
      updateVisble: false,
      updateForm: {
        id: '',
        name: '',
        address: '',
        tel: '',
        defaultAddress: '0'
      },
      updateRules: {
        name: [{
          required: true,
          message: '请填写名称',
          trigger: 'blur'
        }],
        address: [{
          required: true,
          message: '请填写地址',
          trigger: 'blur'
        }],
        tel: [{
          required: true,
          message: '请填写电话',
          trigger: 'blur'
        }],
        defaultAddress: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }]
      }
    };
  },
  created: function created() {
    this.currentPage = 1;
    this.init();
  },
  components: {
    Caseconditons: _index.default
  },
  methods: {
    init: function init() {
      var _this = this;

      this.loading = true;
      (0, _commission.getCourtInfoPage)({
        deleteState: '',
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCancel: function handleCancel() {
      this.updateVisble = false;
      this.updateForm = {};
    },
    //修改||新增
    handleUpdate: function handleUpdate(data) {
      this.id = data.id;
      this.updateVisble = true;

      if (this.id) {
        this.updateForm = {
          id: data.id,
          name: data.name,
          address: data.address,
          tel: data.tel,
          defaultAddress: data.defaultAddress + ''
        };
      } else {
        this.updateForm = {
          id: '',
          name: '',
          address: '',
          tel: '',
          defaultAddress: '0'
        };
      }
    },
    handleUpdateSubmit: function handleUpdateSubmit() {
      var _this2 = this;

      this.$refs['updateForm'].validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          (0, _commission.insertCourtInfo)(_this2.updateForm).then(function (res) {
            _this2.loading = false;

            if (res.code == 1) {
              _this2.$message({
                message: '保存成功',
                type: 'success'
              });

              _this2.updateVisble = false;

              _this2.init();
            } else {
              _this2.$message.error(res.msg);
            }
          }).catch(function (error) {});
        }
      });
    },
    handleDelete: function handleDelete() {
      var _this3 = this;

      this.$confirm('确定删除选中的数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.multipleSelection.forEach(function (item, index) {
          _this3.multipleSelectionId.push(item.id);
        });

        _this3.loading = true;
        (0, _commission.delCourtInfo)({
          ids: _this3.multipleSelectionId
        }).then(function (res) {
          _this3.loading = false;

          if (res.code == 1) {
            _this3.$message({
              message: '删除成功！',
              type: 'success'
            });

            _this3.init();
          } else {
            _this3.$message.error(res.msg);
          }

          _this3.multipleSelectionId = [];
        }).catch(function (error) {
          _this3.loading = false;
        });
      });
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this4 = this;

      this.pageLimit = val; //没有筛选条件的情况下

      (0, _commission.getCourtInfoPage)({
        deleteState: '',
        limit: val,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this4.tableData = res.rows;
          _this4.total = res.total;
        }
      });
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this5 = this;

      //没有筛选条件的情况下
      (0, _commission.getCourtInfoPage)({
        deleteState: '',
        limit: this.pageLimit,
        page: val
      }).then(function (res) {
        if (res.code == 1) {
          _this5.tableData = res.rows;
          _this5.total = res.total;
          _this5.currentPage = val;
        }
      });
    }
  }
};
exports.default = _default;