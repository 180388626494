"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

var _inquiry = require("@/api/inquiry.js");

var _commission = require("@/api/commission.js");

var _dictionary = require("@/config/dictionary");

var _send = _interopRequireDefault(require("@/views/case/service/send"));

var _index2 = require("@/utils/index.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'division',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      pageSizes: this.$tablePageSizes,
      loading: true,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      tableData: [],
      total: 0,
      //总页数
      conditionData: '',
      //来自子组件的数据
      caseState: _dictionary.dictionary.caseState,
      multipleSelection: [],
      multipleSelectionId: [],
      wayList: [{
        name: '书面审理',
        id: '1'
      }, {
        name: '线下开庭',
        id: '2'
      }, {
        name: '视频开庭',
        id: '3'
      }],
      selectArbVisible: false,
      //案件组庭弹窗显示
      selectArbForm: {
        //案件组庭数据
        chiefArbitratorId: "",
        chiefArbitratorName: '',
        secondArbitratorId: '',
        secondArbitratorName: '',
        thirdArbitratorId: '',
        thirdArbitratorName: '',
        trialWay: '1'
      },
      selectArbRules: {
        chiefArbitratorId: [{
          required: true,
          message: '请选择首席仲裁员',
          trigger: 'change'
        }],
        trialWay: [{
          required: true,
          message: '请选择审理方式',
          trigger: 'change'
        }]
      },
      caseId: '',
      arbitratorList: [],
      partyChoiceArb: {
        appArbitratorName: '',
        resArbitratorName: ''
      },
      sendCaseVisible: false,
      gotoServicing: false //选定仲裁员弹窗 按钮loading

    };
  },
  created: function created() {
    this.currentPage = 1;
    this.init();
  },
  components: {
    Caseconditons: _index.default,
    SendCase: _send.default
  },
  methods: {
    init: function init() {
      var _this = this;

      this.loading = true;
      (0, _inquiry.getList)({
        state: '2',
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //已选择仲裁员
    getChoiceArbitrator: function getChoiceArbitrator(data) {
      var _this2 = this;

      (0, _inquiry.getChoiceArbitrator)(data).then(function (res) {
        if (res.code == 1) {
          _this2.partyChoiceArb = res.data;

          if (res.data.chiefArbitratorId) {
            _this2.selectArbForm.chiefArbitratorId = res.data.chiefArbitratorId;
          }

          if (res.data.secondArbitratorId) {
            _this2.selectArbForm.secondArbitratorId = res.data.secondArbitratorId;
          }

          if (res.data.thirdArbitratorId) {
            _this2.selectArbForm.thirdArbitratorId = res.data.thirdArbitratorId;
          }

          if (res.data.trialWay) {
            _this2.selectArbForm.trialWay = res.data.trialWay;
          }
        }
      });
    },
    //仲裁员列表
    getArbitratorList: function getArbitratorList() {
      var _this3 = this;

      (0, _inquiry.getArbitratorList)().then(function (res) {
        if (res.code == 1) {
          _this3.arbitratorList = res.data;
        }
      });
    },
    //批量审核
    batchReview: function batchReview() {
      var _this4 = this;

      this.caseNum = this.multipleSelectionId.length;
      this.$confirm('确定批量审核？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.multipleSelectionId = [];

        _this4.multipleSelection.forEach(function (item) {
          _this4.multipleSelectionId.push(item.caseId);
        });

        (0, _inquiry.batchProcedureByAdopt)({
          caseIds: _this4.multipleSelectionId
        }).then(function (res) {
          if (res.code == 1) {
            _this4.$message({
              message: '批量审核成功',
              type: 'success'
            });

            _this4.init();
          } else {
            _this4.$message.error(res.msg);
          }
        });
      });
    },
    updateShow: function updateShow() {
      this.selectArbVisible = false;
      this.sendCaseVisible = true;
      this.gotoServicing = false; // if(!this.fullscreenLoading){
      //   this.fullscreenLoading=false;
      // }
    },
    updateErrorShow: function updateErrorShow() {
      this.sendCaseVisible = false;
      this.gotoServicing = false;
    },
    // 审核通过后刷新数据
    refreshPage: function refreshPage() {
      this.sendCaseVisible = false;
      this.init();
    },
    //审核
    handleReview: function handleReview(data) {
      this.caseId = data.caseId;
      this.selectArbForm.chiefArbitratorId = '';
      this.selectArbForm.secondArbitratorId = '';
      this.selectArbForm.thirdArbitratorId = '';
      this.getChoiceArbitrator(this.caseId);
      this.getArbitratorList();
      this.selectArbVisible = true;
    },
    //弹窗确认按钮
    gotoService: function gotoService() {
      var _this5 = this;

      this.$refs['selectArbForm'].validate(function (valid) {
        if (valid) {
          _this5.gotoServicing = true;

          _this5.arbitratorList.forEach(function (item) {
            if (item.id == _this5.selectArbForm.chiefArbitratorId) {
              _this5.selectArbForm.chiefArbitratorName = item.name;
            } else if (item.id == _this5.selectArbForm.secondArbitratorId) {
              _this5.selectArbForm.secondArbitratorName = item.name;
            } else if (item.id == _this5.selectArbForm.thirdArbitratorId) {
              _this5.selectArbForm.thirdArbitratorName = item.name;
            }
          });

          _this5.selectArbForm.caseId = _this5.caseId;
          (0, _commission.commChoiceArbitrator)(_this5.selectArbForm).then(function (res) {
            if (res.code == 1) {
              _this5.sendCaseVisible = true;

              _this5.$nextTick(function () {
                _this5.$refs.sendCase.init(_this5.caseId, 2);
              });
            } else {
              _this5.gotoServicing = false;

              _this5.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this5.gotoServicing = false;
          });
        } else {
          return false;
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this6 = this;

      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        state: 2,
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _inquiry.getList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this6.tableData = res.rows;
          _this6.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this7 = this;

      this.pageLimit = val;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        this.conditionData.state = '2';
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          limit: val,
          page: this.currentPage,
          state: '2'
        }).then(function (res) {
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this8 = this;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        this.conditionData.state = '2';
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
            _this8.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          limit: this.pageLimit,
          page: val,
          state: '2'
        }).then(function (res) {
          if (res.code == 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
            _this8.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;