"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'createSettlement',
  data: function data() {
    return {
      cycleVal: '',
      //周期值
      loading: false,
      tableData: [{
        reference: '（2019）赣国仲网字第1号',
        acceptNum: '4500004',
        application: '万海融资租赁（东莞）有限公司',
        respondent: '张时',
        operate: '民间金融纠纷',
        subjectMatter: '10000',
        arbitration: '200',
        status: '待一审',
        origin: '互仲链'
      }],
      currentPage: 4,
      noteMakingVisible: false,
      //制单备注显示
      noteMakeVal: '' //制单备注内容

    };
  },
  methods: {
    dateChange: function dateChange() {},
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {},
    handleCurrentChange: function handleCurrentChange(val) {}
  }
};
exports.default = _default;