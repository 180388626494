import { render, staticRenderFns } from "./comp-device-select.vue?vue&type=template&id=3338545a&scoped=true&"
import script from "./comp-device-select.vue?vue&type=script&lang=js&"
export * from "./comp-device-select.vue?vue&type=script&lang=js&"
import style0 from "./comp-device-select.vue?vue&type=style&index=0&id=3338545a&lang=scss&scoped=true&"
import style1 from "./comp-device-select.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3338545a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/work/project/aihuz/arbitration-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3338545a')) {
      api.createRecord('3338545a', component.options)
    } else {
      api.reload('3338545a', component.options)
    }
    module.hot.accept("./comp-device-select.vue?vue&type=template&id=3338545a&scoped=true&", function () {
      api.rerender('3338545a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/trial/videoTrial/demo/components/comp-device-select.vue"
export default component.exports