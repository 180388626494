"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("/work/project/aihuz/arbitration-ui/node_modules/core-js/modules/es6.array.iterator.js");

require("/work/project/aihuz/arbitration-ui/node_modules/core-js/modules/es6.promise.js");

require("/work/project/aihuz/arbitration-ui/node_modules/core-js/modules/es6.object.assign.js");

require("/work/project/aihuz/arbitration-ui/node_modules/core-js/modules/es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("element-ui/lib/theme-chalk/index.css");

var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));

var _echarts = _interopRequireDefault(require("echarts"));

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("@/icons");

require("@/permission");

var _vueSignaturePad = _interopRequireDefault(require("vue-signature-pad"));

var _index3 = _interopRequireDefault(require("@/directive/permission/index.js"));

// A modern alternative to CSS resets
// global css
// icon
// permission control
_vue.default.prototype.$echarts = _echarts.default;
_vue.default.prototype.$tableHeaderColor = "rgba(247, 248, 250)";
_vue.default.prototype.$tablePageSizes = [5, 10, 20, 50, 100, 200]; // set ElementUI lang to EN

_vue.default.use(_elementUi.default, {
  zhLocale: _zhCN.default
});

_vue.default.use(_vueSignaturePad.default); // 使用全局权限指令


// 权限判断指令
_vue.default.use(_index3.default);

_vue.default.config.productionTip = false;
new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});