var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "handing global-page-base-layout",
      attrs: { loading: _vm.loading },
    },
    [
      _c("Caseconditons", {
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "handing-all-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.multipleSelection.length <= 0 },
              on: { click: _vm.batchReview },
            },
            [_vm._v("批量发起组庭")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "handing-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  selectable: _vm.selectable,
                  width: "55",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerApplyTime", label: "申请日期" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "controversyAmount", label: "争议金额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.caseState[scope.row.state]) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "handingSecretaryName", label: "办案秘书" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerSendTime", label: "立案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.handleCheck(scope.row)
                              },
                            },
                          },
                          [_vm._v("【发起组庭】")]
                        ),
                        _vm._v(" "),
                        _c("br"),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发起组庭",
            visible: _vm.divisionVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.divisionVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "partyChoiceArb",
              attrs: {
                "label-position": "right",
                "label-width": "150px",
                model: _vm.partyChoiceArb,
                rules: _vm.divisionRules,
              },
            },
            [
              _c("el-form-item", { attrs: { label: "申请人选定:" } }, [
                _vm.partyChoiceArb.appArbitratorName
                  ? _c("span", { attrs: { underline: false } }, [
                      _vm._v(_vm._s(_vm.partyChoiceArb.appArbitratorName)),
                    ])
                  : _c("span", { attrs: { underline: false } }, [
                      _vm._v("未选定仲裁员"),
                    ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "被申请人选定:" } },
                [
                  _vm.partyChoiceArb.resArbitratorName
                    ? _c("el-button", [
                        _vm._v(_vm._s(_vm.partyChoiceArb.resArbitratorName)),
                      ])
                    : _c("span", { attrs: { underline: false } }, [
                        _vm._v("未选定仲裁员"),
                      ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "答辩状:" } }, [
                _c(
                  "div",
                  { staticClass: "case-txt" },
                  [
                    _vm.partyChoiceArb.content == ""
                      ? _c("span", [_vm._v("暂无")])
                      : _c("el-input", {
                          staticStyle: { width: "80%" },
                          attrs: {
                            type: "textarea",
                            resize: "none",
                            rows: "10",
                            disabled: "",
                          },
                          model: {
                            value: _vm.partyChoiceArb.content,
                            callback: function ($$v) {
                              _vm.$set(_vm.partyChoiceArb, "content", $$v)
                            },
                            expression: "partyChoiceArb.content",
                          },
                        }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "答辩材料:" } }, [
                _c(
                  "div",
                  { staticClass: "case-txt" },
                  [
                    _vm.partyChoiceArb.replyName == ""
                      ? _c("span", [_vm._v("暂无")])
                      : _c(
                          "el-link",
                          {
                            staticClass: "margin-right20",
                            attrs: {
                              type: "primary",
                              underline: false,
                              href: _vm.partyChoiceArb.href,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.partyChoiceArb.replyName) +
                                "\n          "
                            ),
                          ]
                        ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下一级审核人:", prop: "secretaryId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.partyChoiceArb.secretaryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.partyChoiceArb, "secretaryId", $$v)
                        },
                        expression: "partyChoiceArb.secretaryId",
                      },
                    },
                    _vm._l(_vm.secretaryList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "经办意见:", prop: "opinion" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      type: "textarea",
                      rows: "5",
                      placeholder: "请填写经办意见",
                      resize: "none",
                    },
                    model: {
                      value: _vm.partyChoiceArb.opinion,
                      callback: function ($$v) {
                        _vm.$set(_vm.partyChoiceArb, "opinion", $$v)
                      },
                      expression: "partyChoiceArb.opinion",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.divisionVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.gotoServicing },
                  on: {
                    click: function ($event) {
                      return _vm.caseCourtAdopt("partyChoiceArb")
                    },
                  },
                },
                [_vm._v("进行组庭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }