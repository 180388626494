"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _block = require("@/api/block");

var _settings = _interopRequireDefault(require("@/settings"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      // value:'486823424',
      value: '',
      list: '',
      result: 3,
      numberValidateForm: {
        value: ''
      }
    };
  },
  created: function created() {
    document.title = _settings.default.title;
  },
  methods: {
    input: function input(val) {
      if (val.length > 0) {
        this.result = '';
      } else {
        this.result = 3;
      }
    },
    submitForm: function submitForm(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _block.blockSearch)({
            preservationId: _this.numberValidateForm.value
          }).then(function (res) {
            if (res.code == 1) {
              _this.list = res.data;

              if ('entityId' in _this.list) {
                _this.result = 1;
              }
            } else {
              _this.result = 2;
            }
          });
        } else {
          return false;
        }
      });
    },
    gotoIndex: function gotoIndex() {
      this.$router.push({
        path: '/'
      });
    },
    gotoBlockIndex: function gotoBlockIndex() {
      this.$router.push({
        path: '/chainIndex'
      });
    }
  }
};
exports.default = _default;