"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _commission = require("@/api/commission.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "",
  props: {},
  data: function data() {
    return {
      loading: false,
      tableData: []
    };
  },
  created: function created() {
    this.init();
  },
  mounted: function mounted() {},
  methods: {
    init: function init() {
      var _this = this;

      this.loading = true;
      (0, _commission.remindTodoConfigList)().then(function (res) {
        _this.loading = false;

        if (res.code == 1) {
          _this.tableData = res.data;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    remindDayBlur: function remindDayBlur(data) {
      var _this2 = this;

      if (!data.remindDay) {
        this.$message.error('不能为空');
        return;
      } else {
        (0, _commission.updateProcessNode)({
          id: data.id,
          remindDay: data.remindDay
        }).then(function (res) {
          if (res.code == 1) {
            _this2.$message({
              message: '保存成功',
              type: 'success'
            });
          } else {
            _this2.$message.error(res.msg);
          }
        });
      }
    },
    remindStateChange: function remindStateChange(data) {
      var _this3 = this;

      (0, _commission.updateProcessNode)({
        id: data.id,
        remindState: data.remindState
      }).then(function (res) {
        if (res.code == 1) {
          _this3.$message({
            message: '保存成功',
            type: 'success'
          });
        } else {
          _this3.$message.error(res.msg);
        }
      });
    }
  }
};
exports.default = _default;