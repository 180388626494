"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _inquiry = require("@/api/inquiry");

var _dictionary = require("@/config/dictionary");

var _send = _interopRequireDefault(require("../service/send"));

var _partyInfo = _interopRequireDefault(require("./partyInfo"));

var _rejectCase = _interopRequireDefault(require("@/views/case/handing/rejectCase"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "handingDetail",
  components: {
    SendCase: _send.default,
    PartyInfo: _partyInfo.default,
    rejectCase: _rejectCase.default
  },
  data: function data() {
    return {
      clicked: 'caseStatus',
      FilingPassLoading: false,
      //按钮loading
      dealFormShowLoading: false,
      //案件受理的仲裁费汇总的那个弹窗
      dealFormPayLoading: false,
      secretOpinionLoading: false,
      //等待案件受理第一弹窗结果
      sendCasePayLoading: false,
      //等待案件受理第一弹窗结果
      finalPassLoading: false,
      //审核通过&发送
      showRegisterLoading: false,
      //查看立案文书
      identiyState: _dictionary.dictionary.identiyState,
      //案件识别类型
      cancelform: {
        //作废拒绝弹框的内容
        reason: '',
        state: '2',
        id: ''
      },
      cancelRules: {
        reason: [{
          required: true,
          trigger: 'blur',
          message: '请填写作废理由'
        }]
      },
      optionArr: [{
        value: '你好',
        label: '您好'
      }, {
        value: '请问可以处理吗',
        label: '请问可以处理吗'
      }],
      cancelFormShow: false,
      //作废拒绝弹窗
      dealFormShow: false,
      //案件处理弹框显示
      casePayFormShow: false,
      dealform: {
        //案件处理弹框1数据
        arbitrationReason: '',
        controversyAmount: '',
        zcFee: '',
        slFee: '',
        clFee: '',
        herf: '',
        fileName: '',
        secretaryId: '',
        opinion: '',
        secretaryName: '',
        caseAmount: '',
        amount: '',
        payTime: '',
        remarks: '',
        reasonId: '',
        reasonName: '',
        caseNo: ''
      },
      dealPayRules: {
        secretaryId: [{
          required: true,
          trigger: 'change',
          message: '请选择处理人'
        }],
        opinion: [{
          required: true,
          trigger: 'blur',
          message: '请填写意见'
        }],
        zcFee: [{
          required: true,
          trigger: 'blur',
          message: '请填写仲裁费'
        }]
      },
      dealrules: {
        reasonId: [{
          required: true,
          trigger: 'change',
          message: '请选择案由'
        }],
        secretaryId: [{
          required: true,
          trigger: 'change',
          message: '请选择处理人'
        }],
        opinion: [{
          required: true,
          trigger: 'blur',
          message: '请填写意见'
        }]
      },
      reasonList: [],
      innerVisible: false,
      //费用计算弹框
      claimCount: '',
      dealFormShow2: false,
      //案件处理弹框2显示
      dealform2: {
        //案件处理弹框2数据
        source: '1'
      },
      dealformPass: {
        //案件处理弹框2数据
        secretaryId: '',
        secretaryName: '',
        opinion: '',
        caseId: this.$route.params.caseId
      },
      dealformMaterial: {
        recordContent: '',
        opinion: '',
        caseId: this.$route.params.caseId
      },
      dealformRefuse: {
        opinion: '',
        caseId: this.$route.params.caseId
      },
      dealrulesPass: {
        secretaryId: [{
          required: true,
          trigger: 'change',
          message: '请选择'
        }],
        opinion: [{
          required: true,
          trigger: 'blur',
          message: '请填写意见'
        }]
      },
      dealrulesMaterial: {
        recordContent: [{
          required: true,
          trigger: 'change',
          message: '请选择'
        }],
        opinion: [{
          required: true,
          trigger: 'blur',
          message: '请填写意见'
        }]
      },
      dealrulesRefuse: {
        opinion: [{
          required: true,
          trigger: 'blur',
          message: '请填写理由'
        }]
      },
      arbitryData: [{
        free1: '3450000',
        free2: '500'
      }],
      computePriceForm: {
        slFee: '',
        clFee: '',
        zcFee: ''
      },
      languageinnerVisible: false,
      //添加常用语弹窗显示
      newLanguage: '',
      //添加新的常用语的内容数据同步
      detailData: '',
      //总数据
      partyType: _dictionary.dictionary.partyType,
      caseState: _dictionary.dictionary.caseState,
      appMaterialData: [],
      materialState: _dictionary.dictionary.materialState,
      fileType: _dictionary.dictionary.fileType,
      secretaryList: [],
      sendCaseVisible: false,
      //案件送达
      partyInfoVisible: false,
      //当事人信息
      span: 16,
      arbitrationRequestFlag: false,
      arbitrationAruthFlag: false,
      appMaterialTotal: 0,
      //申请人案件材料的分页
      appMaterialCurrentPage: 1
    };
  },
  activated: function activated() {
    // 弹窗层全部关闭
    this.cancelFormShow = false;
    this.dealFormShow = false;
    this.innerVisible = false;
    this.dealFormShow2 = false;
    this.languageinnerVisible = false;
  },
  created: function created() {
    var _this = this;

    // 弹窗层全部关闭
    this.cancelFormShow = false;
    this.dealFormShow = false;
    this.innerVisible = false;
    this.dealFormShow2 = false;
    this.languageinnerVisible = false;
    this.casedetailid = this.$route.params.caseId; //请求详情数据

    (0, _inquiry.getDetail)(this.casedetailid).then(function (res) {
      if (res.code == 1) {
        _this.detailData = res.data;
        _this.appMaterialData = res.data.appProofFileList;
        /*if(this.detailData.arbitrationRequest.length > 50){
            this.arbitrationRequestFlag = true;
        }*/

        /*if(this.detailData.arbitrationAruth.length > 50){
            this.arbitrationAruthFlag = true;
        }*/
      }
    });
  },
  mounted: function mounted() {},
  methods: {
    //秘书列表
    getSecretaryList: function getSecretaryList() {
      var _this2 = this;

      (0, _inquiry.getSecretaryList)({
        id: this.casedetailid
      }).then(function (res) {
        if (res.code == 1) {
          _this2.secretaryList = res.data[0].allReviews;
          _this2.dealformPass.opinion = res.data[0].content;
          _this2.dealformPass.secretaryName = res.data[0].allReviews[0].name;
          _this2.dealformPass.secretaryId = res.data[0].allReviews[0].id;
          _this2.dealFormShow2 = true;
          _this2.secretOpinionLoading = false;
        } else {
          _this2.dealFormShow2 = false;
          _this2.secretOpinionLoading = false;
        }
      }).catch(function (error) {
        _this2.dealFormShow2 = false;
        _this2.secretOpinionLoading = false;
      });
    },
    getSecretaryListInfo: function getSecretaryListInfo() {
      var _this3 = this;

      (0, _inquiry.getSecretaryList)({
        id: this.$route.params.caseId
      }).then(function (res) {
        if (res.code == 1) {
          _this3.secretaryList = res.data[0].allReviews;
          _this3.dealform.opinion = res.data[0].content;
          _this3.dealform.secretaryName = res.data[0].allReviews[0].name;
          _this3.dealform.secretaryId = res.data[0].allReviews[0].id;
        } else {
          _this3.$message({
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(function (error) {});
    },
    // 右侧菜单栏点击
    anchorTo: function anchorTo(data) {
      document.getElementById(data).scrollIntoView({
        block: "center"
      });
      this.clicked = data;
    },
    //查看当事人信息
    getPartyInfo: function getPartyInfo(data) {
      var _this4 = this;

      this.partyInfoVisible = true;
      this.$nextTick(function () {
        _this4.$refs.partyInfo.init(data);
      });
    },
    // 申请人材料作废
    handleAppMaterialInvalid: function handleAppMaterialInvalid(data) {
      this.cancelform.id = data;
      this.cancelFormShow = true;
    },
    // 提交作废原因
    handleInvalid: function handleInvalid(cancelform) {
      var _this5 = this;

      this.$refs[cancelform].validate(function (valid) {
        if (valid) {
          (0, _inquiry.auditProof)(_this5.cancelform).then(function (res) {
            _this5.cancelFormShow = false;

            _this5.getMaterial({
              type: 1,
              caseId: _this5.casedetailid,
              limit: 5,
              page: 0
            });
          }).catch(function (error) {});
        } else {
          return false;
        }
      });
    },
    // 审核&下一步
    handleFilingReview: function handleFilingReview() {
      this.secretOpinionLoading = true;
      this.getSecretaryList();
    },
    // 确认仲裁费
    sendFilingReview: function sendFilingReview() {
      var _this6 = this;

      (0, _inquiry.getReasonList)().then(function (res) {
        _this6.reasonList = res.data;
      }).catch(function (error) {});
      this.sendCasePayLoading = true;
      (0, _inquiry.getCasePayInfo)(this.dealformPass).then(function (res) {
        _this6.loading = false;

        if (res.code == 1) {
          _this6.casePayFormShow = true;
          _this6.dealFormShow2 = false;
          _this6.FilingPassLoading = false;
          _this6.dealform.controversyAmount = res.data.controversyAmount;
          _this6.dealform.clFee = res.data.clFee;
          _this6.dealform.slFee = res.data.slFee;
          _this6.dealform.zcFee = res.data.zcFee;
          _this6.dealform.href = res.data.href;
          _this6.dealform.fileName = res.data.fileName;

          if (res.data.reasonId) {
            _this6.dealform.reasonId = res.data.reasonId;
          }

          if (res.data.reasonName) {
            _this6.dealform.reasonName = res.data.reasonName;
          }

          _this6.getSecretaryListInfo();

          _this6.dealform.caseNo = res.data.caseNo;
        } else {
          _this6.FilingPassLoading = false;

          _this6.$message.error(res.msg);
        }

        _this6.sendCasePayLoading = false;
      });
    },
    handleIsPayCaseAmount: function handleIsPayCaseAmount() {
      var _this7 = this;

      (0, _inquiry.getReasonList)().then(function (res) {
        _this7.reasonList = res.data;
      }).catch(function (error) {});
      (0, _inquiry.selectRegisterIsPayInfo)({
        caseId: this.$route.params.caseId
      }).then(function (res) {
        _this7.loading = false;

        if (res.code == 1) {
          _this7.dealFormShow = true;
          _this7.dealFormShow2 = false;
          _this7.FilingPassLoading = false;
          _this7.dealform.caseAmount = res.data.caseAmount;
          _this7.dealform.payTime = res.data.payTime;
          _this7.dealform.remarks = res.data.remarks;
          _this7.dealform.amount = res.data.amount;
          _this7.dealform.href = res.data.href;

          if (res.data.reasonId) {
            _this7.dealform.reasonId = res.data.reasonId;
          }

          if (res.data.reasonName) {
            _this7.dealform.reasonName = res.data.reasonName;
          }

          _this7.getSecretaryListInfo();

          _this7.dealform.caseNo = res.data.caseNo;
        } else {
          _this7.FilingPassLoading = false;

          _this7.$message.error(res.msg);
        }
      });
    },
    // 审核通过&发送
    handleFilingFinalPass: function handleFilingFinalPass() {
      var _this8 = this;

      this.finalPassLoading = true;
      this.$nextTick(function () {
        _this8.$refs.sendCase.init(_this8.$route.params.caseId, _this8.detailData.state, _this8.$route.params.typeDetail ? _this8.$route.params.typeDetail : 'register');
      });
    },
    showRegisterFiles: function showRegisterFiles() {
      var _this9 = this;

      this.showRegisterLoading = true;
      this.$nextTick(function () {
        _this9.$refs.sendCase.init(_this9.$route.params.caseId, _this9.detailData.state, _this9.$route.params.typeDetail ? _this9.$route.params.typeDetail : 'register');
      });
    },
    // 通过审核
    handleFilingPass: function handleFilingPass(dealformPass) {
      var _this10 = this;

      // this.dealform2.secretaryName = $("#selectSec option:selected").val();
      this.$refs[dealformPass].validate(function (valid) {
        if (valid) {
          _this10.FilingPassLoading = true;

          _this10.secretaryList.forEach(function (item) {
            if (item.id == _this10.dealformPass.secretaryId) {
              _this10.dealformPass.secretaryName = item.name;
            }
          });

          _this10.loading = true;
          (0, _inquiry.procedureByAdopt)({
            caseId: _this10.$route.params.caseId,
            node: _this10.detailData.node,
            reviewId: _this10.dealformPass.secretaryId,
            reviewName: _this10.dealformPass.secretaryName
          }).then(function (res) {
            if (res.code == 1) {
              _this10.$router.push({
                name: _this10.$route.params.typeDetail ? _this10.$route.params.typeDetail : 'register'
              });
            }
          })
          /*filingPass(this.dealformPass).then(res => {
            this.loading = false;
            if (res.code == 1) {
              this.dealFormShow = true;
              this.dealFormShow2 = false;
              this.FilingPassLoading = false;
              this.dealform.caseNo = res.data.caseNo;
              this.dealform.controversyAmount = res.data.controversyAmount;
              this.dealform.clFee = res.data.clFee;
              this.dealform.slFee = res.data.slFee;
              this.dealform.zcFee = res.data.zcFee;
              getReasonList().then(res => {
                this.reasonList = res.data;
                this.dealform.reasonId = this.reasonList[0].id;
              }).catch(error => {
              })
            } else {
              this.FilingPassLoading = false;
              this.$message.error(res.msg);
            }
          })*/
          .catch(function (error) {
            _this10.FilingPassLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    // 补充材料
    handleFilingAddMaterial: function handleFilingAddMaterial(dealformMaterial) {
      var _this11 = this;

      this.$refs[dealformMaterial].validate(function (valid) {
        if (valid) {
          _this11.FilingPassLoading = true;
          (0, _inquiry.filingSupplyProof)(_this11.dealformMaterial).then(function (res) {
            _this11.loading = false;

            if (res.code == 1) {
              _this11.dealFormShow2 = false;
              _this11.FilingPassLoading = false;

              _this11.$message({
                message: '保存成功',
                type: 'success'
              });

              _this11.$router.push({
                name: _this11.$route.params.typeDetail ? _this11.$route.params.typeDetail : 'register'
              });
            } else {
              _this11.FilingPassLoading = false;

              _this11.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this11.FilingPassLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    // 不予受理
    handleFilingRefuse: function handleFilingRefuse(dealformRefuse) {
      var _this12 = this;

      this.$refs[dealformRefuse].validate(function (valid) {
        if (valid) {
          _this12.FilingPassLoading = true;
          (0, _inquiry.filingRefuse)(_this12.dealformRefuse).then(function (res) {
            _this12.loading = false;

            if (res.code == 1) {
              _this12.dealFormShow2 = false;
              _this12.FilingPassLoading = false;

              _this12.$message({
                message: '保存成功',
                type: 'success'
              });

              _this12.$router.push({
                name: _this12.$route.params.typeDetail ? _this12.$route.params.typeDetail : 'register'
              });
            } else {
              _this12.FilingPassLoading = false;

              _this12.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this12.FilingPassLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    updateShow: function updateShow() {
      this.dealFormShowLoading = false;
      this.sendCaseVisible = true;
      this.dealFormShow = false;
      this.showRegisterLoading = false;
    },
    updateErrorShow: function updateErrorShow() {
      this.dealFormShowLoading = false;
      this.sendCaseVisible = false;
    },
    closePassLoading: function closePassLoading() {
      this.finalPassLoading = false;
    },
    confimPayToCaseShow: function confimPayToCaseShow(dealForm) {
      var _this13 = this;

      this.$refs[dealForm].validate(function (valid) {
        if (valid) {
          _this13.secretaryList.forEach(function (item) {
            if (item.id == _this13.dealform.secretaryId) {
              _this13.dealform.secretaryName = item.name;
            }
          });

          _this13.dealFormShowLoading = true;
          var deafFormParam = {
            caseId: _this13.$route.params.caseId,
            reasonId: _this13.dealform.reasonId,
            caseNo: _this13.dealform.caseNo,
            reviewId: _this13.dealform.secretaryId,
            reviewName: _this13.dealform.secretaryName,
            opinion: _this13.dealform.opinion
          };

          _this13.reasonList.forEach(function (item) {
            if (item.id == _this13.dealform.reasonId) {
              deafFormParam.arbitrationReason = item.reasonName;
            }
          });

          (0, _inquiry.caseAccept)(deafFormParam).then(function (res) {
            if (res.code == 1) {
              //进入送达确认页面
              _this13.$router.push({
                name: _this13.$route.params.typeDetail ? _this13.$route.params.typeDetail : 'register'
              });
            } else {
              _this13.dealFormShowLoading = false;

              _this13.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this13.dealFormShowLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    // 案件处理弹框保存跳转下一个弹框
    nextTodealFormShow2: function nextTodealFormShow2(dealForm) {
      var _this14 = this;

      this.$refs[dealForm].validate(function (valid) {
        if (valid) {
          _this14.secretaryList.forEach(function (item) {
            if (item.id == _this14.dealform.secretaryId) {
              _this14.dealform.secretaryName = item.name;
            }
          });

          _this14.dealFormShowLoading = true;

          if (_this14.dealform.zcFee == '') {
            _this14.$message.error('请填写仲裁费');

            _this14.dealFormShowLoading = false;
            return;
          }

          var deafFormParam = {
            caseId: _this14.$route.params.caseId,
            arbitrationAmount: _this14.dealform.zcFee,
            acceptFee: _this14.dealform.slFee,
            processingFee: _this14.dealform.clFee,
            reviewId: _this14.dealform.secretaryId,
            reviewName: _this14.dealform.secretaryName,
            caseNo: _this14.dealform.caseNo,
            opinion: _this14.dealform.opinion,
            reasonId: _this14.dealform.reasonId
          };

          _this14.reasonList.forEach(function (item) {
            if (item.id == _this14.dealform.reasonId) {
              deafFormParam.arbitrationReason = item.reasonName;
            }
          });

          (0, _inquiry.sendRegisterPayBook)(deafFormParam).then(function (res) {
            if (res.code == 1) {
              //进入送达确认页面
              _this14.$router.push({
                name: _this14.$route.params.typeDetail ? _this14.$route.params.typeDetail : 'casePay'
              });
            } else {
              _this14.dealFormShowLoading = false;

              _this14.$message.error(res.msg);
            }
          }).catch(function (error) {
            _this14.dealFormShowLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    reCreatePayBook: function reCreatePayBook() {
      var _this15 = this;

      this.dealFormPayLoading = true;

      if (this.dealform.zcFee == '') {
        this.$message.error('请填写仲裁费');
        this.dealFormPayLoading = false;
        return;
      }

      var deafFormParam = {
        caseId: this.$route.params.caseId,
        arbitrationAmount: this.dealform.zcFee,
        acceptFee: this.dealform.slFee,
        processingFee: this.dealform.clFee,
        reasonId: this.dealform.reasonId,
        caseNo: this.dealform.caseNo
      };
      this.reasonList.forEach(function (item) {
        if (item.id == _this15.dealform.reasonId) {
          deafFormParam.arbitrationReason = item.reasonName;
        }
      });
      (0, _inquiry.reCreatePayBook)(deafFormParam).then(function (res) {
        if (res.code == 1) {
          _this15.dealFormPayLoading = false;
          _this15.dealform.href = res.data.aliyunOssHref;
          _this15.dealform.fileName = res.data.fileName;

          _this15.$message.success("生成完成！");
        } else {
          _this15.dealFormPayLoading = false;

          _this15.$message.error(res.msg);
        }
      }).catch(function (error) {
        _this15.dealFormPayLoading = false;
      });
    },
    //费用快算
    computePrice: function computePrice(data) {
      var _this16 = this;

      if (this.dealform.controversyAmount) {
        (0, _inquiry.computePrice)({
          controversyAmount: data
        }).then(function (res) {
          if (res.code == 1) {
            _this16.computePriceForm = res.data;
          } else {
            _this16.$message.error(res.msg);
          }
        }).catch(function (error) {});
      } else {
        this.$message.error('请填写争议金额');
      }
    },
    // 案件处理2弹框的秘书常用语状态改变
    chooseCommonLanguage: function chooseCommonLanguage(label) {
      this.dealform2.regionInput = label;
    },
    // 最后审核跳转到案件送达页面
    // gotoCaseService(){
    // this.dealFormShow2=false;
    // this.$router.push({name:'service'})
    // },
    //添加常用语  确定按钮
    addLanguage: function addLanguage() {
      this.languageinnerVisible = false;
      this.optionArr.push({
        label: this.newLanguage,
        value: this.newLanguage
      });
    },
    // 申请人案件材料
    handleappMaterialCurrentChange: function handleappMaterialCurrentChange(val) {
      this.getMaterial({
        type: 1,
        caseId: this.casedetailid,
        limit: 5,
        page: val
      });
    },
    handleappMaterialSizeChange: function handleappMaterialSizeChange() {},
    //不予受理
    confirmReject: function confirmReject() {
      this.$refs.rejectCase.onShow(this.casedetailid);
    },
    onrefresh: function onrefresh() {
      this.dealFormShow = false;
      this.$router.go(0);
    }
  }
};
exports.default = _default;