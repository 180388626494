import { render, staticRenderFns } from "./comp-info-input.vue?vue&type=template&id=de3378ca&scoped=true&"
import script from "./comp-info-input.vue?vue&type=script&lang=js&"
export * from "./comp-info-input.vue?vue&type=script&lang=js&"
import style0 from "./comp-info-input.vue?vue&type=style&index=0&id=de3378ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de3378ca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/work/project/aihuz/arbitration-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('de3378ca')) {
      api.createRecord('de3378ca', component.options)
    } else {
      api.reload('de3378ca', component.options)
    }
    module.hot.accept("./comp-info-input.vue?vue&type=template&id=de3378ca&scoped=true&", function () {
      api.rerender('de3378ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/trial/videoTrial/demo/components/comp-info-input.vue"
export default component.exports