"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.arbitratorRuleRefuse = arbitratorRuleRefuse;
exports.batchExamineAudit = batchExamineAudit;
exports.batchExamineAuditExt = batchExamineAuditExt;
exports.casesProcessAdopt = casesProcessAdopt;
exports.createRuleFile1 = createRuleFile1;
exports.findAdminInfo = findAdminInfo;
exports.findCaseRule = findCaseRule;
exports.getArbitratorAvoidList = getArbitratorAvoidList;
exports.getAwardDetail = getAwardDetail;
exports.saveCaseRule = saveCaseRule;
exports.saveImgAndNotice = saveImgAndNotice;
exports.saveRuleText = saveRuleText;
exports.sumbitAvoidReason = sumbitAvoidReason;
exports.uploadFile = uploadFile;

var _request = _interopRequireDefault(require("@/utils/request"));

function batchExamineAuditExt(data) {
  return (0, _request.default)({
    url: '/batchCase/batchExamine/audit',
    method: 'post',
    data: data
  });
}

function batchExamineAudit(data) {
  return (0, _request.default)({
    url: '/batchExamine/audit',
    method: 'post',
    data: data
  });
} // 查询仲裁员信息


function findAdminInfo() {
  return (0, _request.default)({
    url: '/back/admin/findAdminInfo',
    method: 'get'
  });
} // 保存仲裁员信息


function saveImgAndNotice(data) {
  return (0, _request.default)({
    url: '/back/admin/saveImgAndNotice',
    method: 'post',
    data: data
  });
} //文件上传


function uploadFile() {
  return '/back/file/import/uploadFile';
} // 查询裁决书内容


function findCaseRule(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchRule/findCaseRule/' + params,
    method: 'get'
  });
} // 查询裁决书内容详情


function getAwardDetail(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchRule/getAwardDetail/' + params,
    method: 'get'
  });
} // 仲裁员裁决拒绝


function arbitratorRuleRefuse(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/arbitratorRuleRefuse',
    method: 'post',
    data: data
  });
} // 保存修改后的裁决书


function saveRuleText(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchRule/saveRuleText',
    method: 'post',
    data: data
  });
} // 保存修改后的裁决书-更新


function saveCaseRule(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchRule/saveCaseRule',
    method: 'post',
    data: data
  });
} // 裁决通过


function casesProcessAdopt(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/casesProcessAdopt',
    method: 'post',
    data: data
  });
} // 仲裁员回避列表数据


function getArbitratorAvoidList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findAvoidApplyList',
    method: 'post',
    data: data
  });
} // 仲裁员回避理由


function sumbitAvoidReason(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/avoidApply',
    method: 'post',
    data: data
  });
}

function createRuleFile1(params) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/createRuledFile/' + params,
    method: 'get'
  });
}