"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _configure = require("@/api/configure.js");

var _dictionary = require("@/config/dictionary");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'IPManagement',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      statevalue: '',
      //状态值
      stateArr: [{
        //状态数组
        value: '1',
        label: '已启用'
      }, {
        value: '0',
        label: '已禁用'
      }],
      tableData: [{
        name: '123',
        type: '黑名单',
        content: "来"
      }],
      currentPage: 1
    };
  },
  created: function created() {
    this.loading = false; // this.init();
  },
  methods: {
    // 初始化
    init: function init() {
      var _this = this;

      (0, _configure.getcaseSendTemplateList)({
        limit: 10,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
        }
      });
    },
    //筛选
    screen: function screen() {
      var _this2 = this;

      this.total = 1;

      if (this.sendval || this.statevalue || this.committeeValue || this.temlval) {
        (0, _configure.getcaseSendTemplateList)({
          type: this.temlval,
          commId: this.committeeValue,
          status: this.statevalue,
          sendType: this.sendval,
          limit: 10,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
            _this2.loading = false;
          }
        });
      }
    },
    //点击 清空所有筛选条件
    clearAllConditon: function clearAllConditon() {
      this.statevalue = '';
      this.sendval = "";
      this.committeeValue = "";
      this.temlval = "";
      this.init();
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {},
    handleCurrentChange: function handleCurrentChange(val) {
      var _this3 = this;

      if (this.sendval || this.statevalue || this.committeeValue || this.temlval) {
        (0, _configure.getcaseSendTemplateList)({
          type: this.temlval,
          commId: this.committeeValue,
          status: this.statevalue,
          sendType: this.sendval,
          limit: 10,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this3.tableData = res.rows;
            _this3.total = res.total;
            _this3.loading = false;
            _this3.currentPage = val;
          }
        });
      } else {
        (0, _configure.getcaseSendTemplateList)({
          limit: 10,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this3.tableData = res.rows;
            _this3.total = res.total;
            _this3.loading = false;
            _this3.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;