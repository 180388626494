"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _iconify = _interopRequireDefault(require("@iconify/iconify"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    dataIcon: {
      default: ""
    },
    dataContent: {
      //用户id
      default: ""
    },
    dataColor: {
      default: "#fff"
    },
    //medium / small / mini
    dataSize: {
      default: "medium"
    }
  },
  data: function data() {
    return {
      fontSize: "24px"
    };
  },
  methods: {
    handleClick: function handleClick(type, action) {
      this.$emit("handle-click", type, action);
    }
  },
  mounted: function mounted() {
    if (this.dataSize == "medium") {
      this.fontSize = "24px";
    } else if (this.dataSize == "small") {
      this.fontSize = "16px";
    }
  }
};
exports.default = _default;