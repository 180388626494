"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _defineProperty2 = _interopRequireDefault(require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

var _index2 = _interopRequireDefault(require("@/components/editor/index"));

var _inquiry = require("@/api/inquiry.js");

var _dictionary = require("@/config/dictionary");

var _send = _interopRequireDefault(require("../service/send"));

var _auth = require("@/utils/auth");

var _security = require("@/utils/security");

var _encryptKey = require("@/config/encryptKey");

var _index3 = require("@/utils/index.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'judge',
  data: function data() {
    var _ref;

    return _ref = {
      imageUrl: '',
      loading: true,
      tableData: [],
      total: 0,
      //总页数
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      conditionData: '',
      //来自子组件的数据
      caseState: _dictionary.dictionary.caseState,
      multipleSelection: [],
      multipleSelectionId: [],
      judgeVisible: false,
      //案件组庭弹窗显示
      judgeForm: {
        //案件组庭数据
        region1: "",
        region2: ''
      },
      judgeRules: {
        region1: [{
          required: true,
          message: '请选择开庭时间',
          trigger: 'change'
        }],
        region2: [{
          required: true,
          message: '请选择开庭地址',
          trigger: 'change'
        }]
      },
      judgeInnerVisible: false,
      //添加开庭地址弹窗显示
      caeAddress: '',
      //添加开庭地址数据
      editVisible: false,
      //编辑裁决书弹窗显示
      article: {
        //编辑器的内容
        content: ''
      },
      closeCaseVisible: false,
      //结案审核弹窗显示
      uploadVisible: false,
      sendCaseVisible: false,
      //案件送达
      ruleFile: {},
      uploadRule: {},
      token: (0, _auth.getToken)(),
      uploadApi: '',
      holdcourtVisible: false,
      //案件组庭弹窗显示
      holdcourtForm: {
        //案件组庭数据
        caseId: '',
        courtAddress: "",
        courtTime: '',
        date: '',
        moment: ''
      },
      holdcourtRules: {
        courtTime: [{
          required: true,
          message: '请选择开庭时间',
          trigger: 'change'
        }],
        courtAddress: [{
          required: true,
          message: '请选择开庭地址',
          trigger: 'change'
        }]
      },
      holdcourtInnerVisible: false
    }, (0, _defineProperty2.default)(_ref, "caeAddress", ''), (0, _defineProperty2.default)(_ref, "courtList", []), (0, _defineProperty2.default)(_ref, "addAddressForm", {
      name: '',
      address: '',
      tel: ''
    }), (0, _defineProperty2.default)(_ref, "addAddressRules", {
      name: [{
        required: true,
        message: '请填写开庭名称',
        trigger: 'blur'
      }, {
        min: 1,
        max: 20,
        message: '开庭名称长度1-20位数',
        trigger: 'blur'
      }],
      address: [{
        required: true,
        message: '请填写开庭地址',
        trigger: 'blur'
      }, {
        min: 1,
        max: 20,
        message: '开庭地址长度1-255位数',
        trigger: 'blur'
      }],
      tel: [{
        required: true,
        message: '请填写联系方式',
        trigger: 'blur'
      }]
    }), (0, _defineProperty2.default)(_ref, "pickerOptions", {
      disabledDate: function disabledDate(time) {// return time.getTime() < Date.now();
      }
    }), (0, _defineProperty2.default)(_ref, "batchUploadVisible", false), (0, _defineProperty2.default)(_ref, "batchUploadApi", ''), (0, _defineProperty2.default)(_ref, "gotoServicing", false), (0, _defineProperty2.default)(_ref, "rejectVisible", false), (0, _defineProperty2.default)(_ref, "opinionform", {
      //拒绝表单
      txt: ''
    }), _ref;
  },
  created: function created() {
    this.init();
  },
  mounted: function mounted() {},
  components: {
    Caseconditons: _index.default,
    Editor: _index2.default,
    SendCase: _send.default
  },
  methods: {
    init: function init() {
      var _this = this;

      (0, _inquiry.getList)({
        state: '41',
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this2 = this;

      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1,
        state: '41'
      };
      this.conditionData = postData;
      (0, _inquiry.getList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //裁决书审核
    handleReview: function handleReview(data) {
      var _this3 = this;

      this.caseId = data.caseId;
      this.loading = true;
      (0, _inquiry.createRuledFile)({
        caseId: data.caseId
      }).then(function (res) {
        _this3.loading = false;

        if (res.code == 1) {
          _this3.ruleFile = res.data;
          _this3.closeCaseVisible = true;
        } else {
          _this3.$message.error(res.msg);
        }
      });
    },
    //拒绝表单
    confirmReject: function confirmReject(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _inquiry.procedureByRefuse)({
            caseId: _this4.caseId,
            content: _this4.opinionform.txt
          }).then(function (res) {
            if (res.code == 1) {
              _this4.$refs[formName].resetFields();

              _this4.rejectVisible = false;
              _this4.closeCaseVisible = false;

              _this4.refreshList();
            }
          });
        } else {
          return false;
        }
      });
    },
    updateShow: function updateShow() {
      this.holdcourtVisible = false;
      this.closeCaseVisible = false;
      this.editVisible = false;
      this.uploadVisible = false;
      this.sendCaseVisible = true;
      this.gotoServicing = false;
    },
    updateErrorShow: function updateErrorShow() {
      this.sendCaseVisible = false;
      this.gotoServicing = false;
    },
    // 审核通过后刷新数据
    refreshPage: function refreshPage() {
      this.sendCaseVisible = false;
      this.init();
    },
    // 裁决审核
    gotoService: function gotoService() {
      var _this5 = this;

      this.gotoServicing = true;
      this.sendCaseVisible = true;
      this.$nextTick(function () {
        _this5.$refs.sendCase.init(_this5.caseId, 41);
      });
    },
    // 上传裁决书
    handleUpload: function handleUpload(data) {
      var _this6 = this;

      this.caseId = data.caseId;
      this.uploadVisible = true;
      this.uploadApi = process.env.VUE_APP_BASE_API + (0, _inquiry.uploadRuleFile)();
      (0, _inquiry.getRuleFile)(data.caseId).then(function (res) {
        if (res.code == 1 && res.data) {
          _this6.uploadRule = res.data;
        }
      });
    },
    handleAvatarSuccess: function handleAvatarSuccess(res1, file) {
      var res = JSON.parse((0, _security.decryptByAES)(res1.response, _encryptKey.encryptKey.key));

      if (res.code == 1) {
        this.uploadRule = res.data;
      } else {
        location.reload();
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isJPG = file.type === 'application/pdf';
      var isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error('上传附件支持pdf格式!');
      }

      if (!isLt2M) {
        this.$message.error('上传附件大小不能超过10MB!');
      }

      return isJPG && isLt2M;
    },
    // 上传裁决书提交
    handleUploadSubmit: function handleUploadSubmit() {
      var _this7 = this;

      if (this.uploadRule) {
        var data = {
          caseId: this.caseId,
          filePath: this.uploadRule.filePath
        };
        this.gotoServicing = true;
        (0, _inquiry.saveRuleFile)(data).then(function (res) {
          if (res.code == 1) {
            //进入送达确认页面
            _this7.$nextTick(function () {
              _this7.$refs.sendCase.init(_this7.caseId, 41);
            });
          } else {
            _this7.gotoServicing = false;

            _this7.$message.error(res.msg);
          }
        }).catch(function (err) {
          _this7.gotoServicing = false;
        });
      } else {
        this.$message.error('请先上传裁决书');
      }
    },
    //仲裁庭列表
    getCourtList: function getCourtList() {
      var _this8 = this;

      (0, _inquiry.getCourtList)().then(function (res) {
        if (res.code == 1) {
          _this8.courtList = res.data;
        }
      });
    },
    //再次开庭
    handleAgainCourt: function handleAgainCourt(data) {
      this.caseId = data.caseId;
      this.holdcourtVisible = true;
      this.getCourtList();
    },
    //添加开庭地址
    handleAddAddress: function handleAddAddress(addAddressForm) {
      var _this9 = this;

      this.$refs[addAddressForm].validate(function (valid) {
        if (valid) {
          (0, _inquiry.insertCourtInfo)(_this9.addAddressForm).then(function (res) {
            if (res.code == 1) {
              _this9.$message({
                message: '添加开庭地址成功',
                type: 'success'
              });

              _this9.holdcourtInnerVisible = false;

              _this9.getCourtList();
            } else {
              _this9.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    //再次开庭提交
    handleAgainCourtSubmit: function handleAgainCourtSubmit(divisionForm) {
      var _this10 = this;

      this.$refs[divisionForm].validate(function (valid) {
        if (valid) {
          _this10.gotoServicing = true;
          _this10.holdcourtForm.caseId = _this10.caseId;

          if (_this10.holdcourtForm.date == '') {
            _this10.$message.error('请选择开庭日期');
          } else if (_this10.holdcourtForm.moment == '') {
            _this10.$message.error('请选择开庭时间');
          } else {
            _this10.holdcourtForm.courtTime = _this10.holdcourtForm.date + " " + _this10.holdcourtForm.moment; // this.loading = true;

            (0, _inquiry.choiceCourtAddress)(_this10.holdcourtForm).then(function (res) {
              _this10.loading = false;

              if (res.code == 1) {
                //进入送达确认页面
                _this10.sendCaseVisible = true;

                _this10.$nextTick(function () {
                  _this10.$refs.sendCase.init(_this10.caseId, 3);
                });
              } else {
                _this10.gotoServicing = false;

                _this10.$message.error(res.msg);
              }
            }).catch(function (error) {
              _this10.gotoServicing = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    //批量审核
    handleBatchReview: function handleBatchReview() {
      var _this11 = this;

      // this.multipleSelection.forEach(item => {
      //     this.multipleSelectionId.push(item.caseId);
      // })
      // this.loading = true;
      //   batchSendRule({caseIds: this.multipleSelectionId}).then((res)=>{
      //       this.loading = false;
      //       if(res.code == 1){
      //           this.$message({    message: '批量审核成功',    type: 'success'});
      //           this.init();
      //       }else {
      //           this.$message.error(res.msg);
      //       }
      //   })
      this.$confirm('确定批量审核？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this11.multipleSelectionId = [];

        _this11.multipleSelection.forEach(function (item) {
          _this11.multipleSelectionId.push(item.caseId);
        });

        (0, _inquiry.batchProcedureByAdopt)({
          caseIds: _this11.multipleSelectionId
        }).then(function (res) {
          if (res.code == 1) {
            _this11.$message({
              message: '批量审核成功',
              type: 'success'
            });

            _this11.init();
          } else {
            _this11.$message.error(res.msg);
          }
        });
      });
    },
    //批量生成裁决书
    handleBatchCreateRule: function handleBatchCreateRule() {
      var _this12 = this;

      this.multipleSelection.forEach(function (item) {
        _this12.multipleSelectionId.push(item.caseId);
      });
      this.loading = true;
      (0, _inquiry.batchCreateRule)({
        caseIds: this.multipleSelectionId
      }).then(function (res) {
        _this12.loading = false;

        if (res.code == 1) {
          window.open(res.msg);
        } else {
          _this12.$message.error(res.msg);
        }
      });
    },
    //批量上传裁决书
    handleBatchUploadRule: function handleBatchUploadRule() {
      this.batchUploadVisible = true;
      this.batchUploadApi = process.env.VUE_APP_BASE_API + (0, _inquiry.batchUploadRule)();
    },
    handleBatchUploadSuccess: function handleBatchUploadSuccess(res1, file) {
      var res = JSON.parse((0, _security.decryptByAES)(res1.response, _encryptKey.encryptKey.key));

      if (res.code == 1) {
        this.batchUploadVisible = false;
        this.$message({
          message: '上传成功',
          type: 'success'
        });
        this.init();
      } else {
        location.reload();
      }
    },
    beforeBatchUpload: function beforeBatchUpload(file) {
      var isJPG = file.type === 'application/zip';
      var isLt2M = file.size / 1024 / 1024 < 100;

      if (!isJPG) {
        this.$message.error('上传附件支持zip格式!');
      }

      if (!isLt2M) {
        this.$message.error('上传附件大小不能超过100MB!');
      }

      return isJPG && isLt2M;
    },
    //编辑裁决书
    submitUpload: function submitUpload(e) {},
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this13 = this;

      this.pageLimit = val;

      if ((0, _index3.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this13.tableData = res.rows;
            _this13.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          state: '41',
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this13.tableData = res.rows;
            _this13.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this14 = this;

      if ((0, _index3.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this14.tableData = res.rows;
            _this14.total = res.total;
            _this14.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          state: '41',
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this14.tableData = res.rows;
            _this14.total = res.total;
            _this14.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;