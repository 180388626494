"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("core-js/modules/es6.number.constructor");

var _vueQuillEditor = require("vue-quill-editor");

require("quill/dist/quill.core.css");

require("quill/dist/quill.snow.css");

require("quill/dist/quill.bubble.css");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 工具栏配置
var toolbarOptions = [["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
["blockquote", "code-block"], // 引用  代码块
[{
  header: 1
}, {
  header: 2
}], // 1、2 级标题
[{
  list: "ordered"
}, {
  list: "bullet"
}], // 有序、无序列表
[{
  script: "sub"
}, {
  script: "super"
}], // 上标/下标
[{
  indent: "-1"
}, {
  indent: "+1"
}], // 缩进
// [{'direction': 'rtl'}],                         // 文本方向
[{
  size: ["small", false, "large", "huge"]
}], // 字体大小
[{
  header: [1, 2, 3, 4, 5, 6, false]
}], // 标题
[{
  color: []
}, {
  background: []
}], // 字体颜色、字体背景颜色
[{
  font: []
}], // 字体种类
[{
  align: []
}], // 对齐方式
["clean"], // 清除文本格式
["link", "image", "video"] // 链接、图片、视频
];
var _default = {
  props: {
    /*编辑器的内容*/
    value: {
      type: String
    },

    /*图片大小*/
    maxSize: {
      type: Number,
      default: 4000 //kb

    }
  },
  components: {
    quillEditor: _vueQuillEditor.quillEditor
  },
  data: function data() {
    var _editorOption;

    return {
      content: this.value,
      quillUpdateImg: false,
      // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
      editorOption: (_editorOption = {
        placeholder: "",
        theme: "snow"
      }, (0, _defineProperty2.default)(_editorOption, "placeholder", "编辑...."), (0, _defineProperty2.default)(_editorOption, "modules", {
        toolbar: {
          container: toolbarOptions,
          // container: "#toolbar",
          handlers: {
            image: function image(value) {
              if (value) {
                // 触发input框选择图片文件
                document.querySelector(".avatar-uploader input").click();
              } else {
                this.quill.format("image", false);
              }
            } // link: function(value) {
            //   if (value) {
            //     var href = prompt('请输入url');
            //     this.quill.format("link", href);
            //   } else {
            //     this.quill.format("link", false);
            //   }
            // },

          }
        }
      }), _editorOption),
      serverUrl: "",
      // 这里写你要上传的图片服务器地址
      header: {
        'Authorization': localStorage.token // token: sessionStorage.token

      } // 有的图片服务器要求请求头需要有token

    };
  },
  methods: {
    onEditorBlur: function onEditorBlur() {//失去焦点事件
    },
    onEditorFocus: function onEditorFocus() {//获得焦点事件
    },
    onEditorChange: function onEditorChange() {
      //内容改变事件
      this.$emit("input", this.content);
    },
    fromFatherVal: function fromFatherVal(val) {
      this.content = val;
    },
    // 富文本图片上传前
    beforeUpload: function beforeUpload() {
      // 显示loading动画
      this.quillUpdateImg = true;
    },
    uploadSuccess: function uploadSuccess(res, file) {
      // res为图片服务器返回的数据
      // 获取富文本组件实例
      var quill = this.$refs.myQuillEditor.quill; // 如果上传成功

      if (res.code == 200) {
        // 获取光标所在位置
        var length = quill.getSelection().index; // 插入图片  res.url为服务器返回的图片地址

        quill.insertEmbed(length, "image", res.url); // 调整光标到最后

        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      } // loading动画消失


      this.quillUpdateImg = false;
    },
    // 富文本图片上传失败
    uploadError: function uploadError() {
      // loading动画消失
      this.quillUpdateImg = false;
      this.$message.error("图片插入失败");
    }
  }
};
exports.default = _default;