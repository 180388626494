"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("echarts/map/js/china");

var _map = require("@/utils/map.js");

var _block = require("@/api/block");

var _settings = _interopRequireDefault(require("@/settings"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var convertData = function convertData(data) {
  var res = [];

  for (var i = 0; i < data.length; i++) {
    var geoCoord = _map.geoCoordMap[data[i].name];

    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value)
      });
    }
  }

  return res;
};

var currentFirst = convertData(_map.listData).splice(0, 1);
currentFirst[0].value[2] = 250;
var option = {
  backgroundColor: '#404a59',
  title: {
    left: 'center',
    textStyle: {
      color: '#fff'
    }
  },
  legend: {
    orient: 'vertical',
    y: 'bottom',
    x: 'right',
    data: ['pm2.5'],
    textStyle: {
      color: '#fff'
    }
  },
  geo: {
    map: 'china',
    label: {
      emphasis: {
        show: false
      }
    },
    roam: false,
    itemStyle: {
      normal: {
        areaColor: '#323c48',
        borderColor: '#111'
      },
      emphasis: {
        areaColor: '#2a333d'
      }
    }
  },
  series: [{
    name: '',
    type: 'scatter',
    coordinateSystem: 'geo',
    data: convertData(_map.listData),
    symbolSize: function symbolSize(val) {
      return val[2] / 10;
    },
    label: {
      normal: {
        formatter: '{b}',
        position: 'right',
        show: true
      }
    },
    itemStyle: {
      normal: {
        color: '#f4e925'
      }
    },
    zlevel: 1
  }, {
    name: '',
    type: 'effectScatter',
    coordinateSystem: 'geo',
    data: currentFirst,
    symbolSize: function symbolSize(val) {
      return val[2] / 10;
    },
    showEffectOn: 'render',
    rippleEffect: {
      brushType: 'fill',
      scale: 4
    },
    hoverAnimation: true,
    label: {
      normal: {
        formatter: '{b}',
        position: 'right',
        show: false
      }
    },
    itemStyle: {
      normal: {
        color: 'rgba(244,233,37,.5)',
        shadowBlur: 50,
        shadowColor: '#333'
      }
    },
    zlevel: 1
  }]
};
var _default = {
  data: function data() {
    return {
      value: '',
      list: '',
      index: 0,
      timer: null,
      clientWidth: document.documentElement.clientWidth,
      widthTimer: false
    };
  },
  created: function created() {
    var _this = this;

    document.title = _settings.default.title;
    (0, _block.blockLatsetData)().then(function (res) {
      _this.list = res.data;
    });
  },
  ready: function ready() {
    window.addEventListener('resize', this.handleResize);
  },
  mounted: function mounted() {
    var _this2 = this;

    if (this.timer) {
      clearInterval(this.timer);
    }

    var myChart = this.$echarts.init(document.getElementById('map'));
    myChart.setOption(option);
    this.setTime(myChart); // 获取屏幕宽度

    window.onresize = function () {
      return function () {
        window.screenWidth = document.body.clientWidth;
        _this2.clientWidth = window.screenWidth;
      }();
    };
  },
  watch: {
    clientWidth: function clientWidth(val) {
      if (!this.widthTimer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientWidth = val;
        this.widthTimer = true;
        var that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          that.widthTimer = false;
        }, 400);
      }
    }
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    if (this.timer) {
      clearInterval(this.timer);
    }

    next();
  },
  methods: {
    // 重建option
    creatOption: function creatOption(option, data) {
      var currentData = convertData(_map.listData).splice(this.index, 1);
      currentData[0].value[2] = 250;
      option.series[1].data = currentData;
      return option;
    },
    // 开启时间机器
    setTime: function setTime(myChart) {
      var _this3 = this;

      this.timer = setInterval(function () {
        _this3.index++;

        if (_this3.index >= _map.listData.length) {
          _this3.index = 0;
        }

        if (myChart) {
          myChart.setOption(_this3.creatOption(option, _map.listData));
        }
      }, 3000);
    },
    gotoDetail: function gotoDetail() {
      this.$router.push({
        path: '/chainDetail'
      });
    },
    gotoIndex: function gotoIndex() {
      this.$router.push({
        path: '/'
      });
    },
    handleResize: function handleResize(event) {
      this.clientWidth = document.documentElement.clientWidth;
    }
  }
};
exports.default = _default;