"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _encryptKey = require("@/config/encryptKey");

var _security = require("@/utils/security");

//
//
//
//
//
//
//
//
//
var CryptoJS = require("crypto-js");

var _default = {
  data: function data() {
    return {
      iptValue: "",
      resValue: ""
    };
  },
  methods: {
    onEncryption: function onEncryption() {
      var data = this.iptValue;
      var params = {
        request: (0, _security.encryptByAES)(data, _encryptKey.encryptKey.key)
      };
      this.resValue = params.request;
    },
    onDecrypt: function onDecrypt() {}
  }
};
exports.default = _default;