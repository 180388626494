"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _menu = require("@/api/menu");

var state = {};
var mutations = {};
var actions = {
  getMenuList: function getMenuList() {
    return new Promise(function (resolve, reject) {
      (0, _menu.getMenuList)().then(function (response) {
        var data = response.data;
        console.log(JSON.stringify(data));
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  addMenu: function addMenu(_ref, param) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      (0, _menu.addMenu)({
        parentId: param.parentId,
        name: param.text.trim(),
        url: param.url.trim(),
        method: param.method
      }).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  deleteMenu: function deleteMenu(_ref2, param) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      (0, _menu.deleteMenu)({
        id: param
      }).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  updateMenu: function updateMenu(_ref3, param) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      (0, _menu.updateMenu)({
        name: param.text.trim(),
        url: param.url.trim(),
        method: param.method,
        id: param.id,
        state: param.state,
        showState: param.showState,
        parentId: param.parentId,
        icon: param.icon
      }).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;