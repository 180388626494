"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _arbitrator = require("@/api/arbitrator.js");

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

var _dictionary = require("@/config/dictionary");

var _index2 = require("@/utils/index.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'case-avoid',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      tableData: [{
        source: '123',
        caseId: 123
      }],
      total: 0,
      //总页数
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      conditionData: '',
      //来自子组件的数据
      date: '',
      caseState: _dictionary.dictionary.caseState,
      avoidFormVisible: false,
      form: {
        reason: ''
      },
      currentCaseId: ''
    };
  },
  created: function created() {
    this.currentPage = 1;
    this.init();
  },
  mounted: function mounted() {},
  components: {
    Caseconditons: _index.default
  },
  methods: {
    // 初始化数据列表
    init: function init() {
      var _this = this;

      (0, _arbitrator.getArbitratorAvoidList)({
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    // 表格点击申请回避
    handleClick: function handleClick(val) {
      this.currentCaseId = val;
      this.avoidFormVisible = true;

      if (this.$refs['form']) {
        this.$refs['form'].resetFields();
      }
    },
    //确认回避
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          (0, _arbitrator.sumbitAvoidReason)({
            caseId: _this2.currentCaseId,
            type: 5,
            auditName: '仲裁员回避',
            auditContent: _this2.form.reason
          }).then(function (res) {
            if (res.code == 1) {
              _this2.avoidFormVisible = false;

              _this2.$message({
                message: '申请回避提交成功',
                type: 'success'
              });
            } else if (res.code == 0) {
              _this2.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this3 = this;

      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCourtTime: data.rowThirdDate[0],
        endCourtTime: data.rowThirdDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowSecondDate[0],
        endRegisterTime: data.rowSecondDate[1],
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _arbitrator.getArbitratorAvoidList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this3.tableData = res.rows;
          _this3.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this4 = this;

      this.pageLimit = val;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _arbitrator.getArbitratorAvoidList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this4.tableData = res.rows;
            _this4.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _arbitrator.getArbitratorAvoidList)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this4.tableData = res.rows;
            _this4.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this5 = this;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _arbitrator.getArbitratorAvoidList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this5.tableData = res.rows;
            _this5.total = res.total;
            _this5.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _arbitrator.getArbitratorAvoidList)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this5.tableData = res.rows;
            _this5.total = res.total;
            _this5.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;