var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "global-page-base-layout", attrs: { loading: _vm.loading } },
    [
      _c("Caseconditons", {
        attrs: { replyShow: 0 },
        on: { choose: _vm.checkList, cleanCondition: _vm.cleanConditionData },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "handing-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "tooltip-effect": "dark",
                "header-cell-style": { background: _vm.tableHeaderColor },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "案号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.caseNo
                          ? _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.caseNo))]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "link-style",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetail(scope.row.caseId)
                                  },
                                },
                              },
                              [_vm._v("暂无案号")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "caseId", label: "受理号" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerApplyTime", label: "申请日期" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "resName", label: "被申请人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationReason", label: "案由" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "controversyAmount", label: "争议金额(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "arbitrationAmount", label: "仲裁费(元)" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "审核拒绝" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.rejectAuditState[scope.row.rejectAudit]
                            ) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "state", label: "案件状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.caseState[scope.row.state]) +
                            "\n        "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "handingSecretaryName", label: "办案秘书" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "registerSendTime", label: "立案时间" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "medium" },
                            on: {
                              click: function ($event) {
                                return _vm.handleReview(scope.row)
                              },
                            },
                          },
                          [_vm._v("\n            [指定仲裁员]\n          ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": _vm.pageSizes,
                  "page-size": 10,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "指定仲裁员",
            visible: _vm.selectArbVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectArbVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "selectArbForm",
              attrs: {
                "label-position": "right",
                "label-width": "120px",
                model: _vm.selectArbForm,
                rules: _vm.selectArbRules,
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.backInfoMsg ? true : false,
                      expression: "backInfoMsg ? true : false",
                    },
                  ],
                },
                [
                  _c("span", { staticStyle: { color: "red" } }, [
                    _vm._v(_vm._s(_vm.backInfoMsg)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "申请人选定", prop: "applicant" } },
                [
                  _vm.partyChoiceArb.appArbitratorName
                    ? _c("span", { attrs: { underline: false } }, [
                        _vm._v(_vm._s(_vm.partyChoiceArb.appArbitratorName)),
                      ])
                    : _c("span", { attrs: { underline: false } }, [
                        _vm._v("未选定仲裁员"),
                      ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "被申请人选定", prop: "respondent" } },
                [
                  _vm.partyChoiceArb.resArbitratorName
                    ? _c("el-button", [
                        _vm._v(_vm._s(_vm.partyChoiceArb.resArbitratorName)),
                      ])
                    : _c("span", { attrs: { underline: false } }, [
                        _vm._v("未选定仲裁员"),
                      ]),
                ],
                1
              ),
              _vm._v(" "),
              _vm.selectArbForm.examArbName
                ? _c(
                    "el-form-item",
                    { attrs: { label: "推荐仲裁员", prop: "respondent" } },
                    [
                      _c("span", { staticStyle: { color: "#409EFF" } }, [
                        _vm._v(_vm._s(_vm.selectArbForm.examArbName)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "组庭类型", prop: "composeType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.selectArbForm.composeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.selectArbForm, "composeType", $$v)
                        },
                        expression: "selectArbForm.composeType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "独任庭", value: "1" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "合议庭", value: "2" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.selectArbForm.composeType === "1"
                ? _c(
                    "el-form-item",
                    {
                      attrs: { label: "独任仲裁员", prop: "chiefArbitratorId" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "80%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.selectArbForm.chiefArbitratorId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.selectArbForm,
                                "chiefArbitratorId",
                                $$v
                              )
                            },
                            expression: "selectArbForm.chiefArbitratorId",
                          },
                        },
                        _vm._l(_vm.arbitratorList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "首席仲裁员",
                            prop: "chiefArbitratorId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "80%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.selectArbForm.chiefArbitratorId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectArbForm,
                                    "chiefArbitratorId",
                                    $$v
                                  )
                                },
                                expression: "selectArbForm.chiefArbitratorId",
                              },
                            },
                            _vm._l(_vm.arbitratorList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "仲裁员1" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "80%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.selectArbForm.secondArbitratorId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectArbForm,
                                    "secondArbitratorId",
                                    $$v
                                  )
                                },
                                expression: "selectArbForm.secondArbitratorId",
                              },
                            },
                            _vm._l(_vm.arbitratorList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "仲裁员2" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "80%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.selectArbForm.thirdArbitratorId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.selectArbForm,
                                    "thirdArbitratorId",
                                    $$v
                                  )
                                },
                                expression: "selectArbForm.thirdArbitratorId",
                              },
                            },
                            _vm._l(_vm.arbitratorList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下一步操作人", prop: "secretaryId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "80%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.dealform.secretaryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.dealform, "secretaryId", $$v)
                        },
                        expression: "dealform.secretaryId",
                      },
                    },
                    _vm._l(_vm.secretaryList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "经办意见", prop: "opinion" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%" },
                    attrs: {
                      type: "textarea",
                      rows: "5",
                      placeholder: "请填写经办意见",
                      resize: "none",
                    },
                    model: {
                      value: _vm.dealform.opinion,
                      callback: function ($$v) {
                        _vm.$set(_vm.dealform, "opinion", $$v)
                      },
                      expression: "dealform.opinion",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.rejectData.length > 0 ? true : false,
                  expression: "rejectData.length>0?true:false",
                },
              ],
            },
            [
              _c("h4", [_vm._v("审批拒绝人员记录")]),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.rejectData, stripe: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "opinionName", label: "人员", width: "180" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "reason", label: "拒绝原因", width: "180" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "ctime", label: "拒绝时间" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.selectArbVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.gotoServicing },
                  on: {
                    click: function ($event) {
                      return _vm.gotoService("selectArbVisible")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }