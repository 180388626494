"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _dictionary = require("@/config/dictionary");

var _menu = require("@/api/menu");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "",
  props: {},
  data: function data() {
    return {
      filterText: '',
      editingItem: {
        name: '',
        label: '',
        url: '',
        method: '',
        orderline: '',
        state: true,
        showState: true,
        id: '',
        title: '',
        path: '',
        icon: ''
      },
      addingItem: {},
      methodData: _dictionary.dictionary.method,
      rules: {
        text: [{
          required: true,
          message: '请填写名称',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请填写名称',
          trigger: 'blur'
        }],
        title: [{
          required: true,
          message: '请填写标题',
          trigger: 'blur'
        }],
        url: [{
          required: false,
          message: '请填写菜单',
          trigger: 'blur'
        }],
        path: [{
          required: false,
          message: '请填写路径',
          trigger: 'blur'
        }],
        method: [{
          required: false,
          message: '请选择方法',
          trigger: 'change'
        }],
        orderline: [{
          required: true,
          message: '请填写排序',
          trigger: 'blur'
        }],
        state: [{
          required: false,
          message: '请选择状态',
          trigger: 'change'
        }],
        showState: [{
          required: false,
          message: '请选择是否显示',
          trigger: 'change'
        }]
      },
      loading: false,
      menuVisible: false,
      editFlag: true,
      data: []
    };
  },
  watch: {
    filterText: function filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      this.loading = true;
      this.$store.dispatch('menu/getMenuList').then(function (res) {
        _this.data = res; // localStorage.setItem('menuList', JSON.stringify(res));

        _this.loading = false;
      }).catch(function () {
        _this.loading = false;
      });
    },
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleNodeClick: function handleNodeClick(data, node, vuecomponent) {
      this.menuVisible = true;
      this.editFlag = true;
      this.editingItem = data;
      this.editingItem.state == '1' ? this.editingItem.state = true : this.editingItem.state = false;
      this.editingItem.showState == '1' ? this.editingItem.showState = true : this.editingItem.showState = false;
    },
    append: function append(node, data) {
      var _this2 = this;

      this.menuVisible = true;
      this.editFlag = false; // this.editingItem = {}; //排序字段渲染有问题-不及时

      this.editingItem = {
        name: '',
        label: '',
        url: '',
        method: '',
        orderline: '',
        state: true,
        showState: true,
        id: '',
        title: '',
        path: '',
        icon: ''
      }, this.addingItem.parentId = data.id;
      (0, _menu.defaultOrderLine)(data.id).then(function (res) {
        if (res.code == 1) {
          _this2.editingItem.orderline = res.data;
        } else {
          _this2.$message.error(res.msg);
        }
      });
    },
    remove: function remove(node, data) {
      var _this3 = this;

      this.$confirm('确认删除此菜单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this3.loading = true;
        (0, _menu.deleteMenu)(data.id).then(function (res) {
          _this3.loading = false;

          if (res.code == 1) {
            _this3.loading = false;

            _this3.$message({
              message: '删除成功',
              type: 'success'
            });

            _this3.init();
          } else {
            _this3.$message.error(res.msg);
          }
        }).catch(function () {
          _this3.loading = false;
        });
      }).catch(function () {});
    },
    handleAddMenu: function handleAddMenu(formName) {
      var _this4 = this;

      this.editingItem.parentId = this.addingItem.parentId;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          (0, _menu.addMenu)(_this4.editingItem).then(function (res) {
            _this4.loading = false;

            if (res.code == 1) {
              _this4.$message({
                message: '新增成功',
                type: 'success'
              });

              _this4.init();
            } else {
              _this4.$message.error(res.msg);
            }
          }).catch(function () {
            _this4.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    handleUpdateMenu: function handleUpdateMenu(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.loading = true;
          (0, _menu.updateMenu)(_this5.editingItem).then(function (res) {
            _this5.loading = false;

            if (res.code == 1) {
              _this5.$message({
                message: '修改成功',
                type: 'success'
              });
            } else {
              _this5.$message.error(res.msg);
            }
          }).catch(function () {
            _this5.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default;