"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

var _configure = require("@/api/configure.js");

var _dictionary = require("@/config/dictionary");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'IPManagement',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      statevalue: '',
      //状态值
      stateArr: [{
        //状态数组
        value: '1',
        label: '已启用'
      }, {
        value: '0',
        label: '已禁用'
      }],
      committeeValue: '',
      //所属仲裁委值
      committeeArr: [],
      sendArr: [{
        label: '短信',
        value: '1'
      }, {
        label: '邮件',
        value: '2'
      }],
      //送达类型数组
      sendval: '',
      //送达类型值
      temlArr: [],
      //模板数组
      temlval: '',
      //模板值
      loading: true,
      tableData: [{
        name: '123',
        type: '黑名单',
        content: "来"
      }],
      currentPage: 1,
      caseSendType: _dictionary.dictionary.caseSendType,
      ipEditVisible: false,
      //文书弹窗显示
      title: '',
      //弹窗标题
      IPForm: {
        //模板数
        type: '',
        content: '',
        name: ''
      },
      IPFormRules: {
        name: [{
          required: true,
          message: '请输入'
        }],
        type: [{
          required: true,
          message: '请选择'
        }],
        content: [{
          required: true,
          message: '请输入'
        }]
      },
      currentId: '' //当前表格行id

    };
  },
  created: function created() {
    this.loading = false; // this.init();
  },
  methods: {
    // 初始化
    init: function init() {
      var _this = this;

      (0, _configure.getcaseSendTemplateList)({
        limit: 10,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
        }
      });
    },
    //重置列表
    resetTableList: function resetTableList() {
      var _this2 = this;

      if (this.sendval || this.statevalue || this.committeeValue || this.temlval) {
        (0, _configure.getcaseSendTemplateList)({
          type: this.temlval,
          commId: this.committeeValue,
          status: this.statevalue,
          sendType: this.sendval,
          limit: 10,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
            _this2.loading = false;
          }
        });
      } else {
        (0, _configure.getcaseSendTemplateList)({
          limit: 10,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
            _this2.loading = false;
          }
        });
      }
    },
    //添加文书
    gotoCreate: function gotoCreate() {
      this.ipEditVisible = true;
      this.title = "添加IP黑白名单";

      if (this.$refs['IPForm']) {
        this.$refs['IPForm'].resetFields();
      }
    },
    //筛选
    screen: function screen() {
      var _this3 = this;

      this.total = 1;

      if (this.sendval || this.statevalue || this.committeeValue || this.temlval) {
        (0, _configure.getcaseSendTemplateList)({
          type: this.temlval,
          commId: this.committeeValue,
          status: this.statevalue,
          sendType: this.sendval,
          limit: 10,
          page: this.currentPage
        }).then(function (res) {
          if (res.code == 1) {
            _this3.tableData = res.rows;
            _this3.total = res.total;
            _this3.loading = false;
          }
        });
      }
    },
    //点击 清空所有筛选条件
    clearAllConditon: function clearAllConditon() {
      this.statevalue = '';
      this.sendval = "";
      this.committeeValue = "";
      this.temlval = "";
      this.init();
    },
    //表格   删除
    deleteRow: function deleteRow(val) {
      var _this4 = this;

      this.$confirm('是否删除该送达模板？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        deleteCaseSendTemplate({
          id: val.id
        }).then(function (res) {
          if (res.code == 1) {
            _this4.$message({
              message: '删除成功',
              type: 'success'
            });

            _this4.resetTableList();
          }
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //表格  编辑
    edit: function edit(val) {
      var _this5 = this;

      this.currentId = val.id;
      this.ipEditVisible = true;
      this.title = '编辑IP黑白名单';

      if (this.$refs['IPForm']) {
        this.$refs['IPForm'].resetFields();
      }

      checkCaseSendTemplate(val.id).then(function (res) {
        var committeeItem = _this5.committeeArr.find(function (ele) {
          return ele.id == res.data.commId;
        });

        if (res.code == 1) {
          _this5.IPForm.name = res.data.templateName;
          _this5.IPForm.content = res.data.template;
          _this5.IPForm.type = _this5.caseSendType[res.data.type];
          _this5.IPForm.status = res.data.status == 1 ? "启用" : '禁用';
          _this5.IPForm.organ = committeeItem.name;
          _this5.IPForm.sendType = res.data.sendType == '1' ? '短信' : '邮件';
        }
      });
    },
    //人员修改/添加提交表单
    submitIPForm: function submitIPForm(formName) {
      var _this6 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var committeeItem = _this6.committeeArr.find(function (ele) {
            return ele.name == _this6.IPForm.organ;
          });

          var typeItem = _this6.temlArr.find(function (ele) {
            return ele.desc == _this6.IPForm.type;
          });

          if (_this6.title == '添加送达模板') {
            addCaseSendTemplate({
              templateName: _this6.IPForm.name,
              template: _this6.IPForm.content,
              type: typeItem.type,
              status: _this6.IPForm.status == "启用" ? '1' : '0',
              commId: committeeItem.id,
              commName: _this6.IPForm.organ,
              send_type: _this6.IPForm.sendType == '短信' ? '1' : '2'
            }).then(function (res) {
              if (res.code == 1) {
                _this6.$message({
                  message: '添加送达模板成功',
                  type: 'success'
                });

                _this6.ipEditVisible = false;

                _this6.$refs[formName].resetFields();

                _this6.resetTableList();
              }
            });
          } else {
            updateCaseSendTemplate({
              templateName: _this6.IPForm.name,
              template: _this6.IPForm.content,
              type: typeItem.type,
              status: _this6.IPForm.status == "启用" ? '1' : '0',
              commId: committeeItem.id,
              commName: _this6.IPForm.organ,
              send_type: _this6.IPForm.sendType == '短信' ? '1' : '2',
              id: _this6.currentId
            }).then(function (res) {
              _this6.$message({
                message: '修改送达模板成功',
                type: 'success'
              });

              _this6.ipEditVisible = false;

              _this6.$refs[formName].resetFields();

              _this6.resetTableList();
            });
          }
        } else {
          return false;
        }
      });
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {},
    handleCurrentChange: function handleCurrentChange(val) {
      var _this7 = this;

      if (this.sendval || this.statevalue || this.committeeValue || this.temlval) {
        (0, _configure.getcaseSendTemplateList)({
          type: this.temlval,
          commId: this.committeeValue,
          status: this.statevalue,
          sendType: this.sendval,
          limit: 10,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
            _this7.loading = false;
          }
        });
      } else {
        (0, _configure.getcaseSendTemplateList)({
          limit: 10,
          page: val
        }).then(function (res) {
          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
            _this7.loading = false;
          }
        });
      }
    }
  }
};
exports.default = _default;