import { render, staticRenderFns } from "./Notice.vue?vue&type=template&id=5d1f9160&scoped=true&"
import script from "./Notice.vue?vue&type=script&lang=js&"
export * from "./Notice.vue?vue&type=script&lang=js&"
import style0 from "./Notice.vue?vue&type=style&index=0&id=5d1f9160&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d1f9160",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/work/project/aihuz/arbitration-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d1f9160')) {
      api.createRecord('5d1f9160', component.options)
    } else {
      api.reload('5d1f9160', component.options)
    }
    module.hot.accept("./Notice.vue?vue&type=template&id=5d1f9160&scoped=true&", function () {
      api.rerender('5d1f9160', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/trial/videoTrial/components/Notice.vue"
export default component.exports