"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      radio: '申请人',
      tableData1: [{
        name: '已提交',
        value1: false
      }, {
        name: '已立案',
        value2: false
      }],
      editVisible: false,
      auditor: '',
      auditorList: [],
      fontTableData: [{
        fontName: '',
        fontType: '',
        radio: ''
      }]
    };
  },
  methods: {
    transferAuditorChange: function transferAuditorChange() {},
    edit: function edit() {
      this.editVisible = true;
    },
    sumbitTransferForm: function sumbitTransferForm() {
      this.editVisible = false;
    },
    //表格  删除
    deleteRow: function deleteRow(index, rows) {
      var _this = this;

      this.$confirm('是否删除该项？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        rows.splice(index, 1);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    addRow: function addRow() {
      this.tableData1.push({
        name: '已立案',
        value2: false
      });
    }
  }
};
exports.default = _default;