"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.controlAudioAll = controlAudioAll;
exports.controlFocusVideo = controlFocusVideo;
exports.controlVideoAudio = controlVideoAudio;
exports.endConference = endConference;
exports.getConferenceAddress = getConferenceAddress;
exports.getConferenceCaseDetail = getConferenceCaseDetail;
exports.getConferenceDetail = getConferenceDetail;
exports.getConferenceParty = getConferenceParty;
exports.getCourtSendList = getCourtSendList;
exports.getRecordContent = getRecordContent;
exports.getSeclList = getSeclList;
exports.getTrialList = getTrialList;
exports.inviteEquipment = inviteEquipment;
exports.saveRecordContent = saveRecordContent;
exports.setEquipmentFocus = setEquipmentFocus;

var _request = _interopRequireDefault(require("@/utils/request"));

//获取仲裁庭庭审列表
function getTrialList() {
  return (0, _request.default)({
    url: '/trialapi/trialConference/arbitrationConferences',
    method: 'post'
  });
} //获取庭审排期


function getSeclList(data) {
  return (0, _request.default)({
    url: '/trialapi/trialConferenceProgram/getProgramList',
    method: 'post',
    data: data
  });
} //获取视频开庭地址接口


function getCourtSendList() {
  return (0, _request.default)({
    url: '/trialapi/conferenceAisle/getAisleList',
    method: 'get'
  });
} // 获取视频会议和id


function getConferenceAddress(data) {
  return (0, _request.default)({
    url: '/trialapi/trialConference/startConference',
    method: 'post',
    data: data
  });
} //会议初始化的详情


function getConferenceDetail(param) {
  return (0, _request.default)({
    url: '/trialapi/trialConference/conference/' + param,
    method: 'get'
  });
} //获取会议的案件详情


function getConferenceCaseDetail(data) {
  return (0, _request.default)({
    url: '/trialapi/trialConference/conferenceCaseDetail',
    method: 'post',
    data: data
  });
} //获取会议参与人


function getConferenceParty(param) {
  return (0, _request.default)({
    url: '/trialapi/trialConferenceParty/party/' + param,
    method: 'get'
  });
} //控制音频视频


function controlVideoAudio(data) {
  return (0, _request.default)({
    url: '/trialapi/trialConference/control',
    method: 'post',
    data: data
  });
} //控制主屏幕


function controlFocusVideo(data) {
  return (0, _request.default)({
    url: '/trialapi/trialConference/focusVideo',
    method: 'post',
    data: data
  });
} //全局控制音频视频


function controlAudioAll(data) {
  return (0, _request.default)({
    url: '/trialapi/trialConference/voiceControlAll',
    method: 'post',
    data: data
  });
} //查询笔录


function getRecordContent(param) {
  return (0, _request.default)({
    url: '/trialapi/trialConferenceMark/findMark/' + param,
    method: 'get'
  });
} //保存笔录内容


function saveRecordContent(data) {
  return (0, _request.default)({
    url: '/trialapi/trialConferenceMark/saveMark',
    method: 'post',
    data: data
  });
} //结束会议


function endConference(params) {
  return (0, _request.default)({
    url: '/trialapi/trialConference/endConference/' + params,
    method: 'get'
  });
} //邀请入会


function inviteEquipment(params) {
  return (0, _request.default)({
    url: '/trialapi/trialConference/inviteEquipment/' + params,
    method: 'get'
  });
} //设备为主屏幕


function setEquipmentFocus(params) {
  return (0, _request.default)({
    url: '/trialapi/trialConference/setEquipmentFocus/' + params,
    method: 'get'
  });
}