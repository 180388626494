"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _role = require("@/api/role");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "",
  props: {},
  data: function data() {
    return {
      loading: false,
      visible: false,
      span: 18,
      dataForm: {
        name: '',
        type: '',
        menuIds: []
      },
      dataRule: {
        name: [{
          required: true,
          message: '名称不能为空',
          trigger: 'blur'
        }, {
          max: 30,
          message: '名称最大长度11位数',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }]
      },
      data: [],
      selectedId: [],
      filterText: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.init();
  },
  watch: {
    filterText: function filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    init: function init() {
      var _this = this;

      this.visible = true;
      (0, _role.inquiryRolePermission)().then(function (res) {
        _this.data = res.data.allMenu;
      }).catch(function (error) {});
    },
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    cancel: function cancel(dataForm) {
      this.$refs[dataForm].resetFields();
      this.visible = false;
    },
    handleAddRole: function handleAddRole() {
      var _this2 = this;

      this.dataForm.menuIds = this.$refs.tree.getCheckedKeys();
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          (0, _role.addRolePermission)(_this2.dataForm).then(function (res) {
            if (res.code === 1) {
              _this2.visible = false;

              _this2.$message({
                message: '添加成功',
                type: 'success',
                duration: 1500
              });

              _this2.$refs['dataForm'].resetFields();

              _this2.$emit('refreshDataList');
            } else {
              _this2.$message.error(res.msg);
            }
          }).catch(function (error) {});
        }
      });
    }
  }
};
exports.default = _default;