"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBrowser = getBrowser;
exports.getOS = getOS;

require("core-js/modules/es6.regexp.match");

function getBrowser() {
  var sys = {};
  var ua = navigator.userAgent.toLowerCase();
  var s;
  (s = ua.match(/edge\/([\d.]+)/)) ? sys.edge = s[1] : (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? sys.ie = s[1] : (s = ua.match(/msie ([\d.]+)/)) ? sys.ie = s[1] : (s = ua.match(/firefox\/([\d.]+)/)) ? sys.firefox = s[1] : (s = ua.match(/tbs\/([\d]+)/)) ? sys.tbs = s[1] : (s = ua.match(/xweb\/([\d]+)/)) ? sys.xweb = s[1] : (s = ua.match(/chrome\/([\d.]+)/)) ? sys.chrome = s[1] : (s = ua.match(/opera.([\d.]+)/)) ? sys.opera = s[1] : (s = ua.match(/version\/([\d.]+).*safari/)) ? sys.safari = s[1] : 0;
  if (sys.xweb) return {
    browser: "webView XWEB",
    version: ""
  };
  if (sys.tbs) return {
    browser: "webView TBS",
    version: ""
  };
  if (sys.edge) return {
    browser: "Edge",
    version: sys.edge
  };
  if (sys.ie) return {
    browser: "IE",
    version: sys.ie
  };
  if (sys.firefox) return {
    browser: "Firefox",
    version: sys.firefox
  };
  if (sys.chrome) return {
    browser: "Chrome",
    version: sys.chrome
  };
  if (sys.opera) return {
    browser: "Opera",
    version: sys.opera
  };
  if (sys.safari) return {
    browser: "Safari",
    version: sys.safari
  };
  return {
    browser: "",
    version: "0"
  };
}

var isMobile = {
  Android: function Android() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function BlackBerry() {
    return navigator.userAgent.match(/BlackBerry|BB10/i);
  },
  iOS: function iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function Opera() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function Windows() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function any() {
    return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
  },
  getOsName: function getOsName() {
    var osName = "Unknown OS";

    if (isMobile.Android()) {
      osName = "Android";
    }

    if (isMobile.BlackBerry()) {
      osName = "BlackBerry";
    }

    if (isMobile.iOS()) {
      osName = "iOS";
    }

    if (isMobile.Opera()) {
      osName = "Opera Mini";
    }

    if (isMobile.Windows()) {
      osName = "Windows";
    }

    return {
      osName: osName,
      type: "mobile"
    };
  }
};

function detectDesktopOS() {
  var unknown = "-";
  var nVer = navigator.appVersion;
  var nAgt = navigator.userAgent;
  var os = unknown;
  var clientStrings = [{
    s: "Chrome OS",
    r: /CrOS/
  }, {
    s: "Windows 10",
    r: /(Windows 10.0|Windows NT 10.0)/
  }, {
    s: "Windows 8.1",
    r: /(Windows 8.1|Windows NT 6.3)/
  }, {
    s: "Windows 8",
    r: /(Windows 8|Windows NT 6.2)/
  }, {
    s: "Windows 7",
    r: /(Windows 7|Windows NT 6.1)/
  }, {
    s: "Windows Vista",
    r: /Windows NT 6.0/
  }, {
    s: "Windows Server 2003",
    r: /Windows NT 5.2/
  }, {
    s: "Windows XP",
    r: /(Windows NT 5.1|Windows XP)/
  }, {
    s: "Windows 2000",
    r: /(Windows NT 5.0|Windows 2000)/
  }, {
    s: "Windows ME",
    r: /(Win 9x 4.90|Windows ME)/
  }, {
    s: "Windows 98",
    r: /(Windows 98|Win98)/
  }, {
    s: "Windows 95",
    r: /(Windows 95|Win95|Windows_95)/
  }, {
    s: "Windows NT 4.0",
    r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
  }, {
    s: "Windows CE",
    r: /Windows CE/
  }, {
    s: "Windows 3.11",
    r: /Win16/
  }, {
    s: "Android",
    r: /Android/
  }, {
    s: "Open BSD",
    r: /OpenBSD/
  }, {
    s: "Sun OS",
    r: /SunOS/
  }, {
    s: "Linux",
    r: /(Linux|X11)/
  }, {
    s: "iOS",
    r: /(iPhone|iPad|iPod)/
  }, {
    s: "Mac OS X",
    r: /Mac OS X/
  }, {
    s: "Mac OS",
    r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/
  }, {
    s: "QNX",
    r: /QNX/
  }, {
    s: "UNIX",
    r: /UNIX/
  }, {
    s: "BeOS",
    r: /BeOS/
  }, {
    s: "OS/2",
    r: /OS\/2/
  }, {
    s: "Search Bot",
    r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
  }];

  for (var i = 0, cs; cs = clientStrings[i]; i++) {
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }

  var osVersion = unknown;

  if (/Windows/.test(os)) {
    if (/Windows (.*)/.test(os)) {
      osVersion = /Windows (.*)/.exec(os)[1];
    }

    os = "Windows";
  }

  switch (os) {
    case "Mac OS X":
      if (/Mac OS X (10[/._\d]+)/.test(nAgt)) {
        // eslint-disable-next-line no-useless-escape
        osVersion = /Mac OS X (10[\.\_\d]+)/.exec(nAgt)[1];
      }

      break;

    case "Android":
      // eslint-disable-next-line no-useless-escape
      if (/Android ([\.\_\d]+)/.test(nAgt)) {
        // eslint-disable-next-line no-useless-escape
        osVersion = /Android ([\.\_\d]+)/.exec(nAgt)[1];
      }

      break;

    case "iOS":
      if (/OS (\d+)_(\d+)_?(\d+)?/.test(nAgt)) {
        osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
        osVersion = osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
      }

      break;
  }

  return {
    osName: os + osVersion,
    type: "desktop"
  };
}

function getOS() {
  if (isMobile.any()) {
    return isMobile.getOsName();
  } else {
    return detectDesktopOS();
  }
}