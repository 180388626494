"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _inquiry = require("@/api/inquiry.js");

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

var _dictionary = require("@/config/dictionary");

var _index2 = require("@/utils/index.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'caseReply',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      total: 0,
      loading: true,
      tableData: [],
      conditionData: '',
      //来自子组件的数据
      date: '',
      caseState: _dictionary.dictionary.caseState,
      multipleSelection: [],
      multipleSelectionId: [],
      divisionVisible: false,
      //案由弹窗
      reasonList: [],
      //案由数组
      caseReplyData: {},
      form: {
        reasonId: ''
      }
    };
  },
  activated: function activated() {
    this.currentPage = 1;
    this.init();
  },
  created: function created() {
    this.currentPage = 1;
    this.init();
  },
  components: {
    Caseconditons: _index.default
  },
  methods: {
    init: function init() {
      var _this = this;

      this.loading = true;
      (0, _inquiry.getCaseReplyListByPage)({
        limit: this.pageLimit,
        page: this.currentPage,
        sort: "caseId",
        order: "asc"
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //表格审核事件
    showReply: function showReply(val) {
      var _this2 = this;

      this.divisionVisible = true;
      (0, _inquiry.findCaseReplyInfo)({
        caseId: val
      }).then(function (res) {
        if (res.code == 1) {
          _this2.caseReplyData = res.data;
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this3 = this;

      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1,
        sort: "caseId",
        order: "asc"
      };
      this.conditionData = postData;
      (0, _inquiry.getCaseReplyListByPage)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this3.tableData = res.rows;
          _this3.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this4 = this;

      this.pageLimit = val;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        this.conditionData.sort = "caseId";
        this.conditionData.order = "asc";
        (0, _inquiry.getCaseReplyListByPage)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this4.tableData = res.rows;
            _this4.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getCaseReplyListByPage)({
          limit: val,
          page: this.currentPage,
          sort: "caseId",
          order: "asc"
        }).then(function (res) {
          if (res.code == 1) {
            _this4.tableData = res.rows;
            _this4.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this5 = this;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        this.conditionData.sort = "caseId";
        this.conditionData.order = "asc";
        (0, _inquiry.getCaseReplyListByPage)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this5.tableData = res.rows;
            _this5.total = res.total;
            _this5.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getCaseReplyListByPage)({
          limit: this.pageLimit,
          page: val,
          sort: "caseId",
          order: "asc"
        }).then(function (res) {
          if (res.code == 1) {
            _this5.tableData = res.rows;
            _this5.total = res.total;
            _this5.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;