"use strict";

module.exports = {
  title: process.env.VUE_APP_BASE_API_TITLE,
  logo: process.env.VUE_APP_BASE_API_LOGO,
  commId: process.env.VUE_APP_BASE_API_COMMID,
  // title:'赣江新区国际仲裁院',
  // logo:'https://aihuz-open-file.oss-cn-shenzhen.aliyuncs.com/logo/111200021177112logo.png',
  // commId:"34",
  // title:'十堰仲裁委员会',
  // logo:'https://aihuz-open-file.oss-cn-shenzhen.aliyuncs.com/logo/112000415578401044logo.png',
  // commId:"33",
  // title:'深汕国际仲裁院',
  // logo:'https://aihuz-open-file.oss-cn-shenzhen.aliyuncs.com/logo/111245787002logo.png',
  // commId:"35",
  // title:'衡阳仲裁委员会',
  // logo:'https://aihuz-open-file.oss-cn-shenzhen.aliyuncs.com/upload/comm_sign/file/390a9b8758694c75904a0ce9b2bb936d.png',
  // commId:"36",
  // title:'哈尔滨仲裁委员会',
  // logo:'https://aihuz-open-file.oss-cn-shenzhen.aliyuncs.com/logo/11458120548512485.jpg',
  // commId:"37",

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the tags
   */
  tagsView: true
};