"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _caseRevoke = require("@/api/caseRevoke.js");

var _dictionary = require("@/config/dictionary");

var _send = _interopRequireDefault(require("../service/send"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "msend",
  components: {
    SendCase: _send.default
  },
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      upcomingVisible: false,
      loadingProcess: false,
      loadingSave: false,
      closeCaseVisible: false,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      tableData: [],
      total: 0,
      //总页数
      caseState: _dictionary.dictionary.caseRevokeStats,
      caseId: "",
      allLoading: false,
      //全屏loading
      formFilter: {
        caseId: "",
        caseNo: "",
        state: '2'
      },
      nextStatus: 0,
      optionArr: [],
      processNextId: "",
      dataContentForm: {
        title: "",
        subject: "",
        caseNo: ""
      },
      dataContent: [],
      selectCurRow: null,
      ossFile: {
        title: "",
        subject: "",
        aliyunOssHref: ""
      }
    };
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      var params = this.formFilter;
      params = Object.assign(params, {
        limit: this.pageLimit,
        page: this.currentPage
      });
      (0, _caseRevoke.getFindRevokeCasePage)(params).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: "inquiryDetail",
        params: {
          caseId: val
        }
      });
    },
    //送达
    handleSendData: function handleSendData(data) {
      this.$refs.sendCase.init(data.caseId, -11, '', data);
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.currentPage = 1;
      this.init();
    },
    onFilter: function onFilter() {
      this.init();
    },
    handleEdit: function handleEdit(data) {
      var _this2 = this;

      this.caseId = data.caseId;
      this.selectCurRow = data;
      this.upcomingVisible = true;
      (0, _caseRevoke.onFindRevokeInfo)(data.caseId).then(function (res) {
        var rest = res.data || {};
        var content = rest.content || "{}";
        var contentJSON = JSON.parse(content);
        _this2.dataContent = contentJSON.content;
        _this2.dataContentForm.title = rest.title;
        _this2.dataContentForm.subject = rest.subject;
        _this2.dataContentForm.caseNo = rest.caseNo;
      });
    },
    handlPDF: function handlPDF(data) {
      var _this3 = this;

      this.closeCaseVisible = true;
      (0, _caseRevoke.onFindRevokeInfo)(data.caseId).then(function (res) {
        _this3.ossFile = res.data || {};
      });
    },
    updateShow: function updateShow() {
      this.allLoading = false;
    },
    updateErrorShow: function updateErrorShow() {
      this.allLoading = false;
    },
    // 审核通过后刷新数据
    refreshPage: function refreshPage() {
      this.allLoading = false;
      this.sendCaseVisible = false;
      this.init();
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      this.pageLimit = val;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
      this.init();
    },
    handleSave: function handleSave() {
      var _this4 = this;

      this.loadingSave = true;
      this.id = this.selectCurRow.id;
      var parmas = {
        id: this.selectCurRow.id,
        content: JSON.stringify({
          content: this.dataContent
        }) // reviewId: this.selectCurRow.reviewId,
        // reviewName: this.selectCurRow.reviewName

      };
      (0, _caseRevoke.onCaseRevokeSave)(parmas).then(function (res) {
        _this4.loadingSave = false;

        if (res.code == "1") {
          _this4.$message({
            message: "操作成功!",
            type: "success"
          });

          _this4.upcomingVisible = false;
        } else {
          _this4.$message({
            message: "操作失败!",
            type: "warning"
          });
        }
      });
    },
    handleAdd: function handleAdd(val) {
      this.dataContent.splice(val + 1, 0, {
        text: ""
      });
    },
    handleDelete: function handleDelete(val) {
      if (this.dataContent.length == 0) {
        this.$message.error("决定书修正信息已为空");
      } else {
        this.dataContent.splice(val, 1);
      }
    }
  }
};
exports.default = _default;