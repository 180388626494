"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    // 答辩状态
    caseReplyCondition: {
      type: Boolean,
      default: true
    },
    //案件类型
    caseTypeCondition: {
      type: Boolean,
      default: true
    },
    //案件来源
    caseOriginCondition: {
      type: Boolean,
      default: true
    },
    //送达状态
    serviceStatusConditon: {
      type: Boolean,
      default: false
    },
    rowThirdNameConditon: {
      type: Boolean,
      default: true
    },
    rowSecondNameCondition: {
      type: Boolean,
      default: true
    },
    //送达方式
    serviceWayCondition: {
      type: Boolean,
      default: false
    },
    //送达类型
    serviceTypeCondition: {
      type: Boolean,
      default: false
    },
    rowSecondName: {
      type: String,
      default: "申请日期"
    },
    rowThirdName: {
      type: String,
      default: "立案日期"
    }
  },
  data: function data() {
    return {
      // 案件搜索
      searchArr: [{
        value: "当事人",
        label: "当事人"
      }, {
        value: "案号",
        label: "案号"
      }, {
        value: "受理号",
        label: "受理号"
      }],
      //答辩类型
      replyArr: [{
        value: "全部",
        label: "全部",
        status: ""
      }, {
        value: "未答辩",
        label: "未答辩",
        status: 0
      }, {
        value: "已答辩",
        label: "已答辩",
        status: 1
      }],
      //案件类型
      caseType: [{
        value: "全部",
        label: "全部"
      }, {
        value: "金融案件",
        label: "金融案件"
      }, {
        value: "车贷案件",
        label: "车贷案件"
      }],
      //案件来源
      caseOrigin: [{
        value: "全部",
        label: "全部"
      }, {
        value: "互仲链",
        label: "互仲链"
      }],
      // 送达状态
      serviceStatusArr: [{
        value: "全部",
        label: "全部"
      }, {
        value: "发送失败",
        label: "发送失败"
      }, {
        value: "已重发",
        label: "已重发"
      }, {
        value: "发送成功",
        label: "发送成功"
      }, {
        value: "发送中",
        label: "发送中"
      }],
      //送达方式
      serviceWayArr: [{
        value: "全部",
        label: ""
      }, {
        value: "短信",
        label: "短信"
      }, {
        value: "邮件",
        label: "邮件"
      }, {
        value: "服务平台",
        label: "服务平台"
      }],
      //送达类型
      serviceTypeArr: [{
        value: "全部",
        label: "全部"
      }, {
        value: "立案送达",
        label: "立案送达"
      }, {
        value: "组庭送达",
        label: "组庭送达"
      }, {
        value: "开庭送达",
        label: "开庭送达"
      }, {
        value: "裁决送达",
        label: "裁决送达"
      }, {
        value: "撤案送达",
        label: "撤案送达"
      }, {
        value: "仲裁庭取证送达",
        label: "仲裁庭取证送达"
      }],
      searchvalue: "当事人",
      //搜索值
      input: "",
      //搜索值
      rowSecondDate: "",
      //["2019-09-12", "2019-10-23"]
      rowThirdDate: "",
      //["2019-09-12", "2019-10-23"]
      replyvalue: "",
      //答辩状态值
      caseTypevalue: "",
      //案件类型值
      caseOriginvalue: "",
      //案件来源值
      serviceStatusvalue: "",
      //送达状态值
      serviceWayvalue: "",
      //送达方式值
      serviceTypevalue: "",
      //送达类型值
      reviewName: "",
      //当前处理人
      postReplyvalue: "",
      //根据答辩状态值传给后台的值    0 无答辩 1 有答辩
      postCaseType: "",
      //根据案件类型值传给后台的值    案件类型 1：金融案件: 2：车贷案件
      postCaseOrigin: "",
      //根据案件来源值传给后台的值
      postserviceWay: "",
      //根据送达方式值传给后台的值
      postServiceStatus: "",
      //根据送达方式值传给后台的值
      postServiceType: "",
      //根据送达类型值传给后台的值
      postPartyName: "",
      //根据案件搜索值   当事人的值
      postCaseNum: "",
      //根据案件搜索值   案号的值
      postAcceptNum: "" //根据案件搜索值   受理号的值

    };
  },
  mounted: function mounted() {},
  methods: {
    // 答辩状态改变
    replyChange: function replyChange(val) {
      if (val == "未答辩") {
        this.postReplyvalue = "0";
      } else if (val == "已答辩") {
        this.postReplyvalue = "1";
      } else {
        this.postReplyvalue = "";
      }
    },
    //案件类型改变
    caseTypeChange: function caseTypeChange(val) {
      if (val == "金融案件") {
        this.postCaseType = "1";
      } else if (val == "车贷案件") {
        this.postCaseType = "2";
      } else {
        this.postCaseType = "";
      }
    },
    //案件来源改变
    caseOriginChange: function caseOriginChange(val) {
      if (val == "全部" || val == "") {
        this.postCaseOrigin = "";
      } else {
        this.postCaseOrigin = val;
      }
    },
    //送达方式改变
    serviceWayChange: function serviceWayChange(val) {
      if (val == "全部" || val == "") {
        this.postserviceWay = "";
      } else if (val == "短信") {
        this.postserviceWay = "1";
      } else if (val == "邮件") {
        this.postserviceWay = "2";
      } else if (val == "服务平台") {
        this.postserviceWay = "3";
      }
    },
    //送达状态
    serviceStatusChange: function serviceStatusChange(val) {
      if (val == "全部" || val == "") {
        this.postServiceStatus = "";
      } else if (val == "发送失败") {
        this.postServiceStatus = "0";
      } else if (val == "已重发") {
        this.postServiceStatus = "1";
      } else if (val == "发送成功") {
        this.postServiceStatus = "4";
      } else if (val == "发送中") {
        this.postServiceStatus = "5";
      }
    },
    //送达类型
    serviceTypeChange: function serviceTypeChange(val) {
      if (val == "全部" || val == "") {
        this.postServiceType = "";
      } else if (val == "立案送达") {
        this.postServiceType = "1";
      } else if (val == "组庭送达") {
        this.postServiceType = "2";
      } else if (val == "开庭送达") {
        this.postServiceType = "3";
      } else if (val == "裁决送达") {
        this.postServiceType = "4";
      } else if (val == "撤案送达") {
        this.postServiceType = "5";
      } else if (val == "仲裁庭取证送达") {
        this.postServiceType = "6";
      }
    },
    // 申请日期时间改变
    dateChange: function dateChange(val) {
      this.$emit("dataChange", val);
    },
    // 筛选按钮
    screen: function screen() {
      if (this.searchvalue == "当事人") {
        this.postPartyName = this.input;
        this.postCaseNum = "";
        this.postAcceptNum = "";
      } else if (this.searchvalue == "案号") {
        this.postCaseNum = this.input;
        this.postAcceptNum = "";
        this.postPartyName = "";
      } else if (this.searchvalue == "受理号") {
        this.postAcceptNum = this.input;
        this.postPartyName = "";
        this.postCaseNum = "";
      } // 申请日期 和 立案日期 类型数据
      // let a= {
      //    caseId:this.postAcceptNum,
      //   caseNo:this.postCaseNum,
      //   partyName:this.postPartyName,
      //   defenceState:this.postReplyvalue,
      //   beginCtime:this.rowSecondDate[0],
      //   endCtime:this.rowSecondDate[1],
      //   caseType:this.postCaseType,
      //   beginRegisterTime:this.rowThirdDate[0],
      //   endRegisterTime:this.rowThirdDate[1],
      //   source:this.caseOriginvalue,
      //   limit:10,
      //   offset:0
      // }


      var conditionData = {
        caseId: this.postAcceptNum,
        //搜索值
        caseNo: this.postCaseNum,
        partyName: this.postPartyName,
        rowSecondDate: this.rowSecondDate,
        //["2019-09-12", "2019-10-23"]
        rowThirdDate: this.rowThirdDate,
        //["2019-09-12", "2019-10-23"]
        replyvalue: this.postReplyvalue,
        //答辩状态值
        caseTypevalue: this.postCaseType,
        //案件类型值
        caseOriginvalue: this.postCaseOrigin,
        //案件来源值
        serviceStatusvalue: this.postServiceStatus,
        //送达状态值
        serviceWayvalue: this.postserviceWay,
        //送达方式值
        serviceTypevalue: this.postServiceType //送达类型值

      };
      this.$emit("choose", conditionData);
    },
    //点击清空所有的筛选条件
    clearAllConditon: function clearAllConditon() {
      this.searchvalue = "当事人";
      this.replyvalue = "";
      this.caseTypevalue = "";
      this.caseOriginvalue = "";
      this.input = "";
      this.rowThirdDate = "";
      this.rowSecondDate = "";
      this.postReplyvalue = "";
      this.postCaseType = "";
      this.serviceStatusvalue = "";
      this.serviceWayvalue = "";
      this.serviceTypevalue = "";
      this.postAcceptNum = "";
      this.postCaseNum = "";
      this.postPartyName = "";
      this.postServiceStatus = "";
      this.postserviceWay = "";
      this.postServiceType = "";
      this.$emit("cleanCondition");
    },
    getValue: function getValue() {
      if (this.searchvalue == "当事人") {
        this.postPartyName = this.input;
        this.postCaseNum = "";
        this.postAcceptNum = "";
      } else if (this.searchvalue == "案号") {
        this.postCaseNum = this.input;
        this.postAcceptNum = "";
        this.postPartyName = "";
      } else if (this.searchvalue == "受理号") {
        this.postAcceptNum = this.input;
        this.postPartyName = "";
        this.postCaseNum = "";
      }

      var conditionData = {
        caseId: this.postAcceptNum,
        //搜索值
        caseNo: this.postCaseNum,
        partyName: this.postPartyName,
        rowSecondDate: this.rowSecondDate,
        //["2019-09-12", "2019-10-23"]
        rowThirdDate: this.rowThirdDate,
        //["2019-09-12", "2019-10-23"]
        replyvalue: this.postReplyvalue,
        //答辩状态值
        caseTypevalue: this.postCaseType,
        //案件类型值
        caseOriginvalue: this.postCaseOrigin,
        //案件来源值
        serviceStatusvalue: this.postServiceStatus,
        //送达状态值
        serviceWayvalue: this.postserviceWay,
        //送达方式值
        serviceTypevalue: this.postServiceType //送达类型值

      };
      return conditionData;
    }
  }
};
exports.default = _default;