"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addArbitralBody = addArbitralBody;
exports.addArbitratoSign = addArbitratoSign;
exports.addArbitrator = addArbitrator;
exports.addArbitratorAccount = addArbitratorAccount;
exports.addCaseBookTemplate = addCaseBookTemplate;
exports.addCaseSendTemplate = addCaseSendTemplate;
exports.addChargeTemplate = addChargeTemplate;
exports.addEvidenceList = addEvidenceList;
exports.checkArbitralBody = checkArbitralBody;
exports.checkArbitrator = checkArbitrator;
exports.checkCaseBookTemplate = checkCaseBookTemplate;
exports.checkCaseSendTemplate = checkCaseSendTemplate;
exports.checkChargeTemplate = checkChargeTemplate;
exports.deleteAisleById = deleteAisleById;
exports.deleteArbitralBody = deleteArbitralBody;
exports.deleteArbitrator = deleteArbitrator;
exports.deleteCaseBookTemplate = deleteCaseBookTemplate;
exports.deleteCaseSendTemplate = deleteCaseSendTemplate;
exports.deleteEquipment = deleteEquipment;
exports.deleteEvidenceList = deleteEvidenceList;
exports.deleteGroupUserById = deleteGroupUserById;
exports.deleteTrialYlGroupById = deleteTrialYlGroupById;
exports.editDiscipline = editDiscipline;
exports.editEvidenceList = editEvidenceList;
exports.getAisleInfoById = getAisleInfoById;
exports.getAllCommission = getAllCommission;
exports.getArbitralBody = getArbitralBody;
exports.getArbitratorAccount = getArbitratorAccount;
exports.getArbitratorList = getArbitratorList;
exports.getChargeList = getChargeList;
exports.getCompayList = getCompayList;
exports.getConferenceAisleList = getConferenceAisleList;
exports.getEquipmentInfoById = getEquipmentInfoById;
exports.getEquipmentList = getEquipmentList;
exports.getEvidenceList = getEvidenceList;
exports.getProcesscaseStateList = getProcesscaseStateList;
exports.getTimeTaskList = getTimeTaskList;
exports.getTimeTaskLogList = getTimeTaskLogList;
exports.getTrialEquipmentList = getTrialEquipmentList;
exports.getTrialYlGroupById = getTrialYlGroupById;
exports.getTrialYlGroupList = getTrialYlGroupList;
exports.getallCaseList = getallCaseList;
exports.getcaseBookTemplateList = getcaseBookTemplateList;
exports.getcaseBookTemplateType = getcaseBookTemplateType;
exports.getcaseRelatedBookList = getcaseRelatedBookList;
exports.getcaseSendTemplateList = getcaseSendTemplateList;
exports.groupAddUser = groupAddUser;
exports.runTimeTask = runTimeTask;
exports.saveAisle = saveAisle;
exports.saveAisleYl = saveAisleYl;
exports.saveEquipment = saveEquipment;
exports.saveTrialYlGroup = saveTrialYlGroup;
exports.selectArbitratorInfo = selectArbitratorInfo;
exports.selectGroupUserList = selectGroupUserList;
exports.startTimeTask = startTimeTask;
exports.stopTimeTask = stopTimeTask;
exports.updateArbitralBody = updateArbitralBody;
exports.updateArbitrator = updateArbitrator;
exports.updateCaseBookTemplate = updateCaseBookTemplate;
exports.updateCaseSendTemplate = updateCaseSendTemplate;
exports.updateChargeTemplate = updateChargeTemplate;
exports.uploadArbitralBodyFile = uploadArbitralBodyFile;
exports.uploadArbitratoSign = uploadArbitratoSign;
exports.uploadCommonURL = uploadCommonURL;
exports.uploadFileCommonURL = uploadFileCommonURL;

var _request = _interopRequireDefault(require("@/utils/request"));

// 全局图片上传
function uploadCommonURL() {
  return '/back/manager/uploadCommonFile';
} // 仲裁员列表请求  -peng


function getArbitratorList(params) {
  return (0, _request.default)({
    url: '/back/manager/arbitratorInfo/page',
    method: 'get',
    params: params
  });
} // 查看单个仲裁员


function checkArbitrator(params) {
  return (0, _request.default)({
    url: '/back/manager/arbitratorInfo/' + params,
    method: 'get'
  });
} //仲裁员删除


function deleteArbitrator(data) {
  return (0, _request.default)({
    url: '/back/manager/arbitratorInfo/deleteById',
    method: 'post',
    data: data
  });
} //添加仲裁员


function addArbitrator(data) {
  return (0, _request.default)({
    url: '/back/manager/arbitratorInfo',
    method: 'post',
    data: data
  });
} // 修改仲裁员


function updateArbitrator(data) {
  return (0, _request.default)({
    url: '/back/manager/arbitratorInfo',
    method: 'put',
    data: data
  });
} // 查询仲裁委列表


function getAllCommission(params) {
  return (0, _request.default)({
    url: '/back/commissionInfo/getAllCommission',
    method: 'get',
    params: params
  });
} // 查看仲裁员账号


function getArbitratorAccount(params) {
  return (0, _request.default)({
    url: '/back/admin/getUserById/' + params,
    method: 'get'
  });
} // 开通仲裁员账号


function addArbitratorAccount(data) {
  return (0, _request.default)({
    url: '/back/manager/arbitratorInfo/openAccount',
    method: 'post',
    data: data
  });
} // 开通签名之后返回的paf请求


function addArbitratoSign(data) {
  return (0, _request.default)({
    url: 'batchCase/caseFileInstrument/createArbitratorBook',
    method: 'post',
    data: data
  });
} // 上传的仲裁员签名


function uploadArbitratoSign() {
  return '/back/manager/uploadSignFile';
} // 仲裁委员会列表


function getArbitralBody(params) {
  return (0, _request.default)({
    url: '/back/commissionInfo/page',
    method: 'get',
    params: params
  });
} //仲裁机构删除


function deleteArbitralBody(data) {
  return (0, _request.default)({
    url: '/back/commissionInfo/deleteById',
    method: 'post',
    data: data
  });
} // 仲裁机构文件上传地址


function uploadArbitralBodyFile() {
  return '/back/manager/uploadCommSignFile';
} //添加仲裁机构


function addArbitralBody(data) {
  return (0, _request.default)({
    url: '/back/commissionInfo/',
    method: 'post',
    data: data
  });
} // 查看单个仲裁机构


function checkArbitralBody(params) {
  return (0, _request.default)({
    url: '/back/commissionInfo/' + params,
    method: 'get'
  });
} // 修改单个仲裁机构


function updateArbitralBody(data) {
  return (0, _request.default)({
    url: '/back/commissionInfo',
    method: 'put',
    data: data
  });
} // 查询文书模板列表


function getcaseBookTemplateList(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBookTemplate/page',
    method: 'get',
    params: params
  });
} // 查询文书类型


function getcaseBookTemplateType(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBookTemplate/getTemplateType',
    method: 'post',
    data: data
  });
} //文书模板删除


function deleteCaseBookTemplate(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBookTemplate/deleteById',
    method: 'post',
    data: data
  });
} // 添加文书


function addCaseBookTemplate(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBookTemplate',
    method: 'post',
    data: data
  });
} // 查询单个模板文书


function checkCaseBookTemplate(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBookTemplate/' + params,
    method: 'get'
  });
} // 修改单个模板文书


function updateCaseBookTemplate(data) {
  return (0, _request.default)({
    url: '/batchCase/caseBookTemplate',
    method: 'put',
    data: data
  });
} // 送达模板管理    表格列表


function getcaseSendTemplateList(params) {
  return (0, _request.default)({
    url: '/batchCase/caseSendTemplate/page',
    method: 'get',
    params: params
  });
} // 案件状态列表


function getProcesscaseStateList(data) {
  return (0, _request.default)({
    url: '/activiti/tProcessInfo/getProcessCaseStateList',
    method: 'post',
    data: data
  });
} // 关联文书列表


function getcaseRelatedBookList(params) {
  return (0, _request.default)({
    url: '/batchCase/caseBookTemplate/list',
    method: 'get',
    params: params
  });
} //送达模板删除


function deleteCaseSendTemplate(data) {
  return (0, _request.default)({
    url: '/batchCase/caseSendTemplate/deleteById',
    method: 'post',
    data: data
  });
} // 添加送达模板


function addCaseSendTemplate(data) {
  return (0, _request.default)({
    url: '/batchCase/caseSendTemplate',
    method: 'post',
    data: data
  });
} // 查询单个送达模板


function checkCaseSendTemplate(data) {
  return (0, _request.default)({
    url: '/batchCase/caseSendTemplate/getInfoById',
    method: 'post',
    data: data
  });
} // 修改单个送达模板


function updateCaseSendTemplate(data) {
  return (0, _request.default)({
    url: '/batchCase/caseSendTemplate',
    method: 'put',
    data: data
  });
} // 单个文书上传


function uploadFileCommonURL(data) {
  return '/back/manager/uploadFileByCommInfo';
} // 仲裁费列表


function getChargeList(params) {
  return (0, _request.default)({
    url: '/back/tChargeTemplate/page',
    method: 'get',
    params: params
  });
} // 新增仲裁费


function addChargeTemplate(data) {
  return (0, _request.default)({
    url: '/back/tChargeTemplate/addChargeTemplate',
    method: 'post',
    data: data
  });
} // 查询仲裁费模板


function checkChargeTemplate(data) {
  return (0, _request.default)({
    url: '/back/tChargeTemplate/getChargeTemplate',
    method: 'post',
    data: data
  });
} // 修改单个仲裁费模板


function updateChargeTemplate(data) {
  return (0, _request.default)({
    url: '/back/tChargeTemplate/updateChargeTemplate',
    method: 'post',
    data: data
  });
} // 定时任务列表


function getTimeTaskList(params) {
  return (0, _request.default)({
    url: '/task/manager/scheduleJob/list',
    method: 'get',
    params: params
  });
} // 定时任务 ----查看执行日志


function getTimeTaskLogList(data) {
  return (0, _request.default)({
    url: '/task/manager/schedulelog/queryScheduleLogByPage',
    method: 'post',
    data: data
  });
} //开启定时任务


function startTimeTask(data) {
  return (0, _request.default)({
    url: '/task/manager/scheduleJob/start',
    method: 'post',
    data: data
  });
} //停止定时任务


function stopTimeTask(data) {
  return (0, _request.default)({
    url: '/task/manager/scheduleJob/stop',
    method: 'post',
    data: data
  });
} //立即运行定时任务


function runTimeTask(data) {
  return (0, _request.default)({
    url: '/task/manager/scheduleJob/run',
    method: 'post',
    data: data
  });
} // 证据清单每个仲裁委对应的列表数据


function getEvidenceList(data) {
  return (0, _request.default)({
    url: 'back/tEvidenceList/selectEvidenceList',
    method: 'post',
    data: data
  });
} // 移除证据清单规则


function deleteEvidenceList(params) {
  return (0, _request.default)({
    url: 'back/tEvidenceList/' + params,
    method: 'delete'
  });
} // 添加证据清单规则


function addEvidenceList(data) {
  return (0, _request.default)({
    url: 'back/tEvidenceList',
    method: 'post',
    data: data
  });
} // 修改证据清单规则


function editEvidenceList(data) {
  return (0, _request.default)({
    url: 'back/tEvidenceList',
    method: 'put',
    data: data
  });
} // 证据清单查询所有企业


function getCompayList(data) {
  return (0, _request.default)({
    url: '/batchCase/litigant/selectAllEnterpriseList',
    method: 'post',
    data: data
  });
} //查询所有案件类型列表


function getallCaseList(params) {
  return (0, _request.default)({
    url: '/back/caseTypeConfig/selectCaseTypeList/' + params,
    method: 'get'
  });
} // 查询庭审源列表


function getConferenceAisleList(params) {
  return (0, _request.default)({
    url: '/trialapi/conferenceAisle/page',
    method: 'get',
    params: params
  });
} //保存通道saveAisle


function saveAisle(data) {
  return (0, _request.default)({
    url: '/trialapi/conferenceAisle/saveAisle',
    method: 'post',
    data: data
  });
} //保存通道saveAisle-亿联


function saveAisleYl(data) {
  return (0, _request.default)({
    url: '/trialapi/conferenceAisle/saveAisleYl',
    method: 'post',
    data: data
  });
} //通道删除


function deleteAisleById(params) {
  return (0, _request.default)({
    url: 'trialapi/conferenceAisle/' + params,
    method: 'delete'
  });
} //查询通道信息


function getAisleInfoById(params) {
  return (0, _request.default)({
    url: '/trialapi/conferenceAisle/' + params,
    method: 'get'
  });
} // 查询用户组列表


function getTrialYlGroupList(params) {
  return (0, _request.default)({
    url: '/trialapi/trialYlGroup/page',
    method: 'get',
    params: params
  });
} //保存用户组


function saveTrialYlGroup(data) {
  return (0, _request.default)({
    url: 'trialapi/trialYlGroup/createGroup',
    method: 'post',
    data: data
  });
} //修改用户组


function editDiscipline(data) {
  return (0, _request.default)({
    url: 'trialapi/trialYlGroup/editDiscipline',
    method: 'post',
    data: data
  });
} //通道删除


function deleteTrialYlGroupById(params) {
  return (0, _request.default)({
    url: 'trialapi/trialYlGroup/' + params,
    method: 'delete'
  });
} //查询通道信息


function getTrialYlGroupById(params) {
  return (0, _request.default)({
    url: '/trialapi/trialYlGroup/' + params,
    method: 'get'
  });
} //查询仲裁员信息


function selectArbitratorInfo(data) {
  return (0, _request.default)({
    url: 'back/admin/selectArbitratorInfo',
    method: 'post',
    data: data
  });
} //添加会议用户


function groupAddUser(data) {
  return (0, _request.default)({
    url: 'trialapi/trialYlUser/addUser',
    method: 'post',
    data: data
  });
} //查询会议用户列表


function selectGroupUserList(params) {
  return (0, _request.default)({
    url: 'trialapi/trialYlUser/userList/' + params,
    method: 'get'
  });
} //用户删除


function deleteGroupUserById(params) {
  return (0, _request.default)({
    url: 'trialapi/trialYlUser/' + params,
    method: 'delete'
  });
} // 查询视频设备表


function getTrialEquipmentList(params) {
  return (0, _request.default)({
    url: 'trialapi/trialEquipment/page',
    method: 'get',
    params: params
  });
} //保存设备


function saveEquipment(data) {
  return (0, _request.default)({
    url: 'trialapi/trialEquipment/saveEquipment',
    method: 'post',
    data: data
  });
} //通道删除


function deleteEquipment(params) {
  return (0, _request.default)({
    url: 'trialapi/trialEquipment/' + params,
    method: 'delete'
  });
} //查询通道信息


function getEquipmentInfoById(params) {
  return (0, _request.default)({
    url: 'trialapi/trialEquipment/' + params,
    method: 'get'
  });
} //查询设备


function getEquipmentList(data) {
  return (0, _request.default)({
    url: 'trialapi/trialEquipment/getEquipmentList',
    method: 'post',
    data: data
  });
}