import { render, staticRenderFns } from "./index.vue?vue&type=template&id=426e3dc3&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=426e3dc3&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "426e3dc3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/work/project/aihuz/arbitration-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('426e3dc3')) {
      api.createRecord('426e3dc3', component.options)
    } else {
      api.reload('426e3dc3', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=426e3dc3&scoped=true&", function () {
      api.rerender('426e3dc3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/case/warnRemind/index.vue"
export default component.exports