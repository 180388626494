"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;

var _typeof2 = _interopRequireDefault(require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.weak-map");

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || (0, _typeof2.default)(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

// import store from '@/store'
_vue.default.use(_vueRouter.default);
/* Layout */


/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  name: 'login',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/encryptByAES',
  name: 'encryptByAES',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/encryptByAES/index'));
    });
  }
}, {
  path: '/chainIndex',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/blockChain/index'));
    });
  },
  hidden: true
}, {
  path: '/chainDetail',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/blockChain/detail'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return _interopRequireWildcard(require('@/views/404'));
    });
  },
  hidden: true
} // {
//   path: '/',
//   component: Layout,
//   redirect: '/Survey',
//   children: [{
//     path: 'Survey',
//     name: 'Survey',
//     component: () => import('@/views/Survey/index'),
//     meta: { title: '概况', icon: 'dashboard',affix: true }
//   }]
// } ,
// {
//   path: '/system',
//   component: Layout,
//   redirect: '/system/menu',
//   name: 'system',
//   meta: {title: '系统管理', icon: 'example'},
//   children: [
//     {
//       path: 'menu',
//       name: 'menu',
//       component: () => import('@/views/system/menu/index'),
//       meta: {title: '菜单管理', icon: 'tree'}
//     },
//     {
//       path: 'role',
//       name: 'role',
//       component: () => import('@/views/system/role/index'),
//       meta: {title: '角色管理', icon: 'table'}
//     },
//     {
//       path: 'process',
//       name: 'process',
//       component: () => import('@/views/system/process/index'),
//       meta: {title: '流程管理', icon: 'tree'}
//     },
//     {
//       path: 'addprocess/:id',
//       name: 'addprocess',
//       hidden: true,
//       component: () => import('@/views/system/process/addProcess'),
//       meta: {title: '流程管理/添加流程管理', icon: 'tree'}
//     },
//   ]
//  },
// {
//   path: '/case',
//   component: Layout,
//   redirect: '/case/inquiry',
//   name: 'case',
//   meta: { title: '案件', icon: 'example',noCache: false },
//   children: [
//     {
//       path: 'inquiry',
//       name: 'inquiry',
//       component: () => import('@/views/case/inquiry/index'),
//       meta: { title: '案件查询' ,noCache: false}
//     },
//     {
//       path: '/inquiryDetail/:caseId',
//       component: () => import('@/views/case/inquiry/detail'),
//       hidden: true,
//       name: 'inquiryDetail',
//       meta: { title: '裁决审理' },
//     },
//     {
//       path: 'handing',
//       name: 'handing',
//       component: () => import('@/views/case/handing/index'),
//       meta: { title: '案件受理' }
//     },
//     {
//       path: '/handingDetail/:caseId',
//       component: () => import('@/views/case/handing/detail'),
//       hidden: true,
//       name: 'handingDetail',
//       meta: { title: '立案审核详情' },
//     },
//     {
//       path: 'service',
//       name: 'service',
//       component: () => import('@/views/case/service/index'),
//       meta: { title: '案件送达' }
//     },
//     {
//       path: 'division',
//       name: 'division',
//       component: () => import('@/views/case/division/index'),
//       meta: { title: '案件组庭' }
//     },
//     {
//       path: 'holdcourt',
//       name: 'holdcourt',
//       component: () => import('@/views/case/holdcourt/index'),
//       meta: { title: '开庭审理' }
//     },
//     {
//       path: 'judge',
//       name: 'judge',
//       component: () => import('@/views/case/judge/index'),
//       meta: { title: '裁决审理' }
//     },
//     {
//       path: 'onfile',
//       name: 'onfile',
//       component: () => import('@/views/case/onfile/index'),
//       meta: { title: '案件归档' }
//     },
//     {
//       path: 'auditing',
//       name: 'auditing',
//       component: () => import('@/views/case/auditing/index'),
//       meta: { title: '案件审核' }
//     },
//     {
//       path: 'manage',
//       name: 'manage',
//       component: () => import('@/views/case/manage/index'),
//       meta: { title: '案件管理' }
//     },
//     {
//       path: 'auditInCase',
//       name: 'auditInCase',
//       component: () => import('@/views/case/auditInCase/index'),
//       meta: { title: '案中审核' }
//     },
//     {
//       path: 'warnRemind',
//       name: 'warnRemind',
//       component: () => import('@/views/case/warnRemind/index'),
//       meta: { title: '预警提醒' }
//     },
//     {
//       path: 'caseProcedure',
//       name: 'caseProcedure',
//       component: () => import('@/views/case/caseProcedure/index'),
//       meta: { title: '案件流程审核' }
//     },
//   ]
// },]
// {
//   path: '/case',
//   component: Layout,
//   redirect: '/case/inquiry',
//   name: 'case',
//   meta: { title: '案件待办', icon: 'example',noCache: false },
//   children: [
//     {
//       path: 'inquiry',
//       name: 'inquiry',
//       component: () => import('@/views/case/inquiry/index'),
//       meta: { title: '案件查询' ,noCache: false}
//     },
//     {
//       path: '/inquiryDetail/:caseId',
//       // component: Layout,
//       component: () => import('@/views/case/inquiry/detail'),
//       hidden: true,
//       name: 'inquiryDetail',
//       meta: { title: '案件详情' },
//     },
//     {
//       path: 'handing',
//       name: 'handing',
//       component: () => import('@/views/case/handing/index'),
//       meta: { title: '案件受理' }
//     },
//     {
//       path: '/handingDetail/:caseId',
//       // component: Layout,
//       component: () => import('@/views/case/handing/detail'),
//       hidden: true,
//       name: 'handingDetail',
//       meta: { title: '立案审核详情' },
//     },
//     {
//       path: 'service',
//       name: 'service',
//       component: () => import('@/views/case/service/index'),
//       meta: { title: '案件送达' }
//     },
//     {
//       path: 'division',
//       name: 'division',
//       component: () => import('@/views/case/division/index'),
//       meta: { title: '案件组庭' }
//     },
//     {
//       path: 'holdcourt',
//       name: 'holdcourt',
//       component: () => import('@/views/case/holdcourt/index'),
//       meta: { title: '开庭审理' }
//     },
//     {
//       path: 'judge',
//       name: 'judge',
//       component: () => import('@/views/case/judge/index'),
//       meta: { title: '裁决审理' }
//     },
//     {
//       path: 'onfile',
//       name: 'onfile',
//       component: () => import('@/views/case/onfile/index'),
//       meta: { title: '案件归档' }
//     },
//     {
//       path: 'auditing',
//       name: 'auditing',
//       component: () => import('@/views/case/auditing/index'),
//       meta: { title: '案件审核' }
//     },
//     {
//       path: 'manage',
//       name: 'manage',
//       component: () => import('@/views/case/manage/index'),
//       meta: { title: '案件管理' }
//     },
//     {
//       path: 'auditInCase',
//       name: 'auditInCase',
//       component: () => import('@/views/case/auditInCase/index'),
//       meta: { title: '案中审核' }
//     },
//     {
//       path: 'warnRemind',
//       name: 'warnRemind',
//       component: () => import('@/views/case/warnRemind/index'),
//       meta: { title: '预警提醒' }
//     },
//     {
//       path: 'caseProcedure',
//       name: 'caseProcedure',
//       component: () => import('@/views/case/caseProcedure/index'),
//       meta: { title: '案件流程审核' }
//     },
//   ]
// },
// {
//   path: '/dataAnalysis',
//   component: Layout,
//   redirect: '/dataAnalysis/acceptAnalysis',
//   name: 'dataAnalysis',
//   meta: { title: '数据分析', icon: 'example',noCache: false },
//   children: [
//     {
//       path: 'acceptAnalysis',
//       name: 'acceptAnalysis',
//       component: () => import('@/views/dataAnalysis/acceptAnalysis/index'),
//       meta: { title: '受理分析' }
//     },
//     {
//       path: 'handleAnalysis',
//       name: 'handleAnalysis',
//       component: () => import('@/views/dataAnalysis/handleAnalysis/index'),
//       meta: { title: '办理分析' }
//     },
//     {
//       path: 'otherAnalysis',
//       name: 'otherAnalysis',
//       component: () => import('@/views/dataAnalysis/otherAnalysis/index'),
//       meta: { title: '其他分析' }
//     },
//     ]
// },
// {
//   path: '/financial',
//   component: Layout,
//   redirect: '/financial/financialProfile',
//   name: 'financialProfile',
//   meta: { title: '财务管理', icon: 'example',noCache: false },
//   children: [
//     {
//       path: 'financialProfile',
//       name: 'financialProfile',
//       component: () => import('@/views/financial/financialProfile/index'),
//       meta: { title: '财务概况' }
//     },
//     {
//       path: 'billDetails',
//       name: 'billDetails',
//       component: () => import('@/views/financial/billDetail/index'),
//       meta: { title: '账单明细' }
//     },
//     {
//       path: 'receiveSettlement',
//       name: 'receiveSettlement',
//       component: () => import('@/views/financial/receiveSettlement/index'),
//       meta: { title: '应收结算' }
//     },
//     {
//       path: 'createSettlement',
//       name: 'createSettlement',
//       component: Layout,
//       hidden: true,
//       component: () => import('@/views/financial/receiveSettlement/createSettlement'),
//       meta: { title: '新建应收结算单' }
//     },
//     {
//       path: 'receiveDetail',
//       name: 'receiveDetail',
//       component: Layout,
//       hidden: true,
//       component: () => import('@/views/financial/receiveSettlement/detail'),
//       meta: { title: '应收结算单详情' }
//     },
//     {
//       path: 'paySettlement',
//       name: 'paySettlement',
//       component: () => import('@/views/financial/paySettlement/index'),
//       meta: { title: '应付结算' }
//     },
//     {
//       path: 'createPaySettlement',
//       name: 'createPaySettlement',
//       component: Layout,
//       hidden: true,
//       component: () => import('@/views/financial/paySettlement/createSettlement'),
//       meta: { title: '新建应付结算单' }
//     },
//     {
//       path: 'receivePayDetail',
//       name: 'receivePayDetail',
//       component: Layout,
//       hidden: true,
//       component: () => import('@/views/financial/paySettlement/detail'),
//       meta: { title: '应付结算单详情' }
//     },
//     {
//       path: 'rewardPayment',
//       name: 'rewardPayment',
//       component: () => import('@/views/financial/rewardPayment/index'),
//       meta: { title: '报酬发放' }
//     },
//     {
//       path: 'rewardPaymentDetail',
//       name: 'rewardPaymentDetail',
//       component: Layout,
//       hidden: true,
//       component: () => import('@/views/financial/rewardPayment/detail'),
//       meta: { title: '报酬发放详情' }
//     },
//     {
//       path: 'createRewardSettlement',
//       name: 'createRewardSettlement',
//       component: Layout,
//       hidden: true,
//       component: () => import('@/views/financial/rewardPayment/createSettlement'),
//       meta: { title: '新建报酬发放单' }
//     },
//     {
//       path: 'invoiceManage',
//       name: 'invoiceManage',
//       component: () => import('@/views/financial/invoiceManage/index'),
//       meta: { title: '发票管理' }
//     },
//     {
//       path: 'invoiceManageDetail',
//       name: 'invoiceManageDetail',
//       component: Layout,
//       hidden: true,
//       component: () => import('@/views/financial/invoiceManage/detail'),
//       meta: { title: '发票详情' }
//     },
//     {
//       path: 'financialSetup',
//       name: 'financialSetup',
//       component: () => import('@/views/financial/financialSetup/index'),
//       meta: { title: '财务设置' }
//     },
//     ]
// },
// {
//   path: '/trialSchedule',
//   component: Layout,
//   redirect: '/trialSchedule/courtyardSchedule',
//   name: 'trialSchedule',
//   meta: { title: '审理排期', icon: 'example' },
//   children: [
//     {
//       path: 'courtyardSchedule',
//       name: 'courtyardSchedule',
//       component: () => import('@/views/trialSchedule/courtyardSchedule/index'),
//       meta: { title: '组庭排期' }
//     },
//     {
//       path: 'courtSchedule',
//       name: 'courtSchedule',
//       component: () => import('@/views/trialSchedule/courtSchedule/index'),
//       meta: { title: '开庭排期' }
//     },
//     {
//       path: 'adjudicationSchedule',
//       name: 'adjudicationSchedule',
//       component: () => import('@/views/trialSchedule/adjudicationSchedule/index'),
//       meta: { title: '裁决排期' }
//     }
//     ]
// },
// {
//   path: '/system',
//   component: Layout,
//   redirect: '/system/menu',
//   name: 'system',
//   meta: {title: '系统管理', icon: 'example'},
//   children: [
//     {
//       path: 'menu',
//       name: 'menu',
//       component: () => import('@/views/system/menu/index'),
//       meta: {title: '菜单管理', icon: 'tree'}
//     },
//     {
//       path: 'role',
//       name: 'role',
//       component: () => import('@/views/system/role/index'),
//       meta: {title: '角色管理', icon: 'table'}
//     },
//     {
//       path: 'process',
//       name: 'process',
//       component: () => import('@/views/system/process/index'),
//       meta: {title: '流程管理', icon: 'tree'}
//     },
//     {
//       path: 'addprocess/:id',
//       name: 'addprocess',
//       hidden: true,
//       component: () => import('@/views/system/process/addProcess'),
//       meta: {title: '流程管理/添加流程管理', icon: 'tree'}
//     }
//   ]
//  }]
// {
//   path: '/',
//   component: Layout,
//   redirect: '/Survey',
//   children: [{
//     path: 'Survey',
//     name: 'Survey',
//     component: () => import('@/views/Survey/index'),
//     meta: { title: '概况', icon: 'dashboard' }
//   }]
// },
// {
//   path: '/dataAnalysis',
//   component: Layout,
//   redirect: '/dataAnalysis/acceptAnalysis',
//   name: 'dataAnalysis',
//   meta: { title: '数据分析', icon: 'example',noCache: false },
//   children: [
//     {
//       path: 'acceptAnalysis',
//       name: 'acceptAnalysis',
//       component: () => import('@/views/dataAnalysis/acceptAnalysis/index'),
//       meta: { title: '受理分析' }
//     },
//     {
//       path: 'handleAnalysis',
//       name: 'handleAnalysis',
//       component: () => import('@/views/dataAnalysis/handleAnalysis/index'),
//       meta: { title: '办理分析' }
//     },
//     {
//       path: 'otherAnalysis',
//       name: 'otherAnalysis',
//       component: () => import('@/views/dataAnalysis/otherAnalysis/index'),
//       meta: { title: '其他分析' }
//     },
//     ]
// },
// {
//   path: '/financial',
//   component: Layout,
//   redirect: '/financial/financialProfile',
//   name: 'financialProfile',
//   meta: { title: '财务管理', icon: 'example',noCache: false },
//   children: [
//     {
//       path: 'financialProfile',
//       name: 'financialProfile',
//       component: () => import('@/views/financial/financialProfile/index'),
//       meta: { title: '财务概况' }
//     },
//     {
//       path: 'billDetails',
//       name: 'billDetails',
//       component: () => import('@/views/financial/billDetail/index'),
//       meta: { title: '账单明细' }
//     },
//     {
//       path: 'receiveSettlement',
//       name: 'receiveSettlement',
//       component: () => import('@/views/financial/receiveSettlement/index'),
//       meta: { title: '应收结算' }
//     },
//     {
//       path: 'createSettlement',
//       name: 'createSettlement',
//       component: Layout,
//       hidden: true,
//       component: () => import('@/views/financial/receiveSettlement/createSettlement'),
//       meta: { title: '新建应收结算单' }
//     },
//     {
//       path: 'receiveDetail',
//       name: 'receiveDetail',
//       hidden: true,
//       component: () => import('@/views/financial/receiveSettlement/detail'),
//       meta: { title: '应收结算单详情' }
//     },
//     {
//       path: 'paySettlement',
//       name: 'paySettlement',
//       component: () => import('@/views/financial/paySettlement/index'),
//       meta: { title: '应付结算' }
//     },
//     {
//       path: 'createPaySettlement',
//       name: 'createPaySettlement',
//       component: Layout,
//       hidden: true,
//       component: () => import('@/views/financial/paySettlement/createSettlement'),
//       meta: { title: '新建应付结算单' }
//     },
//     {
//       path: 'receivePayDetail',
//       name: 'receivePayDetail',
//       hidden: true,
//       component: () => import('@/views/financial/paySettlement/detail'),
//       meta: { title: '应付结算单详情' }
//     },
//     {
//       path: 'rewardPayment',
//       name: 'rewardPayment',
//       component: () => import('@/views/financial/rewardPayment/index'),
//       meta: { title: '报酬发放' }
//     },
//     {
//       path: 'rewardPaymentDetail',
//       name: 'rewardPaymentDetail',
//       hidden: true,
//       component: () => import('@/views/financial/rewardPayment/detail'),
//       meta: { title: '报酬发放详情' }
//     },
//     {
//       path: 'createRewardSettlement',
//       name: 'createRewardSettlement',
//       hidden: true,
//       component: () => import('@/views/financial/rewardPayment/createSettlement'),
//       meta: { title: '新建报酬发放单' }
//     },
//     {
//       path: 'invoiceManage',
//       name: 'invoiceManage',
//       component: () => import('@/views/financial/invoiceManage/index'),
//       meta: { title: '发票管理' }
//     },
//     {
//       path: 'invoiceManageDetail',
//       name: 'invoiceManageDetail',
//       hidden: true,
//       component: () => import('@/views/financial/invoiceManage/detail'),
//       meta: { title: '发票详情' }
//     },
//     {
//       path: 'financialSetup',
//       name: 'financialSetup',
//       component: () => import('@/views/financial/financialSetup/index'),
//       meta: { title: '财务设置' }
//     },
//     {
//       path: 'collectFees',
//       name: 'collectFees',
//       component: () => import('@/views/financial/collectFees/index'),
//       meta: { title: '仲裁收费' }
//     },
//     {
//       path: 'arbitrationRefund',
//       name: 'arbitrationRefund',
//       component: () => import('@/views/financial/arbitrationRefund/index'),
//       meta: { title: '仲裁退费' }
//     },
//     ]
// },
// {
//   path: '/trialSchedule',
//   component: Layout,
//   redirect: '/trialSchedule/courtyardSchedule',
//   name: 'trialSchedule',
//   meta: { title: '审理排期', icon: 'example' },
//   children: [
//     {
//       path: 'courtyardSchedule',
//       name: 'courtyardSchedule',
//       component: () => import('@/views/trialSchedule/courtyardSchedule/index'),
//       meta: { title: '组庭排期' }
//     },
//     {
//       path: 'courtSchedule',
//       name: 'courtSchedule',
//       component: () => import('@/views/trialSchedule/courtSchedule/index'),
//       meta: { title: '开庭排期' }
//     },
//     {
//       path: 'adjudicationSchedule',
//       name: 'adjudicationSchedule',
//       component: () => import('@/views/trialSchedule/adjudicationSchedule/index'),
//       meta: { title: '裁决排期' }
//     },
//     ]
// },
// {
//   path: '/system',
//   component: Layout,
//   redirect: '/system/menu',
//   name: 'system',
//   meta: {title: '系统管理', icon: 'example'},
//   children: [
//     {
//       path: 'menu',
//       name: 'menu',
//       component: () => import('@/views/system/menu/index'),
//       meta: {title: '菜单管理', icon: 'tree'}
//     },
//     {
//       path: 'role',
//       name: 'role',
//       component: () => import('@/views/system/role/index'),
//       meta: {title: '角色管理', icon: 'table'}
//     },
//     {
//       path: 'process',
//       name: 'process',
//       component: () => import('@/views/system/process/index'),
//       meta: {title: '流程管理', icon: 'tree'}
//     },
//     {
//       path: 'addprocess/:id',
//       name: 'addprocess',
//       hidden: true,
//       component: () => import('@/views/system/process/addProcess'),
//       meta: {title: '流程管理/添加流程管理', icon: 'tree'}
//     },
//   ]
// },
// {
//   path: '/configure',
//   component: Layout,
//   redirect: '/configure/arbitratorMaintenance',
//   name: 'configure',
//   meta: {title: '配置管理', icon: 'example'},
//   children: [
//     {
//       path: 'arbitratorMaintenance',
//       name: 'arbitratorMaintenance',
//       component: () => import('@/views/configure/arbitratorMaintenance/index'),
//       meta: {title: '仲裁员维护'}
//     },
//     {
//       path: 'documentManagement',
//       name: 'documentManagement',
//       component: () => import('@/views/configure/documentManagement/index'),
//       meta: {title: '文书管理'}
//     },
//     {
//       path: 'serviceSettings',
//       name: 'serviceSettings',
//       component: () => import('@/views/configure/serviceSettings/index'),
//       meta: {title: '送达设置'}
//     },
//     {
//       path: 'organManage',
//       name: 'organManage',
//       component: () => import('@/views/configure/organManage/index'),
//       meta: {title: '仲裁机构管理'}
//     },
//     {
//       path: 'arbitratorFree',
//       name: 'arbitratorFree',
//       component: () => import('@/views/configure/arbitratorFree/index'),
//       meta: {title: '仲裁费设置'}
//     },
//     {
//       path: 'timingTask',
//       name: 'timingTask',
//       component: () => import('@/views/configure/timingTask/index'),
//       meta: {title: '定时任务管理'}
//     },
//     {
//       path: 'ipListManage',
//       name: 'ipListManage',
//       component: () => import('@/views/configure/ipListManage/index'),
//       meta: {title: 'IP黑/白名单管理'}
//     },
//     {
//       path: 'operationLog',
//       name: 'operationLog',
//       component: () => import('@/views/configure/operationLog/index'),
//       meta: {title: '操作日志'}
//     },
//     {
//       path: 'exerciseRightSetting',
//       name: 'exerciseRightSetting',
//       component: () => import('@/views/configure/exerciseRightSetting/index'),
//       meta: {title: '行使权利设置'}
//     },
//     {
//       path: 'exerciseRightSettingDetail/:id',
//       name: 'exerciseRightSettingDetail',
//       hidden: true,
//       component: () => import('@/views/configure/exerciseRightSetting/detail'),
//       meta: {title: '权利行使设置详情'}
//     },
// {
//       path: 'courtVedioSet',
//       name: 'courtVedioSet',
//       component: () => import('@/views/configure/courtVedioSet/index'),
//       meta: {title: '视频庭审设置'}
//     },
//   ]
// },
// 404 page must be placed at the end !!!
// { path: '*', redirect: '/404', hidden: true }
// -----------------------仲裁委-----------------------
// {
//   path: '/commission',
//   component: Layout,
//   redirect: '/commission/comInquiry',
//   name: 'commission',
//   meta: {title: '仲裁委待办', icon: 'example', noCache: false},
//   children: [
// {
//   path: 'comInquiry',
//   name: 'comInquiry',
//   component: () => import('@/views/commission/comInquiry/index'),
//   meta: {title: '案件查询', noCache: false}
// },
// {
//   path: 'courtManage',
//   name: 'courtManage',
//   component: () => import('@/views/commission/courtManage/index'),
//   meta: {title: '开庭设置', noCache: false}
// },
// {
//   path: 'selectArb',
//   name: 'selectArb',
//   component: () => import('@/views/commission/selectArb/index'),
//   meta: {title: '选定仲裁员', noCache: false}
// },
// {
//   path: 'transfer',
//   name: 'transfer',
//   component: () => import('@/views/commission/transfer/index'),
//   meta: {title: '案件转移', noCache: false}
// },
// {
//   path: 'user',
//   name: 'user',
//   component: () => import('@/views/commission/user/index'),
//   meta: {title: '人员管理', noCache: false}
// },
//   {
//     path: 'allot',
//     name: 'allot',
//     component: () => import('@/views/commission/allot/index'),
//     meta: {title: '自动分配设置', noCache: false}
//   },
//   {
//     path: 'allotSec',
//     name: 'allotSec',
//     component: () => import('@/views/commission/allot/allotSec'),
//     meta: {title: '分配秘书', noCache: false}
//   },
// ]
// },
// -----------------------仲裁员-----------------------
// {
//   path: '/arbitrator',
//   component: Layout,
//   redirect: '/arbitrator/arbInquiry',
//   name: 'arbitrator',
//   meta: {title: '仲裁员待办', icon: 'example', noCache: false},
//   children: [
//     {
//       path: 'arbInquiry',
//       name: 'arbInquiry',
//       component: () => import('@/views/arbitrator/arbInquiry/index'),
//       meta: {title: '案件查询', noCache: false}
//     },
//     {
//       path: 'upcoming',
//       name: 'upcoming',
//       component: () => import('@/views/arbitrator/upcoming/index'),
//       meta: {title: '裁决审核', noCache: false}
//     },
//     {
//       path: 'deploy',
//       name: 'deploy',
//       component: () => import('@/views/arbitrator/deploy/index'),
//       meta: {title: '仲裁员配置', noCache: false}
//     },
//   ]
// },
]; // 之前代码

exports.constantRoutes = constantRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;