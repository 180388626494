"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _dictionary = require("@/config/dictionary");

var _addAdmin = _interopRequireDefault(require("./addAdmin"));

var _updateAdmin = _interopRequireDefault(require("./updateAdmin"));

var _addRole = _interopRequireDefault(require("./addRole"));

var _updateRole = _interopRequireDefault(require("./updateRole"));

var _role = require("@/api/role");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "",
  components: {
    AddAdmin: _addAdmin.default,
    UpdateAdmin: _updateAdmin.default,
    AddRole: _addRole.default,
    UpdateRole: _updateRole.default
  },
  data: function data() {
    return {
      loading: false,
      roleList: [],
      page: 1,
      pageSize: 10,
      total: 0,
      adminList: [],
      selectedData: [],
      selectedForm: [],
      state: _dictionary.dictionary.adminState,
      addAdminVisible: false,
      updateAdminVisible: false,
      addRoleVisible: false,
      updateRoleVisible: false,
      changeRoleId: ''
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getRoleList();
    this.getAdminList(null);
  },
  methods: {
    getRoleList: function getRoleList() {
      var _this = this;

      this.loading = true;
      (0, _role.getRoleList)().then(function (res) {
        _this.loading = false;
        _this.roleList = res.data;
      }).catch(function (error) {
        _this.loading = false;
      });
    },
    selectAdmin: function selectAdmin(param, ev) {
      this.changeRoleId = param;
      ev.preventDefault();
      this.getAdminList(param);
    },
    handleAddRole: function handleAddRole() {
      var _this2 = this;

      this.addRoleVisible = true;
      this.$nextTick(function () {
        _this2.$refs.addRole.init();
      });
    },
    handleUpdateRole: function handleUpdateRole(id, name, ev) {
      var _this3 = this;

      ev.preventDefault();
      this.updateRoleVisible = true;
      this.$nextTick(function () {
        _this3.$refs.updateRole.init(id, name);
      });
    },
    handleDeleteRole: function handleDeleteRole(id) {
      var _this4 = this;

      this.$confirm('确定删除选中的用户？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this4.loading = true;
        (0, _role.deleteRole)(id).then(function (res) {
          _this4.loading = false;

          if (res.code === 1) {
            _this4.$message({
              message: '删除成功！',
              type: 'success'
            });

            _this4.getRoleList();
          } else {
            _this4.$message.error(res.msg);
          }
        }).catch(function (error) {
          _this4.loading = false;
        });
      });
    },
    getAdminList: function getAdminList(roleId) {
      var _this5 = this;

      var data = {
        page: this.page,
        pageSize: this.pageSize,
        roleId: roleId
      };
      this.loading = true;
      (0, _role.getAdminList)(data).then(function (res) {
        _this5.loading = false;
        _this5.adminList = res.rows;
        _this5.total = res.total;
      }).catch(function (error) {
        _this5.loading = false;
      });
    },
    handleCurrentChange: function handleCurrentChange(page) {
      this.page = page;
      this.getAdminList(this.changeRoleId);
    },
    handleSizeChange: function handleSizeChange(size) {
      this.pageSize = size;
      this.getAdminList(this.changeRoleId);
    },
    handleAddAdmin: function handleAddAdmin() {
      var _this6 = this;

      this.addAdminVisible = true;
      this.$nextTick(function () {
        _this6.$refs.addAdmin.init();
      });
    },
    handleUpdateAdmin: function handleUpdateAdmin(param) {
      var _this7 = this;

      this.updateAdminVisible = true;
      this.$nextTick(function () {
        _this7.$refs.updateAdmin.init(param);
      });
    },
    handleSelectionChange: function handleSelectionChange(value) {
      this.selectedData = value;
    },
    handleDeleteAdmin: function handleDeleteAdmin() {
      var _this8 = this;

      this.$confirm('确定删除选中的用户？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this8.selectedData.forEach(function (item, index) {
          _this8.selectedForm.push(item.id);
        });

        _this8.loading = true;
        (0, _role.deleteAdmin)({
          ids: _this8.selectedForm
        }).then(function (res) {
          _this8.loading = false;

          if (res.code === 1) {
            _this8.$message({
              message: '删除成功！',
              type: 'success'
            });

            _this8.getAdminList(_this8.changeRoleId || null);
          } else {
            _this8.$message.error(res.msg);
          }

          _this8.selectedForm = [];
        }).catch(function (error) {
          _this8.loading = false;
        });
      });
    }
  }
};
exports.default = _default;