"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _inquiry = require("@/api/inquiry.js");

var _dictionary = require("@/config/dictionary");

var _send = _interopRequireDefault(require("../../case/service/send"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "msend",
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      loading: true,
      processVisible: false,
      upcomingVisible: false,
      loadingProcess: false,
      loadingSave: false,
      closeCaseVisible: false,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      tableData: [],
      total: 0,
      //总页数
      caseState: _dictionary.dictionary.repeatStats,
      caseId: "",
      allLoading: false,
      //全屏loading
      formFilter: {
        caseId: "",
        caseNo: ""
      },
      nextStatus: 0,
      optionArr: [],
      processNextId: "",
      dataContentForm: {
        title: "",
        subject: "",
        caseNo: ""
      },
      dataContent: [],
      selectCurRow: null,
      ossFile: {
        title: "",
        subject: "",
        aliyunOssHref: ""
      }
    };
  },
  mounted: function mounted() {
    this.init();
  },
  components: {
    SendCase: _send.default
  },
  methods: {
    init: function init() {
      var _this = this;

      var params = this.formFilter;
      params = Object.assign(params, {
        limit: this.pageLimit,
        page: this.currentPage
      });
      (0, _inquiry.getCaseCorrectionContentPage)(params).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: "inquiryDetail",
        params: {
          caseId: val
        }
      });
    },
    onloadProcessMan: function onloadProcessMan() {
      var _this2 = this;

      (0, _inquiry.getCaseCorrectionContentNextProcess)(this.caseId).then(function (res) {
        if (res.code == 1) {
          var data = res.data.nextProcess;
          _this2.optionArr = data;
          _this2.nextStatus = res.data.nextStatus; // this.processVisible=true;

          _this2.handleProcess();
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.currentPage = 1;
      this.init();
    },
    onFilter: function onFilter() {
      this.init();
    },
    onOpenNextProcce: function onOpenNextProcce(data) {
      if (data.status != 1) {
        this.$message({
          message: "已经指派了审批人!",
          type: "warning"
        });
        return;
      }

      this.caseId = data.caseId;
      this.processVisible = true;
      this.onloadProcessMan();
    },
    //补正书送达
    handleSend: function handleSend(data) {
      var _this3 = this;

      this.caseId = data.caseId;
      this.$nextTick(function () {
        _this3.$refs.sendCase.init(_this3.caseId, 99, data);
      });
    },
    //补正书编辑
    handleEdit: function handleEdit(data) {
      var _this4 = this;

      this.caseId = data.caseId;
      this.selectCurRow = data;
      this.upcomingVisible = true;
      (0, _inquiry.getCaseCorrectionContent)(data.caseId).then(function (res) {
        var rest = res.data || {};
        var content = rest.content || "{}";
        var contentJSON = JSON.parse(content);
        _this4.dataContent = contentJSON.content;
        _this4.dataContentForm.title = rest.title;
        _this4.dataContentForm.subject = rest.subject;
        _this4.dataContentForm.caseNo = rest.caseNo;
        _this4.selectCurRow.reviewId = rest.reviewId;
        _this4.selectCurRow.reviewName = rest.reviewName;
      });
    },
    handlPDF: function handlPDF(data) {
      this.ossFile = data;
      this.closeCaseVisible = true;
    },
    updateShow: function updateShow() {
      this.allLoading = false;
    },
    updateErrorShow: function updateErrorShow() {
      this.allLoading = false;
    },
    // 审核通过后刷新数据
    refreshPage: function refreshPage() {
      this.allLoading = false;
      this.sendCaseVisible = false;
      this.init();
    },
    handleProcess: function handleProcess() {
      var _this5 = this;

      // if (!this.processNextId) {
      //   this.$message({
      //     message: "请选择审批人员!",
      //     type: "warning"
      //   });
      //   return;
      // }
      // var obj = this.optionArr.find(item => {
      //   return item.reviewId == this.processNextId;
      // });
      var item = this.optionArr[0];
      var params = {
        caseId: this.caseId,
        status: this.nextStatus,
        reviewId: item.processNextId,
        reviewName: item.reviewName
      };
      (0, _inquiry.caseCorrectionContentAuit)(params).then(function (res) {
        if (res.code == 1) {
          _this5.$message({
            message: "操作成功!",
            type: "success"
          });

          _this5.processVisible = false;
          _this5.processNextId = "";
          _this5.nextStatus = 0;
          _this5.optionArr = [];

          _this5.refreshPage();
        }
      });
    },
    // 分页
    handleSizeChange: function handleSizeChange(val) {
      this.pageLimit = val;
      this.init();
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
      this.init();
    },
    handleSave: function handleSave() {
      var _this6 = this;

      this.loadingSave = true;
      this.caseId = this.selectCurRow.caseId;
      var parmas = {
        caseId: this.selectCurRow.caseId,
        content: JSON.stringify({
          content: this.dataContent
        }),
        reviewId: this.selectCurRow.reviewId,
        reviewName: this.selectCurRow.reviewName
      };
      (0, _inquiry.saveCaseCorrectionContent)(parmas).then(function (res) {
        _this6.loadingSave = false;

        if (res.code == "1") {
          _this6.$message({
            message: "操作成功!",
            type: "success"
          });

          _this6.upcomingVisible = false; // this.processVisible = true;
          // this.init();
          // this.onloadProcessMan();
        } else {
          _this6.$message({
            message: "操作失败!",
            type: "warning"
          });
        }
      });
    },
    handleAdd: function handleAdd(val) {
      this.dataContent.splice(val + 1, 0, {
        text: ""
      });
    },
    handleDelete: function handleDelete(val) {
      if (this.dataContent.length == 0) {
        this.$message.error("判决书修正信息已为空");
      } else {
        this.dataContent.splice(val, 1);
      }
    }
  }
};
exports.default = _default;