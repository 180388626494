"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ifShowImg = ifShowImg;

function ifShowImg(val) {
  var index = val.lastIndexOf(".");
  var ext = val.substr(index + 1);

  if (ext !== "pdf") {
    return true;
  } else {
    return false;
  }
}