"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.editPassword = editPassword;
exports.getInfo = getInfo;
exports.getMenu = getMenu;
exports.getPicture = getPicture;
exports.login = login;
exports.loginByPhone = loginByPhone;
exports.logout = logout;
exports.sendMess = sendMess;
exports.uploadImg = uploadImg;

var _request = _interopRequireDefault(require("@/utils/request"));

function login(data) {
  return (0, _request.default)({
    url: '/back/login',
    method: 'post',
    data: data
  });
}

function getMenu(params) {
  return (0, _request.default)({
    url: '/back/menu/listMenu',
    method: 'get',
    params: params
  });
}

function getInfo(token) {
  return (0, _request.default)({
    url: '/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}

function logout() {
  return (0, _request.default)({
    url: '/user/logout',
    method: 'post'
  });
}

function editPassword(data) {
  return (0, _request.default)({
    url: '/back/admin/modifyPwd',
    method: 'post',
    data: data
  });
} //获取图片验证码


function getPicture(params) {
  return (0, _request.default)({
    url: '/back/captcha',
    method: 'get',
    responseType: 'arraybuffer',
    params: params
  });
} //发送短信验证码


function sendMess(data) {
  return (0, _request.default)({
    url: '/back/sms/send',
    method: 'post',
    data: data
  });
} //手机号登录


function loginByPhone(data) {
  return (0, _request.default)({
    url: '/back/sms/login',
    method: 'post',
    data: data
  });
} //文件上传


function uploadImg() {
  return '/litigant/fileUpload/uploadImg';
}