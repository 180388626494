"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getList } from '@/api/table'
// import { dictionary } from '@/config/dictionary'
var _default = {
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      list: null,
      listLoading: true,
      type1: dictionary.type
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;

      this.listLoading = true;
      getList().then(function (response) {
        _this.list = response.data.items;
        _this.listLoading = false;
      });
    }
  }
};
exports.default = _default;