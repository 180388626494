export const dictionary = {
  method: ['POST', 'DELETE', 'PUT', 'GET'],
  adminState: {
    '0': '关闭',
    '1': '开启',
    '2': '临时挂号',
  },
  caseState: {
    '0': '草稿',
    '1': '立案审核',
    '12': '办案审核送达',
    '13': '待补充材料',
    '14': "待缴费送达",
    '15': "待仲裁委缴费确认",
    '2': '待组庭',
    '21': "组庭审核",
    '22': "指定仲裁员",
    '23': "仲裁员签名",
    '24': "组庭送达",
    '3': '待开庭',
    '31': '开庭审核',
    '4': '仲裁员裁决',
    '41': '秘书裁决审核',
    '42': '核阅审核',
    '43': '仲裁委签发',
    '44': "事务部审核",
    '45': "审核核阅",
    '46': "秘书长审核",
    '5': '待归档',
    '6': '归档',
    '10':'处理中',
    '-1': '撤案',
    '-2': '已退回',
    '-3': '已拒绝',
    '-4': '重复案件',
    '-11': '申请撤案',
    '71': '仲裁庭调查取证',
    '72': '其他审核2',
    '73': '其他审核3',
    '74': '其他审核4',
    '75': '其他审核5',
    '76': '其他审核6',
    '77': '其他审核7',
    '78': '其他审核8',
    '79': '其他审核9',
  },
  caseStateRouter: {
      '1': 'register',
      '12': 'registerSend',
      '13': 'proofExam',
      '14': "casePay",
      '15': "caseReview",
      '2': 'applyCompse',
      '21': "examCompose",
      '22': "selectArb",
      '23': "arbitratorSign",
      '24': "sendCompose",
      '3': 'courtSend',
      '31': 'courtSend',
      '4': 'bookMark',
      '41': 'ruleSend',
      '42': 'bookExam',
      '43': 'bookExam',
      '44': "bookExam",
      '45': "bookExam",
      '46': "bookExam",
      '5': 'onfile',
      '6': 'onfile',
      '10':'inquiry',
      '-1': 'inquiry',
      '-2': 'inquiry',
      '-3': 'inquiry',
      '-4': 'inquiry',
      '71': 'obtainEvident',
      '72': 'inquiry',
      '73': 'inquiry',
      '74': 'inquiry',
      '75': 'inquiry',
      '76': 'inquiry',
      '77': 'inquiry',
      '78': 'inquiry',
      '79': 'inquiry',
    },
  caseDealing:{
     '0': '处理完成',
     '1': '处理中'
  },
  identiyState:{
    '0':'未识别',
    '1':'处理中',
    '-1':'识别不通过',
    '2':'识别通过',
    '3':'不用识别',
  },
  caseExamineState:{
    '0':'待处理',
    '1':'处理通过',
    '-1':'拒绝',
    '2':'无需处理'
  },
  caseExamineType:{
    '1':'选择仲裁员',
    '2':'变更仲裁请求',
    '3':'撤案申请',
    '4':'延期申请',
    '5':'仲裁员回避',
    '6':'秘书回避',
    '7':'反请求',
    '8':'保全申请',
    '9':'减缓申请',
    '10':'调解申请',
    '11':'答辩',
    '12':'质证',
    '13':'鉴定申请',
    '14':'补充材料'
  },

  partyType: {
    '0': '企业',
    '1': '个人',
  },
  partyIdType:{
    '1':'申请人',
    '2':'被申请人',
    '3':'代理人',
    '4':'申请人代理人',
    '5':'被申请人代理人',
    '6':'独任仲裁员',
    '7':'书记员'
  },
  card:{
    '0':'其他证件',
    '1':'统一信用代码',
    '2':'组织证书',
    '3':'身份证',
    '4':'军官证',
    '5':'律师证'
  },
  sex:{
    '0':'男',
    '1':'女'
  },
  materialState: {
    '0': '未审核',
    '1': '审核通过',
    '2': '审核不通过',
  },
  fileType: {
    '0': '案件材料',
    '1': '身份证明',
    '2': '借款关系',
    '3': '抵/质押关系',
    '4': '履约证明',
    '5': '违约证明',
    '6': '其他',
  },
  caseBookTemplateType:{
    '0':'缴费通知书',
    '1':'立案通知书',
    '2':'应仲裁通知书',
    '3':'组庭通知书',
    '4':'组庭通知书-双方共同指定仲裁员',
    '5':'开庭通知书',
    '6':'撤案-未组庭',
    '7':'撤案-已组庭',
    '8':'调解书',
    '9':'仲裁员声明书',
    '10':'裁决模板-未还款',
    '11':'裁决模板-有还款',
    '12':'裁决生效证明'
  },
  caseSendType:{
    '1':'应仲裁通知',
    '2':'组庭通知',
    '3':'开庭通知',
    '4':'撤案通知',
    '5':'裁决通知',
    '6':'仲裁庭取证通知',
    '7': "仲裁行使权利通知",
    '8': "不予立案通知",
    '9': "缴费通知书送达"
  },

  evidenceStatus:{
    '0':'未上传证据',
    '1':'待发送',
    '2':'送达中',
    '3':'失败预警通知成功',
    '4':'送达成功',
    '5':'已重发',
    '-2':'送达失败预警通知失败',
    '-1':'送达失败',
  },
  sendMsgType: {
    '0': "其他类型",
    '1': "立案-被申请人短信",
    '2': "立案-被申请人邮件",
    '3': "立案-申请人邮件",
    '4': "裁决-被申请人短信",
    '5': "裁决-被申请人邮件",
    '6': "裁决-申请人邮件",
    '7': "立案-申请人和被申请人邮件",
    '8': "裁决-申请人和被申请人邮件",
    '9': "补交证据材料-被申请人短信",
    '10': "补交证据材料-申请人短信",
    "11": "组庭-被申请人邮件",
    "12": "组庭-被申请人短信",
    "13": "组庭-申请人邮件",
    '14': "开庭-申请人邮件",
    "15": "开庭-被申请人邮件",
    "16": "开庭-申请人短信",
    "17": "开庭-被申请人短信",
    '18': "批量-被申请人邮件",
    '19': "撤回-申请人邮件",
    '20': "撤回-被申请人邮件",
    '21': "撤回-被申请人短信",
    '22': "撤回-申请人和被申请人邮件",
    '23': "撤回-申请人和被申请人短信",
    '24': "管辖权异议-发起人短信",
    '25': "特殊申请—发起人短信",
    '26': "延期-发起人短信",
    '27': "撤案-发起人短信",
    '28': "调解-发起人短信",
    "29": "仲裁员回避-双方短信",
    "30": "财产保全-发起人短信",
    "31": "变更仲裁-发起人短信",
    '32': "反请求-发起人短信",
    "33": "管辖权异议-双方邮件",
    "34": "管辖权异议-双方短信",
    "35": "仲裁员回避-双方邮件",
    "36": "缴费-案件申请人短信",
    '37': "二次开庭-开庭文书",
    "38": "立案-申请人短信",
    "39": "组庭-申请人短信",
    "40": "裁决-申请人短信",
    "41": "撤回-申请人短信",
  },
  sendMsgState: {
    '0': "失败",
    '1': "已重发",
    '4': "成功"
  },
  receiptState:{
    '0': '回执处理中',
    '1': '回执成功',
    '2':'无回执',
    '-1': '回执失败'
  },
  channelType:{
    1: '亿联',
    2: '小鱼'
  },
  channelState:{
    '0': '空闲',
    '1': '占用',
    '-1': '停用'
  },
  videoState:{
      '0': '庭审排期中',
      '1': '庭审进行中',
      '2': '庭审已结束',
      '-1': '庭审取消',
      '-2': '庭审延期'
    },
    rejectAudit:{
      1:'有 ',
      2:'无'
    },
    //1.待指定 2.待审核 3.待送达 4.已送达 5.审核拒绝
    repeatStats:{
      1:'待指定',
      2:'待审核',
      3:'待送达',
      4:'已送达',
      5:'审核拒绝'
    },
    //0申请 1待审核 2待送达 3已送达 4.审核拒绝 -1秘书拒绝
    caseRevokeStats:{
      0:'申请',
      1:'待审核',
      2:'待送达',
      3:'已送达',
      4:'审核拒绝',
      '-1':'秘书拒绝'
    }
}
