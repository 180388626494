"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _commission = require("@/api/commission.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'handing',
  data: function data() {
    return {
      tableHeaderColor: this.$tableHeaderColor,
      form: {
        delivery: true
      },
      secList: [],
      loading: true,
      tableData: [],
      addVisble: false,
      addForm: {
        userId: ''
      },
      auditorList: {},
      registFrom: {
        reviewId: '',
        reviewName: ''
      },
      rules: {
        name: [{
          required: true,
          message: '请输入工作流名称',
          trigger: 'blur'
        }],
        region: [{
          required: true,
          message: '请选择仲裁委',
          trigger: 'change'
        }],
        status: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }]
      },
      addRules: {
        userId: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }]
      },
      secretaryList: []
    };
  },
  created: function created() {
    this.init();
  },
  components: {},
  methods: {
    handleSave: function handleSave() {},
    init: function init() {
      var _this = this;

      this.loading = true;
      (0, _commission.findStaffAllotList)({
        adminType: '50'
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.data;
          _this.loading = false;
        }
      });
    },
    handleAdd: function handleAdd() {
      var _this2 = this;

      this.addVisble = true;
      (0, _commission.findAssignableSecretaryList)().then(function (res) {
        if (res.code == 1) {
          _this2.secretaryList = res.data;
        }
      });
    },
    handleAddSubmit: function handleAddSubmit(addform) {
      var _this3 = this;

      this.$refs[addform].validate(function (valid) {
        if (valid) {
          (0, _commission.addStaffAllot)({
            userId: _this3.addForm.userId
          }).then(function (res) {
            if (res.code == 1) {
              _this3.$message({
                message: '添加成功',
                type: 'success'
              });

              _this3.addVisble = false;

              _this3.init();
            } else {
              _this3.$message.error(res.msg);
            }
          });
        }
      });
    },
    handleDelete: function handleDelete(data) {
      var _this4 = this;

      this.$confirm('确定删除此秘书？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _commission.deleteStaffAllot)(data.userId).then(function (res) {
          if (res.code == 1) {
            _this4.$message({
              message: '删除成功',
              type: 'success'
            });

            _this4.init();
          } else {
            _this4.$message.error(res.msg);
          }
        });
      });
    },
    saveRatio: function saveRatio(data) {
      var _this5 = this;

      (0, _commission.updateStaffAllot)({
        userId: data.userId,
        ratio: data.ratio
      }).then(function (res) {
        if (res.code == 1) {
          _this5.$message({
            message: '保存成功',
            type: 'success'
          });
        } else {
          _this5.$message.error(res.msg);
        }
      });
    } //保存批量分配比例
    // handleSave(){
    //   let params = [];
    //   let total = 0;
    //   this.tableData.forEach(function (value, key) {
    //       params.push({'userId': value.userId, 'ratio': value.ratio});
    //       total = total + parseFloat(value.ratio);
    //   });
    //   if (total == 100.00) {
    //       updateStaffAllot(params).then((res)=>{
    //                   if(res.code==1){
    //                       this.$message({    message: '保存成功',    type: 'success'});
    //                   }else {
    //                       this.$message.error(res.msg);
    //                   }
    //               })
    //   } else {
    //       this.$message.error('分配比例总和必须为100');
    //   }
    // },

  }
};
exports.default = _default;