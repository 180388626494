"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isExternal = isExternal;
exports.isLandlineOrPhone = isLandlineOrPhone;
exports.isvalidPhone = isvalidPhone;
exports.validPhone = validPhone;
exports.validUsername = validUsername;
exports.validatenull = validatenull;

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */


function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
} // 验证手机号码


function isvalidPhone(str) {
  var reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
  return reg.test(str);
} //验证手机或者是座机


function isLandlineOrPhone(val) {
  var isMobilePhone = /^([0-9]{3,4}-)?[0-9]{7,8}$/; // var isFixMob= /^0?1[3|4|5|8][0-9]\d{8}$/;

  var isFixMob = /^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/;

  if (isFixMob.test(val) || isMobilePhone.test(val)) {
    return true;
  } else {
    return false;
  }
} // 验证表单号码  (手机号码或者座机号码)


function validPhone(rule, value, callback) {
  var isMobilePhone = /^1[3|4|5|7|8][0-9]\d{8}$/;
  var isFixMob = /^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/;
  var isPhone1 = isFixMob.test(value);
  var isPhone2 = isMobilePhone.test(value);

  if (!value) {
    callback(new Error('请输入电话号码'));
  } else {
    if (isPhone1 || isPhone2) {
      callback();
    } else {
      callback(new Error("请输入正确的手机号码或者固定座机号码"));
    }
  }
}
/**
 * 判断是否为空
 */


function validatenull(val) {
  if (val instanceof Array) {
    if (val.length === 0) {
      return true;
    }
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') {
      return true;
    }
  } else {
    if (val === 'null' || val === null || val === 'undefined' || val === undefined || val === '') {
      return true;
    }

    return false;
  }

  return false;
}