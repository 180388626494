"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRevokeSendFile = createRevokeSendFile;
exports.getFindRevokeCasePage = getFindRevokeCasePage;
exports.onCaseRevokeAudit = onCaseRevokeAudit;
exports.onCaseRevokeRefuse = onCaseRevokeRefuse;
exports.onCaseRevokeSave = onCaseRevokeSave;
exports.onCaseRevokeSaveFile = onCaseRevokeSaveFile;
exports.onCaseRevokeSend = onCaseRevokeSend;
exports.onFindNextProcess = onFindNextProcess;
exports.onFindRevokeInfo = onFindRevokeInfo;

var _request = _interopRequireDefault(require("@/utils/request"));

//获取撤案概况接口
function onFindRevokeInfo(caseId) {
  return (0, _request.default)({
    url: '/batchCase/caseRevoke/findRevokeInfo/' + caseId,
    method: 'get'
  });
}

; //获取撤案决定书文件接口

function createRevokeSendFile(data) {
  return (0, _request.default)({
    url: '/batchCase/caseFileInstrument/createRevokeSendFile/' + data,
    method: 'get'
  });
} //保存测回决定书接口


function onCaseRevokeSave(data) {
  return (0, _request.default)({
    url: '/batchCase/caseRevoke/save',
    method: 'post',
    data: data
  });
}

; //决定书文件上传接口

function onCaseRevokeSaveFile(data) {
  return (0, _request.default)({
    url: '/batchCase/caseRevoke/saveFile',
    method: 'post',
    data: data
  });
}

; //修改撤案接口

function onCaseRevokeRefuse(data) {
  return (0, _request.default)({
    url: '/batchCase/caseRevoke/refuse',
    method: 'post',
    data: data
  });
}

; //列表搜索接口

function getFindRevokeCasePage(data) {
  return (0, _request.default)({
    url: '/batchCase/caseRevoke/findRevokeCasePage',
    method: 'post',
    data: data
  });
}

; //审核决定书接口

function onCaseRevokeAudit(data) {
  return (0, _request.default)({
    url: '/batchCase/caseRevoke/audit',
    method: 'post',
    data: data
  });
}

; //获取下一级审核人接口

function onFindNextProcess(id) {
  return (0, _request.default)({
    url: '/batchCase/caseRevoke/findNextProcess/' + id,
    method: 'get'
  });
}

; //送达接口

function onCaseRevokeSend(data) {
  return (0, _request.default)({
    url: '/batchCase/caseRevoke/send',
    method: 'post',
    data: data
  });
}

;