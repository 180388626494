"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

var _auth = require("@/utils/auth");

var _arbitrator = require("@/api/arbitrator");

var _security = require("@/utils/security");

var _encryptKey = require("@/config/encryptKey");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'handing',
  data: function data() {
    return {
      loading: false,
      deployVisible: false,
      deployForm: {},
      submitData: {},
      uploadUrl: process.env.VUE_APP_BASE_API + (0, _arbitrator.uploadFile)(),
      uploadRule: {},
      accept: '.jpg,.png',
      headers: {
        'Authorization': (0, _auth.getToken)()
      }
    };
  },
  created: function created() {
    this.init();
  },
  components: {
    Caseconditons: _index.default
  },
  methods: {
    init: function init() {
      var _this = this;

      (0, _arbitrator.findAdminInfo)().then(function (res) {
        if (res.code == 1) {
          _this.deployForm = res.data;
          _this.submitData.noticeFile = res.data.noticeFile;
          _this.submitData.img = res.data.img;
        }
      });
    },
    handleNoticeSuccess: function handleNoticeSuccess(res1, file) {
      var res = JSON.parse((0, _security.decryptByAES)(res1.response, _encryptKey.encryptKey.key));

      if (res.code == 1) {
        this.deployForm.noticeOssHref = res.data.aliyunOssHref;
        this.submitData.noticeFile = res.data.filePath;
      } else {
        location.reload();
      }
    },
    handleImgSuccess: function handleImgSuccess(res1, file) {
      var res = JSON.parse((0, _security.decryptByAES)(res1.response, _encryptKey.encryptKey.key));

      if (res.code == 1) {
        this.deployForm.imgOssHref = res.data.aliyunOssHref;
        this.submitData.img = res.data.filePath;
      } else {
        location.reload();
      }
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var accept = 'image/png,image/jpg,image/jpeg,image/bmp';
      var isJPG = accept.indexOf(file.type);
      var isLt2M = file.size / 1024 / 1024 < 5;

      if (isJPG == -1) {
        this.$message.error('上传附件支持图片格式');
      }

      if (!isLt2M) {
        this.$message.error('上传附件大小不能超过5MB');
      }

      return isJPG && isLt2M;
    },
    handleSave: function handleSave() {
      var _this2 = this;

      if (!this.deployForm.imgOssHref) {
        this.$message({
          message: '请先上传仲裁员签名',
          type: 'warning'
        });
        return;
      }

      this.loading = true;
      (0, _arbitrator.saveImgAndNotice)(this.submitData).then(function (res) {
        if (res.code == 1) {
          _this2.$message({
            message: '保存成功',
            type: 'success'
          });
        } else {
          _this2.$message.error(res.msg);
        }

        _this2.loading = false;
      });
    }
  }
};
exports.default = _default;