"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'auditing',
  data: function data() {
    return {
      loading: true,
      currentPage: 4,
      tableData: [{
        reference: '暂无案号',
        acceptNum: '4500004',
        application: '万海融资租赁（东莞）有限公司',
        respondent: '张时',
        operate: '民间金融纠纷',
        subjectMatter: '10000',
        arbitration: '200',
        status: '待一审',
        origin: '互仲链'
      }],
      auditingVisible: false,
      //审核弹窗显示
      optionArr: [{
        label: '你好',
        value: '你好'
      }, {
        label: '我不会',
        value: '我不会'
      }],
      auditingform: {
        //案件审核弹框2数据
        source: '通过审核',
        region: '',
        stuffSupply: '',
        stuffExplain: '',
        nohandExplain: '',
        regionInput: ''
      },
      auditingrules: {
        source: [{
          required: true,
          trigger: 'change'
        }],
        region: [{
          required: true,
          trigger: 'change'
        }],
        stuffSupply: [{
          required: true,
          trigger: 'change'
        }],
        stuffExplain: [{
          required: true
        }],
        nohandExplain: [{
          required: true
        }]
      },
      languageinnerVisible: false,
      //添加常用语弹窗显示
      newLanguage: '' //添加新的常用语的内容数据同步

    };
  },
  created: function created() {
    this.loading = false;
  },
  components: {
    Caseconditons: _index.default
  },
  methods: {
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    //表格审核事件
    handleClick: function handleClick(e) {
      this.$router.push({
        name: 'auditingDetail'
      });
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {},
    handleCurrentChange: function handleCurrentChange(val) {},
    handResult: function handResult(label) {},
    // 案件处理2弹框的秘书常用语状态改变
    chooseCommonLanguage: function chooseCommonLanguage(label) {
      this.auditingform.regionInput = label;
    },
    // 最后弹窗审核按钮
    gotoCaseService: function gotoCaseService() {
      this.auditingVisible = false; // this.$router.push({name:'service'})
    },
    //添加常用语  弹窗确认按钮
    addLanguage: function addLanguage() {
      this.languageinnerVisible = false;
      this.optionArr.push({
        label: this.newLanguage,
        value: this.newLanguage
      });
    }
  }
};
exports.default = _default;