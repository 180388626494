"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.findCaseRemindDoneTotal = findCaseRemindDoneTotal;
exports.findCurrentTaskRemind = findCurrentTaskRemind;
exports.findTaskRemind = findTaskRemind;
exports.getCaseRemindList = getCaseRemindList;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询当前用户的任务待办提醒数量
function findTaskRemind() {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findTaskRemind',
    method: 'get'
  });
}

function findCurrentTaskRemind() {
  return (0, _request.default)({
    url: '/batchCase/caseBatchInfo/findNowDayCases',
    method: 'get'
  });
}

function getCaseRemindList(data) {
  return (0, _request.default)({
    url: '/batchCase/caseDataRemind/findUserCaseWaitDonePage',
    method: 'post',
    data: data
  });
}

function findCaseRemindDoneTotal() {
  return (0, _request.default)({
    url: '/batchCase/caseDataRemind/findUserCaseWaitDoneTotal',
    method: 'get'
  });
}