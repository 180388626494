"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _inquiry = require("@/api/inquiry.js");

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

var _dictionary = require("@/config/dictionary");

var _index2 = require("@/utils/index.js");

var _security = require("@/utils/security");

var _encryptKey = require("@/config/encryptKey");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//  import Caseconditons from "./codition.vue";
//定义一个全局的变量
var validPhone = function validPhone(rule, value, callback) {
  if (!value) {
    callback(new Error("请输入电话号码"));
  } else {
    callback();
  }
};

var _default = {
  name: "manage",
  data: function data() {
    return {
      selectType: 1,
      selectShow: false,
      loadingSave: false,
      loadingShow: false,
      loadingPass: false,
      loadingProcess: false,
      loadingUpload: false,
      upcomingVisible: false,
      selectVisible: false,
      selectCurRow: null,
      dataContentForm: {
        title: "",
        subject: "",
        caseNo: ""
      },
      dataContent: [],
      optionArr: [],
      processNextId: "",
      loading: true,
      tableHeaderColor: this.$tableHeaderColor,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      suppleMaterialVisible: false,
      //补充材料弹窗
      partyVisible: false,
      //当事人维护弹窗
      tableData: [],
      total: 0,
      //总页数
      caseState: _dictionary.dictionary.caseState,
      identityType: _dictionary.dictionary.partyType,
      partyIdType: _dictionary.dictionary.partyIdType,
      card: _dictionary.dictionary.card,
      sex: _dictionary.dictionary.sex,
      uploadSuccess: false,
      materUploadTip: false,
      appParty: [],
      //申请人列表
      resParty: [],
      //被申请人列表
      appisActive: null,
      //申请人名字高亮
      resisActive: null,
      //被申请人名字高亮
      ruleForm: {
        sumbitName: "",
        submitter: ""
      },
      rules: {
        sumbitName: [{
          required: true,
          message: "请输入材料名称"
        }],
        submitter: [{
          required: true,
          message: "请选择提交人"
        }]
      },
      submitterArr: [],
      //提交人列表
      materFileList: [],
      //补充材料的上传材料列表
      docFileList: [],
      partyRuleForm: {
        partyType: "",
        partyIdentity: "",
        partyName: "",
        partySex: "",
        DocType: "",
        DocNum: "",
        DocAddress: "",
        phone: "",
        email: "",
        legalPerson: "",
        legalId: "",
        post: ""
      },
      partyIdfileList: [],
      //当事人文件材料
      partyRules: {
        partyName: [{
          required: true,
          message: "请输入名称"
        }],
        partyType: [{
          required: true,
          message: "请选择"
        }],
        partyIdentity: [{
          required: true,
          message: "请选择"
        }],
        DocType: [{
          required: true,
          message: "请选择证件类型"
        }],
        DocNum: [{
          required: true,
          message: "请输入证件号码"
        }],
        DocAddress: [{
          required: true,
          message: "请输入证件地址"
        }],
        phone: [{
          required: true,
          message: "请输入电话号码",
          validator: validPhone
        }]
      },
      partyFileList: [],
      title: "添加",
      currentCaseId: "",
      //当前详情caseid
      partyId: "",
      //当前当事人的id
      partyType: "",
      //当前当事人的类型
      uploadTip: false,
      //补充资料=>上传文件提示
      conditionData: "",
      //筛选数据
      headers: {
        Authorization: localStorage.getItem("token")
      },
      uploadData: {
        file: ""
      },
      action: process.env.VUE_APP_BASE_API + (0, _inquiry.partyUploadFiles)(),
      newUploadFiles: [],
      //当事人新添加的上传的文件
      partyLoading: true //打开当事人窗口的Loading

    };
  },
  created: function created() {
    this.init();
  },
  mounted: function mounted() {},
  components: {
    Caseconditons: _index.default
  },
  methods: {
    // 初始化数据列表
    init: function init() {
      var _this = this;

      this.loading = true;
      (0, _inquiry.getList)({
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDelete: function handleDelete(val) {
      if (this.dataContent.length == 0) {
        this.$message.error("补正书修正信息已为空");
      } else {
        this.dataContent.splice(val, 1);
      }
    },
    handleAdd: function handleAdd(val) {
      this.dataContent.splice(val + 1, 0, {
        text: ""
      });
    },
    onloadProcessMan: function onloadProcessMan() {
      var _this2 = this;

      var caseId = this.selectCurRow.caseId;
      (0, _inquiry.getCaseCorrectionContentNextProcess)(caseId).then(function (res) {
        if (res.code == 1) {
          var data = res.data.nextProcess;
          _this2.optionArr = data;
        }
      });
    },
    handleSave: function handleSave() {
      var _this3 = this;

      //提交数据
      this.loadingSave = true;
      var parmas = {
        caseId: this.selectCurRow.caseId,
        content: JSON.stringify({
          content: this.dataContent
        }),
        reviewId: this.selectCurRow.reviewId,
        reviewName: this.selectCurRow.reviewName
      };
      (0, _inquiry.saveCaseCorrectionContent)(parmas).then(function (res) {
        _this3.loadingSave = false;

        if (res.code == "1") {
          _this3.$message({
            message: "操作成功!",
            type: "success"
          });

          _this3.upcomingVisible = false;
        } else {
          _this3.$message({
            message: res.msg ? res.msg : "操作失败!",
            type: "warning"
          });
        }
      }); //保存预览
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this4 = this;

      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginCtime: data.rowSecondDate[0],
        endCtime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      this.loading = true;
      (0, _inquiry.getList)(postData).then(function (res) {
        _this4.loading = false;

        if (res.code == 1) {
          _this4.tableData = res.rows;
          _this4.total = res.total;
        }
      }).catch(function (er) {
        _this4.loading = false;
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = "";
      this.currentPage = 1;
      this.init();
    },
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //表格  案号点击
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: "inquiryDetail",
        params: {
          caseId: val
        }
      });
    },
    //表格  维护按钮
    mainTain: function mainTain(val) {
      this.partyVisible = true;
      this.currentCaseId = val;
      this.appisActive = 0; //申请人名字高亮

      this.resisActive = null; //被申请人名字高亮

      this.partyFileList = [];

      if (this.$refs["partyRuleForm"]) {
        this.$refs["partyRuleForm"].resetFields();
      }

      this.getPartyDetail();
    },
    fixt: function fixt(data) {
      this.selectCurRow = data;
      this.docFileList = [];
      this.selectVisible = true;
    },
    onLoadFixtFile: function onLoadFixtFile() {
      var _this5 = this;

      this.upcomingVisible = true;
      (0, _inquiry.getCaseCorrectionContent)(this.selectCurRow.caseId).then(function (res) {
        var rest = res.data || {};
        var content = rest.content || "{}";
        var contentJSON = JSON.parse(content);
        _this5.dataContent = contentJSON.content;
        _this5.dataContentForm.title = rest.title;
        _this5.dataContentForm.subject = rest.subject;
        _this5.dataContentForm.caseNo = rest.caseNo;
        _this5.selectCurRow.reviewId = rest.reviewId;
        _this5.selectCurRow.reviewName = rest.reviewName;
      });
    },
    // 表格   补充材料按钮
    supple: function supple(val) {
      var _this6 = this;

      this.currentCaseId = val; // 获取提交人列表

      (0, _inquiry.getsubmitterList)({
        caseId: val
      }).then(function (res) {
        if (res.code == 1) {
          _this6.submitterArr = res.data;
        }
      });
      this.suppleMaterialVisible = true; // 初始化补充材料弹框

      this.materFileList = [];
      this.materUploadTip = false;

      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].resetFields();
      }
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this7 = this;

      this.pageLimit = val;
      this.loading = true;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          _this7.loading = false;

          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          _this7.loading = false;

          if (res.code == 1) {
            _this7.tableData = res.rows;
            _this7.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this8 = this;

      this.loading = true;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _inquiry.getList)(this.conditionData).then(function (res) {
          _this8.loading = false;

          if (res.code == 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
            _this8.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getList)({
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          _this8.loading = false;

          if (res.code == 1) {
            _this8.tableData = res.rows;
            _this8.total = res.total;
            _this8.currentPage = val;
          }
        });
      }
    },
    //补充材料  表单提交
    submitForm: function submitForm(formName) {
      var _this9 = this;

      // 处理新上传的文件的数据   ==>转为字符串
      var fileStr = [];
      this.materFileList.forEach(function (item, index) {
        fileStr.push(item.filePath);
      });
      this.$refs[formName].validate(function (valid) {
        if (valid && _this9.materFileList.length > 0) {
          (0, _inquiry.addSupplementMaterial)({
            partyId: _this9.ruleForm.submitter,
            filePath: fileStr.join(","),
            fileName: _this9.ruleForm.sumbitName,
            caseId: _this9.currentCaseId
          }).then(function (res) {
            if (res.code == 1) {
              _this9.$message({
                message: "补充材料成功",
                type: "success"
              });

              _this9.$refs[formName].resetFields();

              _this9.suppleMaterialVisible = false;
              _this9.materUploadTip = false;
              _this9.materFileList = [];
            }
          });
        } else {
          _this9.materUploadTip = true;
          return false;
        }
      });
    },
    // 上传前判断文件的类型和大小
    beforeUpload: function beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      testmsg = testmsg.toLowerCase();
      var extension = testmsg === "png";
      var extension2 = testmsg === "jpg";
      var extension3 = testmsg === "pdf";
      var isLt2M = file.size / 1024 / 1024 < 5;

      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: "上传文件只能是png、jpg、pdf格式!",
          type: "warning"
        });
      }

      if (!isLt2M) {
        this.$message({
          message: "上传文件大小不能超过 5MB!",
          type: "warning"
        });
      }

      return (extension || extension2 || extension3) && isLt2M;
    },
    // 删除文件前
    beforeRemove: function beforeRemove(file, fileList) {
      return this.$confirm("\u786E\u5B9A\u79FB\u9664 ".concat(file.name, "\uFF1F"));
    },
    //上传   移除文件的事件
    handleRemove: function handleRemove(file, fileList) {
      var index = (0, _index2.findWithAttr)(this.materFileList, "uid", file.uid);
      this.materFileList.splice(index, 1);
    },
    //上传   点击文件列表中的文件
    handlePreview: function handlePreview(file) {
      if (file.url) {
        window.open(file.url);
      } else {
        var res = JSON.parse((0, _security.decryptByAES)(file.response.response, _encryptKey.encryptKey.key));

        if (res.code == 1) {
          window.open(res.data.href);
        } else {
          location.reload();
        }
      }
    },
    //上传   超过限制时的事件
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E91\u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u9009\u62E9\u4E86 ".concat(files.length, " \u4E2A\u6587\u4EF6\uFF0C\u5171\u9009\u62E9\u4E86 ").concat(files.length + fileList.length, " \u4E2A\u6587\u4EF6"));
    },
    //上传成功时的钩子
    handleSuccess: function handleSuccess(res1, file, fileList) {
      var res = JSON.parse((0, _security.decryptByAES)(res1.response, _encryptKey.encryptKey.key));

      if (res.code == 1) {
        var testmsg = file.name.substring(0, file.name.indexOf("."));

        if (!this.ruleForm.sumbitName) {
          this.ruleForm.sumbitName = testmsg;
        }

        this.materFileList.push({
          name: res.data.fileName,
          url: res.data.aliyunOssHref,
          filePath: res.data.filePath,
          uid: file.uid
        });
        this.materUploadTip = false;
      } else {
        location.reload();
      }
    },
    // 当事人弹窗数据初始化
    partyDataInit: function partyDataInit() {
      this.$refs.partyUpload.clearFiles();
      this.$refs["partyRuleForm"].resetFields();
      this.partyVisible = false;
      this.appisActive = null;
      this.resisActive = null;
      this.uploadTip = false;
      this.partyFileList = [];
      this.newUploadFiles = [];
    },
    //当事人 表单提交
    submitPartyForm: function submitPartyForm(formName) {
      var _this10 = this;

      // 处理新上传的文件的数据   ==>转为字符串
      var fileStr = [];
      this.newUploadFiles.forEach(function (item, index) {
        fileStr.push(item.filePath);
      }); // 判断必须的已经有值才给提交接口

      this.$refs[formName].validate(function (valid) {
        if (valid && _this10.partyFileList.length >= 1) {
          _this10.uploadTip = false;

          if (_this10.title == "添加") {
            (0, _inquiry.addPartyMaintenance)({
              annexs: fileStr.join(","),
              caseId: _this10.currentCaseId,
              certAddress: _this10.partyRuleForm.DocAddress,
              companyChargeDuty: _this10.partyRuleForm.post,
              companyChargeName: _this10.partyRuleForm.legalPerson,
              companyChargeNameCode: _this10.partyRuleForm.legalId,
              contacterDuty: "",
              contacterName: "",
              contacterPhone: "",
              email: _this10.partyRuleForm.email,
              idcard: _this10.partyRuleForm.DocNum,
              idtype: (0, _index2.findKey)(_dictionary.dictionary.card, _this10.partyRuleForm.DocType),
              name: _this10.partyRuleForm.partyName,
              otherAddress: "",
              partyType: (0, _index2.findKey)(_dictionary.dictionary.partyIdType, _this10.partyRuleForm.partyType),
              phone: _this10.partyRuleForm.phone,
              type: (0, _index2.findKey)(_dictionary.dictionary.partyType, _this10.partyRuleForm.partyIdentity)
            }).then(function (res) {
              if (res.code == 1) {
                _this10.$message("添加成功");

                _this10.partyDataInit();
              }
            });
          } else if (_this10.title == "修改") {
            var _updatePartyMaintenan;

            // 旧的材料和新的材料
            (0, _inquiry.updatePartyMaintenance)((_updatePartyMaintenan = {
              annexs: fileStr.join(","),
              caseId: _this10.currentCaseId,
              certAddress: _this10.partyRuleForm.DocAddress,
              companyChargeDuty: _this10.partyRuleForm.post,
              companyChargeName: _this10.partyRuleForm.legalPerson,
              companyChargeNameCode: _this10.partyRuleForm.legalId,
              contacterDuty: "",
              contacterName: "",
              contacterPhone: "",
              email: _this10.partyRuleForm.email,
              idcard: _this10.partyRuleForm.DocNum,
              idtype: (0, _index2.findKey)(_dictionary.dictionary.card, _this10.partyRuleForm.DocType),
              name: _this10.partyRuleForm.partyName,
              otherAddress: "",
              partyType: (0, _index2.findKey)(_dictionary.dictionary.partyIdType, _this10.partyRuleForm.partyType),
              phone: _this10.partyRuleForm.phone,
              type: (0, _index2.findKey)(_dictionary.dictionary.partyType, _this10.partyRuleForm.partyIdentity),
              partyId: _this10.partyId
            }, (0, _defineProperty2.default)(_updatePartyMaintenan, "partyType", _this10.partyType), (0, _defineProperty2.default)(_updatePartyMaintenan, "sex", (0, _index2.findKey)(_dictionary.dictionary.sex, _this10.partyRuleForm.sex)), _updatePartyMaintenan)).then(function (res) {
              if (res.code == 1) {
                _this10.$message("修改成功");

                _this10.partyDataInit();
              }
            });
          }
        } else {
          _this10.uploadTip = true;
          return false;
        }
      });
    },
    //添加当事人
    addInit: function addInit(formName) {
      this.title = "添加";
      this.partyIdfileList = [];
      this.appisActive = null;
      this.resisActive = null;
      this.partyFileList = [];
      this.newUploadFiles = [];
      this.$refs.partyUpload.clearFiles();
      this.$refs[formName].resetFields();
    },
    // 获取当事人详情
    getPartyDetail: function getPartyDetail() {
      var _this11 = this;

      (0, _inquiry.getPartyMaintenance)({
        caseId: this.currentCaseId
      }).then(function (res) {
        if (res.code == 1) {
          _this11.currentCaseId = res.data.caseId;
          _this11.resParty = res.data.resParty;
          _this11.appParty = res.data.appParty; // 名称高亮
          // this.appisActive = 0;

          _this11.chooseAppName(0);

          _this11.partyLoading = false;
        }
      });
    },
    //点击  申请人当事人姓名
    chooseAppName: function chooseAppName(index) {
      var _this12 = this;

      // this.getPartyDetail();
      this.appisActive = index;
      this.resisActive = null;
      this.uploadTip = false;
      this.title = "修改";
      this.newUploadFiles = [];
      this.partyFileList = [];
      this.$refs.partyUpload.clearFiles();
      this.partyId = this.appParty[index].partyId;
      this.partyType = this.appParty[index].partyType;
      this.partyRuleForm.partyType = this.partyIdType[this.appParty[index].partyType];
      this.partyRuleForm.partyIdentity = this.identityType[this.appParty[index].type];
      this.partyRuleForm.partyName = this.appParty[index].name;
      this.partyRuleForm.partySex = this.sex[this.appParty[index].sex];
      this.partyRuleForm.DocType = this.card[this.appParty[index].idtype];
      this.partyRuleForm.DocNum = this.appParty[index].idcard;
      this.partyRuleForm.DocAddress = this.appParty[index].certAddress;
      this.partyRuleForm.phone = this.appParty[index].phone;
      this.partyRuleForm.email = this.appParty[index].email;
      this.partyRuleForm.legalPerson = this.appParty[index].companyChargeName;
      this.partyRuleForm.legalId = this.appParty[index].companyChargeNameCode;
      this.partyRuleForm.post = this.appParty[index].companyChargeDuty; // this.partyFileList = this.appParty[index].partyIdfileList;

      if (this.appParty[index].partyIdfileList.length > 0) {
        this.appParty[index].partyIdfileList.forEach(function (item, index) {
          _this12.partyFileList.push({
            name: item.fileName,
            url: item.aliyunOssHref,
            id: item.id
          });
        });
      }
    },
    //点击  被申请人当事人姓名
    chooseResName: function chooseResName(index) {
      var _this13 = this;

      // this.getPartyDetail();
      this.resisActive = index;
      this.appisActive = null;
      this.uploadTip = false;
      this.title = "修改";
      this.newUploadFiles = [];
      this.partyFileList = [];
      this.$refs.partyUpload.clearFiles();
      this.partyId = this.resParty[index].partyId;
      this.partyType = this.resParty[index].partyType;
      this.partyRuleForm.partyType = this.partyIdType[this.resParty[index].partyType];
      this.partyRuleForm.partyIdentity = this.identityType[this.resParty[index].type];
      this.partyRuleForm.partyName = this.resParty[index].name;
      this.partyRuleForm.partySex = this.sex[this.resParty[index].sex];
      this.partyRuleForm.DocType = this.card[this.resParty[index].idtype];
      this.partyRuleForm.DocNum = this.resParty[index].idcard;
      this.partyRuleForm.DocAddress = this.resParty[index].certAddress;
      this.partyRuleForm.phone = this.resParty[index].phone;
      this.partyRuleForm.email = this.resParty[index].email;
      this.partyRuleForm.legalPerson = this.resParty[index].companyChargeName;
      this.partyRuleForm.legalId = this.resParty[index].companyChargeNameCode;
      this.partyRuleForm.post = this.resParty[index].companyChargeDuty;

      if (this.resParty[index].partyIdfileList.length > 0) {
        this.resParty[index].partyIdfileList.forEach(function (item, index) {
          _this13.partyFileList.push({
            name: item.fileName,
            url: item.aliyunOssHref,
            id: item.id
          });
        });
      }
    },
    partyBeforeRemove: function partyBeforeRemove(file) {
      var _this14 = this;

      // 1、当有多份材料的时候,分带有id文件和没有带id文件
      // 2、当带有id文件的时候,但是只有一份id文件的时候,不给删除带有id的文件，多份id文件可以删除并调用删除接口，接口成功才给删除
      // 3、当有一份材料的时候,不给删除
      // 4、不符合规格的文件,组件没有放入this.partyFileList中,无法理会
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      testmsg = testmsg.toLowerCase();

      if (this.partyFileList.length > 1) {
        if (file.id && (0, _index2.findRepeat)(this.partyFileList, "id") > 1) {
          //有id才触发接口删除
          return this.$confirm("\u786E\u5B9A\u79FB\u9664 ".concat(file.name, "\uFF1F")).then(function (res) {
            (0, _inquiry.deletePartyFile)({
              id: file.id
            }).then(function (res) {
              if (res.code == 1) {
                _this14.$message({
                  message: "删除材料成功",
                  type: "success"
                });

                return true;
              } else {
                return false;
              }
            });
          });
        } else if (file.id && (0, _index2.findRepeat)(this.partyFileList, "id") == 1) {
          this.$message.warning("\u6DFB\u52A0\u6750\u6599\u8FD8\u6CA1\u4FDD\u5B58\uFF0C\u5FC5\u987B\u4FDD\u8BC1\u6709\u4E00\u4EFD\u4EE5\u4E0A\u7684\u6750\u6599\uFF0C\u6682\u65E0\u6CD5\u79FB\u9664!");
          return false;
        } else {
          return this.$confirm("\u786E\u5B9A\u79FB\u9664 ".concat(file.name, "\uFF1F"));
        }
      } else if (this.partyFileList.length == 1) {
        if (testmsg == "jpg" || testmsg == "png") {
          this.$message.warning("\u5FC5\u987B\u4FDD\u8BC1\u6709\u4E00\u4EFD\u6750\u6599\u4E0A\u4F20\uFF0C\u65E0\u6CD5\u79FB\u9664!");
          return false;
        } else {
          return this.$confirm("\u6B64\u6750\u6599\u4E0D\u7B26\u5408\u89C4\u683C\uFF0C\u65E0\u6CD5\u4E0A\u4F20\uFF0C\u786E\u5B9A\u79FB\u9664 ".concat(file.name, "\uFF1F"));
        }
      } else {
        return this.$confirm("\u6B64\u6750\u6599\u4E0D\u7B26\u5408\u89C4\u683C\uFF0C\u65E0\u6CD5\u4E0A\u4F20\uFF0C\u786E\u5B9A\u79FB\u9664 ".concat(file.name, "\uFF1F"));
      }
    },
    partyHandleRemove: function partyHandleRemove(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      var partyIndex = (0, _index2.findWithAttr)(this.partyFileList, "uid", file.uid); //展示的文件列表

      var newIndex = (0, _index2.findWithAttr)(this.newUploadFiles, "uid", file.uid); //传给后台 符合规范的新的上传文件列表

      if (testmsg == "jpg" || testmsg == "png") {
        this.partyFileList.splice(partyIndex, 1);
        this.newUploadFiles.splice(newIndex, 1);
      }
    },
    partyHandlePreview: function partyHandlePreview(file) {
      if (file.url) {
        window.open(file.url);
      } else {
        var res = JSON.parse((0, _security.decryptByAES)(file.response.response, _encryptKey.encryptKey.key));

        if (res.code == 1) {
          window.open(res.data.href);
        } else {
          location.reload();
        }
      }
    },
    partyHandleExceed: function partyHandleExceed(files, fileList) {
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E9 3 \u4E2A\u6587\u4EF6");
    },
    partyBeforeUpload: function partyBeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      var extension = testmsg === "png";
      var extension2 = testmsg === "jpg";
      var extension3 = testmsg === "pdf";
      var isLt2M = file.size / 1024 / 1024 < 5;

      if (!extension && !extension2 && !extension3) {
        this.$message({
          message: "上传文件只能是png、jpg、pdf格式!",
          type: "warning"
        });
      }

      if (!isLt2M) {
        this.$message({
          message: "上传文件大小不能超过 5MB!",
          type: "warning"
        });
      }

      return (extension || extension2 || extension3) && isLt2M;
    },
    // 文件上传成功的回调
    partyHandleSuccess: function partyHandleSuccess(res1, files, fileList) {
      // this.partyFileList = fileList;
      //这里是增加新上传的每条数据
      var res = JSON.parse((0, _security.decryptByAES)(res1.response, _encryptKey.encryptKey.key));

      if (res.code == 1) {
        this.newUploadFiles.push({
          name: res.data.fileName,
          url: res.data.aliyunOssHref,
          uid: files.uid,
          filePath: res.data.filePath
        });
        this.partyFileList.push({
          name: res.data.fileName,
          url: res.data.aliyunOssHref,
          uid: files.uid,
          filePath: res.data.filePath
        });
      } else {
        location.reload();
      }
    },
    docBeforeRemove: function docBeforeRemove(file) {
      return this.$confirm("\u786E\u5B9A\u79FB\u9664 ".concat(file.name, "\uFF1F"));
    },
    docHandleRemove: function docHandleRemove(file) {
      var index = (0, _index2.findWithAttr)(this.docFileList, "uid", file.uid);
      this.docFileList.splice(index, 1);
    },
    docHandlePreview: function docHandlePreview(file) {
      if (file.url) {
        window.open(file.url);
      } else {
        var res = JSON.parse((0, _security.decryptByAES)(file.response.response, _encryptKey.encryptKey.key));

        if (res.code == 1) {
          window.open(res.data.href);
        } else {
          location.reload();
        }
      }
    },
    docHandleExceed: function docHandleExceed(files, fileList) {
      this.$message.warning("\u5F53\u524D\u9650\u5236\u9009\u62E91\u4E2A\u6587\u4EF6\uFF0C\u672C\u6B21\u9009\u62E9\u4E86 ".concat(files.length, " \u4E2A\u6587\u4EF6\uFF0C\u5171\u9009\u62E9\u4E86 ").concat(files.length + fileList.length, " \u4E2A\u6587\u4EF6"));
    },
    docBeforeUpload: function docBeforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      testmsg = testmsg.toLowerCase();
      var extension = testmsg === "doc";
      var extension2 = testmsg === "docx";
      var isLt2M = file.size / 1024 / 1024 < 5;

      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是doc、docx格式!",
          type: "warning"
        });
      }

      if (!isLt2M) {
        this.$message({
          message: "上传文件大小不能超过 5MB!",
          type: "warning"
        });
      }

      return (extension || extension2) && isLt2M;
    },
    docHandleSuccess: function docHandleSuccess(res1, files, fileList) {
      var res = JSON.parse((0, _security.decryptByAES)(res1.response, _encryptKey.encryptKey.key));

      if (res.code == 1) {
        this.docFileList.push({
          name: res.data.fileName,
          url: res.data.aliyunOssHref,
          filePath: res.data.filePath,
          uid: files.uid
        });
      } else {
        location.reload();
      }
    },
    changeType: function changeType(val) {
      if (val == 2) {
        this.selectShow = true;
      } else {
        this.selectShow = false;
      }
    },
    handleUpload: function handleUpload() {
      if (this.selectType == 1) {
        this.selectVisible = false;
        this.upcomingVisible = true;
        this.onLoadFixtFile();
      } else {
        this.submitUploadForm();
      }
    },
    submitUploadForm: function submitUploadForm() {
      var _this15 = this;

      var caseId = this.selectCurRow.caseId;

      if (this.docFileList.length <= 0) {
        this.$message({
          message: "请上传补正书文件!",
          type: "warning"
        });
        return;
      }

      this.loadingUpload = true;
      (0, _inquiry.onCorrectSaveFile)({
        caseId: caseId,
        filePath: this.docFileList[0].filePath
      }).then(function (res) {
        _this15.selectVisible = false;
        _this15.loadingUpload = false;

        if (res.code == "1") {
          _this15.$message({
            message: "操作成功!",
            type: "success"
          });

          _this15.init();
        } else {
          _this15.$message({
            message: res.msg ? res.msg : "操作失败",
            type: "warning"
          });
        }
      }).catch(function (error) {
        _this15.$message({
          message: "操作失败",
          type: "warning"
        });

        _this15.loadingUpload = false;
      });
    }
  }
};
exports.default = _default;