"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _inquiry = require("@/api/inquiry");

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

var _dictionary = require("@/config/dictionary");

var _index2 = require("@/utils/index.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'onfile',
  data: function data() {
    return {
      loading: true,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      pageSizes: this.$tablePageSizes,
      total: 0,
      caseState: _dictionary.dictionary.caseState,
      tableData: [],
      conditionData: '',
      tableHeaderColor: this.$tableHeaderColor
    };
  },
  created: function created() {
    this.init();
  },
  mounted: function mounted() {},
  components: {
    Caseconditons: _index.default
  },
  methods: {
    // 初始化数据列表
    init: function init() {
      var _this = this;

      this.currentPage = 1;
      (0, _inquiry.getCaseOnFilesList)({
        limit: this.pageLimit,
        page: this.currentPage,
        state: '6'
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleDetail: function handleDetail(val) {
      this.$router.push({
        name: 'inquiryDetail',
        params: {
          caseId: val
        }
      });
    },
    //点击筛选，发送请求列表
    checkList: function checkList(data) {
      var _this2 = this;

      // 需要发送给后端的数据
      this.currentPage = 1;
      var postData = {
        caseId: data.caseId,
        caseNo: data.caseNo,
        partyName: data.partyName,
        defenceState: data.replyvalue,
        beginRuleTime: data.rowSecondDate[0],
        endRuleTime: data.rowSecondDate[1],
        caseType: data.caseTypevalue,
        beginRegisterTime: data.rowThirdDate[0],
        endRegisterTime: data.rowThirdDate[1],
        source: data.caseOriginvalue,
        state: '6',
        limit: this.pageLimit,
        page: 1
      };
      this.conditionData = postData;
      (0, _inquiry.getCaseOnFilesList)(this.conditionData).then(function (res) {
        if (res.code == 1) {
          _this2.tableData = res.rows;
          _this2.total = res.total;
        }
      });
    },
    //点击 清空所有筛选条件
    cleanConditionData: function cleanConditionData() {
      this.conditionData = '';
      this.currentPage = 1;
      this.init();
    },
    //批量归档
    batchFile: function batchFile() {},
    // 表格勾选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //表格  归档按钮
    handleClick: function handleClick(val) {
      (0, _inquiry.downloadOnFiles)({
        caseId: val
      }).then(function (res) {
        if (res.code == 1) {
          window.location.href = res.data;
        }
      });
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this3 = this;

      this.pageLimit = val;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        (0, _inquiry.getCaseOnFilesList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this3.tableData = res.rows;
            _this3.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getCaseOnFilesList)({
          limit: val,
          page: this.currentPage,
          state: '6'
        }).then(function (res) {
          if (res.code == 1) {
            _this3.tableData = res.rows;
            _this3.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this4 = this;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        (0, _inquiry.getCaseOnFilesList)(this.conditionData).then(function (res) {
          if (res.code == 1) {
            _this4.tableData = res.rows;
            _this4.total = res.total;
            _this4.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.getCaseOnFilesList)({
          limit: this.pageLimit,
          page: val,
          state: '6'
        }).then(function (res) {
          if (res.code == 1) {
            _this4.tableData = res.rows;
            _this4.total = res.total;
            _this4.currentPage = val;
          }
        });
      }
    }
  }
};
exports.default = _default;