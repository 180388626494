"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.blockLatsetData = blockLatsetData;
exports.blockSearch = blockSearch;

var _request = _interopRequireDefault(require("@/utils/request"));

// 区块链查询前100
function blockLatsetData(data) {
  return (0, _request.default)({
    url: '/task/api/ebaoquan/latestDigests',
    method: 'post',
    data: data
  });
} // 输入查询结果


function blockSearch(data) {
  return (0, _request.default)({
    url: '/task/api/ebaoquan/chainInfo',
    method: 'post',
    data: data
  });
}