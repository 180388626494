"use strict";

var _interopRequireDefault = require("/work/project/aihuz/arbitration-ui/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _inquiry = require("@/api/inquiry.js");

var _index = _interopRequireDefault(require("@/views/caseConditions/index.vue"));

var _dictionary = require("@/config/dictionary");

var _auth = require("@/utils/auth");

var _security = require("@/utils/security");

var _encryptKey = require("@/config/encryptKey");

var _arbitrator = require("@/api/arbitrator");

var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));

var _index2 = require("@/utils/index.js");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Editor from '@/components/editor/index.vue'
var _default = {
  name: "recordCourt",
  data: function data() {
    return {
      list: [],
      // checkList: [],
      partyInfos: {
        title: "",
        content: ""
      },
      partyContents: {
        title: "",
        content: ""
      },
      caseContents: {
        title: "",
        content: ""
      },
      arbitrationContents: {
        title: "",
        content: ""
      },
      ruleContents: {
        title: "",
        content: ""
      },
      tableHeaderColor: this.$tableHeaderColor,
      pageSizes: this.$tablePageSizes,
      currentPage: 1,
      pageLimit: 10,
      //每页请求多少个数据，默认10
      total: 0,
      loading: false,
      tableData: [],
      conditionData: "",
      //来自子组件的数据
      date: "",
      caseState: _dictionary.dictionary.caseState,
      multipleSelection: [],
      multipleSelectionId: [],
      caseId: "",
      id: "",
      upcomingVisible: false,
      //
      refuseVisible: false,
      refuseForm: {
        opinion: "",
        caseId: ""
      },
      refuseRules: {
        opinion: [{
          required: true,
          message: "请填写拒绝理由",
          trigger: "blur"
        }]
      },
      rule: "",
      detailData: "",
      //总数据
      identiyState: _dictionary.dictionary.identiyState,
      //案件识别类型
      caseReply: "",
      //答辩状数据   ==>因为返回detailData数据里面，它自成一个对象，必须要声明
      courtInfo: "",
      //仲裁庭信息
      arbitrationRequestFlag: false,
      arbitrationAruthFlag: false,
      MaterialData1: [],
      //申请人材料列表
      MaterialData2: [],
      //被申请人材料列表
      //申请人案件材料分页
      MaterialTotal1: 0,
      MaterialCurrentPage1: 1,
      // 被申请人案件材料分页
      MaterialTotal2: 0,
      MaterialCurrentPage2: 1,
      partyInfoVisible: false,
      //当事人信息
      fileType: _dictionary.dictionary.fileType,
      materialState: _dictionary.dictionary.materialState,
      sendMsgType: _dictionary.dictionary.sendMsgType,
      batchUploadVisible: false,
      //批量上传裁决书
      batchUploadApi: "",
      token: (0, _auth.getToken)(),
      uploadVisible: false,
      uploadDecisionVisible: false,
      uploadRule: {},
      uploadApi: "",
      loadingSave: false,
      loadingShow: false,
      loadingPass: false,
      singleUploading: false,
      caseAwardContentDto: ""
    };
  },
  created: function created() {
    this.currentPage = 1;
    this.init();
  },
  components: {
    Caseconditons: _index.default,
    // Editor,
    Tinymce: _Tinymce.default
  },
  methods: {
    init: function init() {
      var _this = this;

      (0, _inquiry.findCaseCourtListByPage)({
        states: [3, 4],
        trialWay: 3,
        limit: this.pageLimit,
        page: this.currentPage
      }).then(function (res) {
        if (res.code == 1) {
          _this.tableData = res.rows;
          _this.total = res.total;
          _this.loading = false;
        }
      });
    },
    handleInVideo: function handleInVideo(row) {
      console.log(row);
      this.$router.push({
        path: "/trial/videoTrial",
        query: {
          caseId: row.caseId,
          caseNo: row.caseNo
        }
      }); //videoTrial
    },
    //--------------
    handleDelete1: function handleDelete1(val) {
      if (this.partyInfos.content.length == 1) {
        this.$message.error("当事人信息不能为空");
      } else {
        this.partyInfos.content.splice(val, 1);
      }
    },
    handleDelete2: function handleDelete2(val) {
      this.partyContents.content.splice(val, 1);
    },
    handleDelete3: function handleDelete3(val) {
      this.caseContents.content.splice(val, 1);
    },
    handleDelete4: function handleDelete4(val) {
      this.arbitrationContents.content.splice(val, 1);
    },
    handleDelete5: function handleDelete5(val) {
      this.ruleContents.content.splice(val, 1);
    },
    handleAdd1: function handleAdd1(val) {
      this.partyInfos.content.splice(val + 1, 0, {
        text: ""
      });
    },
    handleAdd2: function handleAdd2(val) {
      this.partyContents.content.splice(val + 1, 0, {
        text: ""
      });
    },
    handleAdd3: function handleAdd3(val) {
      this.caseContents.content.splice(val + 1, 0, {
        text: ""
      });
    },
    handleAdd4: function handleAdd4(val) {
      this.arbitrationContents.content.splice(val + 1, 0, {
        text: ""
      });
    },
    handleAdd5: function handleAdd5(val) {
      this.ruleContents.content.splice(val + 1, 0, {
        text: ""
      });
    },
    handleGetRule: function handleGetRule() {
      var textareas1 = document.getElementById("rule1").getElementsByTagName("textarea");
      var newText1 = [];

      if (textareas1.length > 0) {
        for (var i = 0; i < textareas1.length; i++) {
          newText1.push({
            text: textareas1[i].value
          });
        }
      }

      var textareas2 = document.getElementById("rule2").getElementsByTagName("textarea");
      var newText2 = [];

      if (textareas2.length > 0) {
        for (var i = 0; i < textareas2.length; i++) {
          newText2.push({
            text: textareas2[i].value
          });
        }
      }

      var textareas3 = document.getElementById("rule3").getElementsByTagName("textarea");
      var newText3 = [];

      if (textareas3.length > 0) {
        for (var i = 0; i < textareas3.length; i++) {
          newText3.push({
            text: textareas3[i].value
          });
        }
      }

      var textareas4 = document.getElementById("rule4").getElementsByTagName("textarea");
      var newText4 = [];

      if (textareas4.length > 0) {
        for (var i = 0; i < textareas4.length; i++) {
          newText4.push({
            text: textareas4[i].value
          });
        }
      }

      var textareas5 = document.getElementById("rule5").getElementsByTagName("textarea");
      var newText5 = [];

      if (textareas5.length > 0) {
        for (var i = 0; i < textareas5.length; i++) {
          newText5.push({
            text: textareas5[i].value
          });
        }
      }

      var title1 = "";
      var title2 = "";
      var title3 = "";
      var title4 = "";
      var title5 = "";

      if (this.partyInfos.title) {
        title1 = document.getElementById("title1").value;
      }

      if (this.partyContents.title) {
        title2 = document.getElementById("title2").value;
      }

      if (this.caseContents.title) {
        title3 = document.getElementById("title3").value;
      }

      if (this.arbitrationContents.title) {
        title4 = document.getElementById("title4").value;
      }

      if (this.ruleContents.title) {
        title5 = document.getElementById("title5").value;
      }
    },
    // 分页事件
    handleSizeChange: function handleSizeChange(val) {
      var _this2 = this;

      this.pageLimit = val;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.limit = val;
        this.conditionData.page = this.currentPage;
        this.conditionData.states = [3, 4];
        this.conditionData.trialWay = 3;
        (0, _inquiry.findCaseCourtListByPage)(this.conditionData).then(function (res) {
          if (res.code === 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.findCaseCourtListByPage)({
          states: [3, 4],
          trialWay: 3,
          limit: val,
          page: this.currentPage
        }).then(function (res) {
          if (res.code === 1) {
            _this2.tableData = res.rows;
            _this2.total = res.total;
          }
        });
      }
    },
    handleCurrentChange: function handleCurrentChange(val) {
      var _this3 = this;

      if ((0, _index2.hasValueInObj)(this.conditionData)) {
        // 有筛选条件的情况下
        this.conditionData.page = val;
        this.conditionData.limit = this.pageLimit;
        this.conditionData.states = [3, 4];
        this.conditionData.trialWay = 3;
        (0, _inquiry.findCaseCourtListByPage)(this.conditionData).then(function (res) {
          if (res.code === 1) {
            _this3.tableData = res.rows;
            _this3.total = res.total;
            _this3.currentPage = val;
          }
        });
      } else {
        //没有筛选条件的情况下
        (0, _inquiry.findCaseCourtListByPage)({
          states: [3, 4],
          trialWay: 3,
          limit: this.pageLimit,
          page: val
        }).then(function (res) {
          if (res.code === 1) {
            _this3.tableData = res.rows;
            _this3.total = res.total;
            _this3.currentPage = val;
          }
        });
      }
    },
    cleanConditionData: function cleanConditionData() {},
    handleAvatarSuccess: function handleAvatarSuccess() {},
    beforeAvatarUpload: function beforeAvatarUpload() {},
    handleUploadSubmit: function handleUploadSubmit() {},
    //点击筛选，发送请求列表
    checkList: function checkList(data) {// 需要发送给后端的数据
      //  this.currentPage = 1;
      // const postData = {
      //   caseId: data.caseId,
      //   caseNo: data.caseNo,
      //   partyName: data.partyName,
      //   beginCtime: data.rowSecondDate[0],
      //   endCtime: data.rowSecondDate[1],
      //   caseType: data.caseTypevalue,
      //   beginRegisterTime: data.rowThirdDate[0],
      //   endRegisterTime: data.rowThirdDate[1],
      //   limit: this.pageLimit,
      //   page: 1
      // };
      // this.conditionData = postData;
      // findCaseCourtListByPage(this.conditionData).then(res => {
      //   if (res.code == 1) {
      //     this.tableData = res.rows;
      //     this.total = res.total;
      //   }
      // });
    }
  }
};
exports.default = _default;