import { render, staticRenderFns } from "./Stream.vue?vue&type=template&id=543d4810&scoped=true&"
import script from "./Stream.vue?vue&type=script&lang=js&"
export * from "./Stream.vue?vue&type=script&lang=js&"
import style0 from "./Stream.vue?vue&type=style&index=0&id=543d4810&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "543d4810",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/work/project/aihuz/arbitration-ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('543d4810')) {
      api.createRecord('543d4810', component.options)
    } else {
      api.reload('543d4810', component.options)
    }
    module.hot.accept("./Stream.vue?vue&type=template&id=543d4810&scoped=true&", function () {
      api.rerender('543d4810', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/trial/videoTrial/components/Stream.vue"
export default component.exports